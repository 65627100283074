<div class="modal-header">
  <h6 class="h7">{{ 'modals.resend_onboarding_email.title' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <p class="mb-2">{{ 'modals.resend_onboarding_email.description' | translate }}</p>

  <div class="participant-item">
    <app-avatar [profilePicture]="patient.profile_picture"></app-avatar>

    <div class="meta">
      <p class="title text-truncate">{{ patient.getFullName() || '...' }}</p>
      <p class="description text-truncate">{{ patient.date_of_birth | timeZoneDate }} ({{ patient.age() | translateNumber }}{{ 'pages.default.dashboard.yo' | translate }})</p>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="resend-onboarding-email-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" id="resend-onboarding-email-submit" [ngClass]="{'loader': isSending}" (click)="handleSubmit()">
      <span class="loader" *ngIf="isSending" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.resend_onboarding_email.submit' | translate }}</span>
    </button>
  </div>
</div>
