import {Component, OnInit} from '@angular/core';
import {Profile} from '../../../../models/profile';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {UserService} from '../../../../services/user.service';
import {GeneralService} from '../../../../services/general.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {HealthCareProfessional} from '../../../../models/health-care-professional';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings-preferences',
  templateUrl: './settings-preferences.component.html',
  styleUrls: ['./settings-preferences.component.scss']
})
export class SettingsPreferencesComponent implements OnInit {
  public profile: Profile;
  public hcp: HealthCareProfessional;
  public isFormDataLoaded: boolean;
  public isUpdatingPreferences: boolean;

  public unitForm: UntypedFormGroup;

  public validationVisible: boolean;

  public measurementUnits: any[];
  public numberFormats: any[];
  public dateFormats: any;
  public timeFormats: any[];
  public weekdays: any[];
  public timeZones: any[];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public generalService: GeneralService,
    public router: Router,
    public translateService: TranslateService,
    public toastService: ToastrService
  ) {
  }

  ngOnInit() {
    this.unitFormSetup();

    forkJoin([
      this.userService.getProfile(),
      this.generalService.getMeasurementUnits(),
      this.generalService.getNumberFormats(),
      this.generalService.getTimeFormats(),
      this.generalService.getWeekDays(),
      this.generalService.getTimeZones()
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data) {
    this.profile = data[0];
    this.measurementUnits = data[1];
    this.numberFormats = data[2];
    this.timeFormats = data[3];
    this.weekdays = data[4];
    this.timeZones = data[5];

    if (this.generalService.dateFormatMap) {
      this.dateFormats = this.generalService.dateFormatMap.dateFormats;
    }

    this.setProfile();
    this.isFormDataLoaded = true;
  }

  unitFormSetup() {
    if (this.unitForm) {
      return;
    }

    this.unitForm = this.formBuilder.group({
      measurement_unit: [''],
      number_format: [''],
      date_format: [''],
      time_24_hours: [''],
      first_day_of_week: [''],
      time_zone: []
    });
  }

  setProfile() {
    this.unitForm.get('measurement_unit').setValue(this.profile?.locale.measurement_unit);
    this.unitForm.get('number_format').setValue(this.profile?.locale.number_format);
    this.unitForm.get('date_format').setValue(this.profile?.locale.date_format);
    this.unitForm.get('time_24_hours').setValue(this.profile?.locale.time_24_hours);
    this.unitForm.get('first_day_of_week').setValue(this.profile?.locale.first_day_of_week);
    this.unitForm.get('time_zone').setValue(this.profile?.locale.time_zone);
  }

  handleUnitFormSubmission() {
    this.isUpdatingPreferences = true;

    this.profile.locale.first_day_of_week = this.unitForm.value.first_day_of_week;
    this.profile.locale.measurement_unit = this.unitForm.value.measurement_unit;
    this.profile.locale.date_format = this.unitForm.value.date_format;
    this.profile.locale.time_24_hours = this.unitForm.value.time_24_hours;
    this.profile.locale.number_format = this.unitForm.value.number_format;
    this.profile.locale.time_zone = this.unitForm.value.time_zone;

    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess(),
      () => this.onUpdateError()
    );
  }

  onUpdateSuccess() {
    this.isUpdatingPreferences = false;
    this.toastService.clear();
    this.toastService.success(this.translateService.instant('pages.default.settings.preferences.update_success'));
  }

  onUpdateError() {
    this.isUpdatingPreferences = false;
  }
}
