<div class="user-task-standard-modal">
  <div class="modal-header">
    <!-- Title User Task -->
    <h6 class="h7">
      <span *ngIf="userTask?.title">{{ userTask?.translationKeyTitle | translate | evalTranslation }}</span>
      <span *ngIf="!userTask?.title">...</span>
    </h6>

    <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="handleCancel()"></button>
  </div>

  <div class="modal-body">
    <div *ngIf="isLoading">
      <app-page-loader [padding]="'20px 0'"></app-page-loader>
    </div>

    <p *ngIf="userTask?.description && !isLoading">{{ userTask?.translationKeyDescription | translate | evalTranslation }}</p>

    <ng-container *ngIf="!isLoading">
      <div class="row">
        <div class=" mb-3 col-6 ">
          <p class="text-muted small mb-1">{{ 'modals.user_task.patient' | translate }}</p>

          <div class="participant-item">
            <app-avatar [profilePicture]="userTask?.patient?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title text-truncate">{{ userTask?.patient.getFullName() || '...' }}</p>
              <p class="description" *ngIf="userTask?.patient">{{ userTask?.patient?.date_of_birth | date:dateFormat?.format }} ({{
                userTask?.patient?.age() }}{{ 'pages.default.dashboard.yo' | translate }})</p>
            </div>
          </div>
        </div>

        <div class="mb-3 col-6" *ngIf="userTask?.assignee">
          <p class="text-muted small mb-1">{{ 'modals.user_task.assigned_to' | translate }}</p>

          <div class="participant-item">
            <app-avatar [profilePicture]="userTask?.assignee?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title text-truncate" *ngIf="userTask?.assignee?.uid === hcpUid">{{ 'modals.user_task.you' |
                translate }}</p>
              <p class="title text-truncate" *ngIf="userTask?.assignee?.uid !== hcpUid">{{
                userTask?.assignee?.getFullName() || '...' }}</p>
              <p class="description">{{ userTask?.assignee?.job_title }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center" *ngIf="userTask?.candidate_users?.length > 1">
        <div class="col">
          <p class="text-muted small mb-1">{{ 'modals.user_task.shared_task_with' | translate }}</p>
          <ng-container *ngIf="!showCandidateUsers">
            <app-avatar class="m-end-1 mb-1 e2e-candidate-avatar-collapsed" [profilePicture]="user?.profile_picture"
              *ngFor="let user of userTask?.sortedCandidateUsers(hcpUid)"></app-avatar>
          </ng-container>
        </div>
        <div class="col text-end">
          <a href="#" (click)="setShowCandidateUsers($event, true)" class="btn btn-tertiary"
            *ngIf="!showCandidateUsers" id="collapse-candidates-show">
            <span class="label">{{ 'modals.user_task.show' | translate }} &#x200E;</span>
            <span [inlineSVG]="'/assets/svg/chevron-down-24.svg'" class="icon"></span>
          </a>
          <a href="#" (click)="setShowCandidateUsers($event, false)" class="btn btn-tertiary mt-n3"
            *ngIf="showCandidateUsers" id="collapse-candidates-hide">
            <span class="label">{{ 'modals.user_task.hide' | translate }} &#x200E;</span>
            <span [inlineSVG]="'/assets/svg/chevron-up-24.svg'" class="icon"></span>
          </a>
        </div>
      </div>

      <div class="row" *ngIf="userTask?.candidate_users?.length > 1 && showCandidateUsers">
        <div class="col-6" *ngFor="let user of userTask?.sortedCandidateUsers(hcpUid)">
          <div class="participant-item e2e-candidate mb-1">
            <app-avatar [profilePicture]="user?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title text-truncate" *ngIf="user?.uid === hcpUid">{{ 'modals.user_task.you' | translate }}</p>
              <p class="title text-truncate" *ngIf="user?.uid !== hcpUid">{{ user?.getFullName() || '...' }}</p>
              <p class="description">{{ user?.job_title }}</p>
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-1 mb-4 medium"/>

      <div class="info-box mb-3 inline-page-notification" *ngIf="userTask?.accessible_by_patient">
        <em class="info-box-icon" [inlineSVG]="'/assets/svg/info-circle-24.svg'"></em>
        <h6 class="info-box-title mb-0">{{ 'pages.default.query_lists.accessible_by_patient.title' | translate }}</h6>
        <p>{{ 'pages.default.query_lists.accessible_by_patient.description' | translate }}</p>
      </div>

      <div class="user-task-form" *ngIf="userTask">
        <app-user-task-form [userTask]="userTask" [isReadOnly]="false" [validationVisible]="validationVisible" [(form)]="form"></app-user-task-form>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="m-start-auto">
      <button class="btn btn-tertiary" id="user-task-standard-cancel" (click)="handleCancel()"
        *ngIf="userTask?.isAssignedTo(hcpUid)">{{
        'action.cancel' | translate }}</button>
      <button class="btn btn-tertiary" id="user-task-standard-close" (click)="handleCancel()"
        *ngIf="!userTask?.isAssignedTo(hcpUid)">{{
        'action.close' | translate }}</button>

      <button class="btn btn-secondary m-start-2" id="user-task-standard-save" [ngClass]="{'loader': isSaving}"
        (click)="handleSaveChanges()" [disabled]="!userTask" *ngIf="userTask?.isAssignedTo(hcpUid) && userTask.canSave">
        <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-light-sm.svg'"></span>
        <span>{{ 'action.save' | translate }}</span>
      </button>

      <button class="btn btn-primary m-start-2" id="user-task-standard-submit" [ngClass]="{'loader': isSubmitting}"
        (click)="handleSubmit()" [disabled]="!userTask" *ngIf="userTask?.isAssignedTo(hcpUid)">
        <span class="loader" *ngIf="isSubmitting" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
        <span>{{ 'action.submit' | translate }}</span>
      </button>
    </div>
  </div>
</div>
