import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DateFormat } from '../../models/date-format';
import { Pathway, PatientPathwayStopReason } from '../../models/pathway';
import { Patient } from '../../models/patient';
import { HcpService } from '../../services/hcp.service';
import { LocaleService } from '../../services/locale.service';
import { PathwayService } from '../../services/pathway.service';

@Component({
  selector: 'app-stop-pathway-confirm-modal',
  templateUrl: './stop-pathway-confirm-modal.component.html'
})
export class StopPathwayConfirmModalComponent implements OnInit {
  public patient: Patient;
  public pathway: Pathway;
  public reason_code: { key: PatientPathwayStopReason, name: any };
  public reason: string;

  public dateFormat: DateFormat;
  public hospitalUid: string;

  public isSaving: boolean = false;

  @Output() public pathwayStopConfirmed = new EventEmitter<Boolean>();

  constructor(
    public readonly bsModalRef: BsModalRef,
    public localeService: LocaleService,
    public hcpService: HcpService,
    public pathwayService: PathwayService,
    public toastService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
  }

  handleSubmit() {
    this.isSaving = true;
    this.pathwayService.stopPatientPathway(
      this.hospitalUid,
      this.pathway.uid,
      this.patient.uid,
      this.reason_code.key,
      this.reason).subscribe(result => {
        this.isSaving = false;
        this.toastService.success(this.translate.instant('modals.stop_pathway_confirm_modal.pathway_stopped'));
        this.pathwayStopConfirmed.emit(true);
      }, () => this.isSaving = false)

  }

  onHandleClose(): void {
    this.bsModalRef.hide();
  }

}

