import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DoNotConsentModalComponent } from '../../../../modals/do-not-consent-modal/do-not-consent-modal.component';
import { Router } from '@angular/router';
import { Consent, ConsentStatus } from '../../../../models/consent';
import { DataService } from '../../../../services/data.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { UserService } from '../../../../services/user.service';
import { ConsentService } from '../../../..//services/consent.service';
import { GeneralService } from '../../../..//services/general.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-consent-detail',
  templateUrl: './settings-consent-detail.component.html',
  styleUrls: ['./settings-consent-detail.component.scss']
})
export class SettingsConsentDetailComponent implements OnInit {
  public revokeModal: BsModalRef;
  public consent: Consent;
  public isPerformingAction = false;

  constructor(
    public modalService: BsModalService,
    private readonly dataService: DataService,
    public readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    public readonly userService: UserService,
    public consentService: ConsentService,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.consent = this.dataService.get(DataService.KeyConsentSelected);
    this.dataService.clear(DataService.KeyConsentSelected);
  }

  doConsent() {
    if (!this.consent.isPlatformConsent()) {
      return;
    }

    this.isPerformingAction = true;

    this.consentService.updateConsentStatus(this.consent, ConsentStatus.CONSENTED).subscribe(() => {
      setTimeout(() => this.doConsentSuccess(), 1000);
    });
  }

  doConsentSuccess() {
    this.isPerformingAction = false;
    this.consent.status = ConsentStatus.CONSENTED;

    this.showToast();

    //
    // BEHAVIOUR DISABLED PER REQUEST OF AGIK-5054
    //
    // if (this.consent.isPlatformContactConsent()) {
    //   this.router.navigateByUrl('/settings/personal-information');
    // }
  }

  doDeconsent() {
    if (this.consent.isHospitalConsent()) {
      this.showRevokeConsentModal();
    }
    if (this.consent.isPlatformConsent()) {
      this.isPerformingAction = true;
      this.consentService.updateConsentStatus(this.consent, ConsentStatus.DECLINED).subscribe(() => {
        this.consent.status = ConsentStatus.DECLINED;
        this.isPerformingAction = false;

        this.showToast();
      });
    }
  }

  showRevokeConsentModal() {
    this.revokeModal = this.modalService.show(DoNotConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          isRevokeModal: true
        }
      })
    );

    this.revokeModal.content.onChoice.subscribe(() => this.handleRevokeConsent());
  }

  handleRevokeConsent() {
    this.revokeModal.content.isLoading = true;

    this.consentService.updateConsentStatus(this.consent, ConsentStatus.DECLINED).subscribe(
      () => {
        this.revokeModal.content.isLoading = false;
        this.revokeModal.hide();
        this.showToast();
        this.authenticationService.logout();
      },
      () => {
        this.revokeModal.content.isLoading = false;
        this.revokeModal.hide();
      }
    );
  }

  showToast() {
    this.toastrService.success(this.translate.instant('pages.default.settings.consent_detail.consent_updated'));
  }
}
