<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part">
      <h5>{{ 'pages.flow.expired_password_reset.pw_expired_choose_new' | translate }}</h5>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
        <div class="form-group">
          <label id="label_expired_password_new_pw" for="input_expired_password_new_pw">{{ 'pages.flow.expired_password_reset.new_pw' | translate }}</label>

          <input id="input_expired_password_new_pw" type="password" class="form-control w-100" placeholder="" formControlName="new_password" [ngClass]="{'ng-invalid-important': (form?.errors?.mismatch)}">

          <p id="feedback_expired_password_pw_complexity_error" class="validation-feedback" *ngIf="form?.controls?.new_password?.errors">{{ 'pages.flow.expired_password_reset.pw_complexity_error' | translate }}</p>
        </div>

        <div class="form-group">
          <label id="label_expired_password_confirm_new_pw" for="input_expired_password_confirm_new_pw">{{ 'pages.flow.expired_password_reset.confirm_new_pw' | translate }}</label>

          <input id="input_expired_password_confirm_new_pw" type="password" class="form-control w-100" placeholder="" formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': form?.errors?.mismatch}">

          <p id="feedback_expired_password_pw_not_the_same" class="validation-feedback general-feedback" *ngIf="form?.errors?.mismatch">{{ 'pages.flow.expired_password_reset.pw_not_the_same' | translate }}</p>
        </div>

        <button id="button_expired_password_submit" type="submit" class="btn btn-primary mt-2" [ngClass]="{'loader': isLoading}">
          <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'pages.flow.expired_password_reset.change_pw' | translate }}</span>
        </button>
      </form>
    </div>

    <div class="content-part">
      <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible" [passwordControl]="form?.controls?.new_password" ></app-password-policy-rules>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="layout-background"></div>
</div>
