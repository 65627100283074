<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h5>{{ 'pages.default.administration.mdts.mdts' | translate }}</h5>

        <button class="btn btn-primary" id="admin-mdts-add-mdt"
                (click)="onShowAddMdtModal($event)">
          {{ 'pages.default.administration.mdts.add_mdt' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container pb-4">
    <div class="row w-100 justify-content-between align-items-center">
      <div class="col col-auto">
        <div class="filters-group">

          <div class="form-group form-group-search">
            <label for="searchMdtName">{{ 'form.labels.mdt_name' | translate }}</label>

            <input type="text" id="searchMdtName" class="form-control form-control-sm"
                    placeholder=""
                    [(ngModel)]="filters.name"
                    (ngModelChange)="searchMdtNameEvent.next($event)">

          </div>

          <div class="form-group form-group-search">
            <label for="searchHcpLastName">{{ 'form.labels.hcp_last_name' | translate }}</label>

            <input type="text" id="searchHcpLastName" class="form-control form-control-sm"
                    placeholder=""
                    [(ngModel)]="filters.hcpLastName"
                    (ngModelChange)="searchHcpLastNameEvent.next($event)" required>

          </div>

          <div class="divider d-none"></div>


          <app-select
            id="admin-mdts-select-function"
            class="d-none"
            [label]="('form.labels.function' | translate)"
            [clearable]="true"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="true"
            [items]="functions"

            [(ngModel)]="functionFilter"
            (ngModelChange)="onFunctionFilterChange($event)"

            [asFilter]="true"
            [showCheckboxes]="true"
          ></app-select>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-mdts-reset-filters"
                  *ngIf="filtersAreSet" (click)="onResetFilters()">{{ 'action.reset' | translate }}
          </button>
        </div>
      </div>

      <div class="col col-auto">
        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="dropdown-btn-sort"
              [label]="'general.sort_by' | translate"
              [selectClass]="'ng-select-sm ng-select-align-end'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="sortingOptions"
              [bindValue]="'value'"
              [(ngModel)]="sorting"

              (ngModelChange)="onChangeSort()"

              [asFilter]="false"
            ></app-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div *ngFor="let mdt of mdts; let index = index;">
      <div id="mdt-{{index}}" class="action-item-block card card-lg mt-2">
        <div class="card-body">
          <div class="d-flex align-items-center mb-2">
            <h6 class="h7 mb-0">{{ mdt.name }}</h6>

            <button class="block-action m-start-auto btn btn-secondary" (click)="onShowEditMdtModal($event, mdt)"
                    [id]="'admin-mdts-edit-mdt-' + mdt.uid">
              {{ 'action.edit' | translate }}
            </button>
          </div>

          <div class="row">
            <div class="col-4 mb-2" *ngFor="let hcp of mdt.hcps">
              <div class="participant-item">
                <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
                <div class="meta">
                  <p class="title">{{ hcp.getFullName() }}</p>
                  <p class="description">{{ hcp.job_title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1">
          <app-pagination [totalPages]="totalPages" [activePage]="currentPage"
                          (pageChange)="onPaginationPageChanged($event)"></app-pagination>
        </div>
        <!-- pagination range -->
        <div class="m-start-auto">
          <app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)"
                                [rangeOptions]="[5,10]"></app-pagination-range>
        </div>
      </div>
    </div>
  </div>
</ng-container>
