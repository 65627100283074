<div class="row">
  <div class="col">
    <div class="form-group form-group-calendar">
      <label class="text-muted small" for="inputDate">{{ 'general.date' | translate }}</label>
      <input type="text" id="inputDate" class="form-control w-100" [ngClass]="{'ng-invalid-important': !editDateValid}" bsDatepicker autocomplete="off" [(ngModel)]="editDateValue" (ngModelChange)="onDateNgModelChange($event)" [disabled]="disabled">
    </div>
  </div>
  <div class="col">
    <div class="form-group">
      <label class="text-muted small" for="timePickerField">{{ 'general.time' | translate }}</label>
      <timepicker id="timePickerField" [ngClass]="{'ng-invalid-important': !editTimeValid}" [showMeridian]="!time_24_hours" [showSpinners]="false" [mousewheel]="false" dir="ltr" [(ngModel)]="editTimeValue" (ngModelChange)="onTimeNgModelChange($event)" [disabled]="disabled"></timepicker>
    </div>
  </div>
</div>
