<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <app-back-link [route]="backButtonData?.url" label="pages.helpcenter.faq_detail.back_to_faq" />
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    <app-faq-content [faq]="faq" [category]="category" (goToFaq)="goToFaq($event)"></app-faq-content>
  </div>
</div>
