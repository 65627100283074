<div class="modal-header">
  <h6 class="h7">{{ 'modals.bulk_approval.title' | translate }}</h6>
  <button type="button" class="btn-close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="handleClose()"></button>
</div>

<div class="modal-body">
  <p>{{ 'modals.bulk_approval.description' | translate }}</p>

  <hr />

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select [placeholder]="('modals.bulk_approval.clinical_lead' | translate)" [clearable]="false"
        [searchable]="true" [hideSelected]="false" [multiple]="false" [items]="CLList" [searchFn]="customSearchFn"
        (search)="searchCLListEvent.next($event)" formControlName="clinical_lead" [asFilter]="false"
        [showCheckboxes]="false" [showUserDetails]="true"></app-select>
      <app-feedback-field [field]="form.get('clinical_lead')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select [placeholder]="('modals.bulk_approval.case_manager' | translate)" [clearable]="false"
        [searchable]="true" [hideSelected]="false" [multiple]="false" [items]="CMList" [searchFn]="customSearchFn"
        (search)="searchCMListEvent.next($event)" formControlName="case_manager" [asFilter]="false"
        [showCheckboxes]="false" [showUserDetails]="true"></app-select>
      <app-feedback-field [field]="form.get('case_manager')"></app-feedback-field>
    </div>
  </form>

  <hr />
  <!-- default MDTs and other hcps -->
  <h6 class="h7">{{ 'modals.bulk_approval.mdts' | translate }}</h6>

  <div class="form-group">
    <ng-select (change)="onAddMdtOrHcp()" (search)="searchMdtOtherHcpListEvent.next($event)"
      [(ngModel)]="mdtOrHcpSelect" [clearable]="true" [items]="allMdtsOrHcps"
      [placeholder]="'modals.edit_hospital_team_modal.search_team_hcp' | translate" [searchFn]="customSearchFn"
      class="users-select">

      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item?.className === 'MedicalTeam'">{{item?.name}}</span>
        <span *ngIf="item?.className === 'HealthCareProfessional'">{{item?.getFullName()}}</span>
      </ng-template>

      <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <div *ngIf="item?.className === 'MedicalTeam'" class="wrapper" id="mdt-info">
          <div class="avatar">
            <span [inlineSVG]="'/assets/svg/community-24.svg'" class="icon"></span>
          </div>
          <div class="user-info">
            <p class="title">{{item?.name}}</p>
            <p class="sub-text">{{item?.hcpsAllNames}}</p>
          </div>
          <div *ngIf="isMdtSelected(item)" class="status small text-muted m-start-2">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isMdtSelected(item)" class="btn btn-tertiary m-start-2">
            <span [inlineSVG]="'/assets/svg/add-24.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
        <div *ngIf="item?.className === 'HealthCareProfessional'" class="wrapper" id="hcp-info">
          <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

          <div class="user-info">
            <p class="title">{{item?.getFullName()}}</p>
            <p class="sub-text">{{ item?.job_title }}</p>
          </div>
          <div *ngIf="isHcpSelected(item)" class="status small text-muted m-start-2">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isHcpSelected(item)" class="btn btn-tertiary m-start-2">
            <span [inlineSVG]="'/assets/svg/add-24.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
      </ng-template>

    </ng-select>
  </div>

  <div *ngIf="careModule?.default_mdts?.length" class="mt-4">
    <p class="mb-2 font-weight-bold">{{ 'modals.edit_hospital_team_modal.teams' | translate }}</p>

    <div *ngFor="let mdt of careModule?.default_mdts" class="participant-item">
      <div class="avatar">
        <span [inlineSVG]="'/assets/svg/community-24.svg'" class="icon"></span>
      </div>
      <div class="meta">
        <p class="title">{{ mdt?.name }}</p>
        <p class="description">
          <span *ngFor="let hcp of mdt?.hcps; let i=index">
            <span>{{ hcp?.getFullName() }}</span>
            <span *ngIf="(i < (mdt?.hcps?.length - 1))">, </span>
          </span>
        </p>
      </div>
      <a (click)="onRemoveMdt($event, mdt)" [id]="'default-mdt-modal-remove-mdt-' + mdt.uid"
        class="btn btn-tertiary" href="">
        <span [inlineSVG]="'/assets/svg/close-24.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>

  <div *ngIf="careModule?.default_hcps?.length" class="mt-4">
    <p class="mb-2 font-weight-bold">{{ 'modals.edit_hospital_team_modal.other_hcps' | translate }}</p>

    <div *ngFor="let hcp of careModule?.default_hcps" class="participant-item">
      <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title">{{ hcp?.getFullName() }}</p>
        <p class="description">{{ hcp?.job_title }}</p>
      </div>
      <a (click)="onRemoveHcp($event, hcp)" [id]="'default-mdt-modal-remove-hcp-' + hcp.uid"
        class="btn btn-tertiary m-start-1" href="">
        <span [inlineSVG]="'/assets/svg/close-24.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>

</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="appointment-form-cancel" (click)="handleClose()">{{ 'action.cancel' |
      translate}}</button>
    <button class="btn btn-primary m-start-2" id="appointment-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
