import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription, from } from 'rxjs';
import { DateFormat } from '../../../models/date-format';
import { UserTaskBasic } from '../../../models/user-task-basic';
import { LocaleService } from '../../../services/locale.service';
import { UserTaskService } from '../../../services/user-task.service';
import { Patient } from '../../../models/patient';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HcpService } from '../../../services/hcp.service';
import { Ooo } from '../../../models/ooo';
import { OooService } from '../../../services/ooo.service';
import { UserTaskModalService } from '../../../services/user-task-modal.service';
import { UserTaskAppointmentModalComponent } from '../../../modals/user-task-appointment-modal/user-task-appointment-modal.component';
import { UserTaskStandardModalComponent } from '../../../modals/user-task-standard-modal/user-task-standard-modal.component';
import { GeneralService } from '../../../services/general.service';
import { AttentionCenterService } from '../../../services/attention-center.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../services/language.service';
import { PageTabItem } from '../../../models/page-tab-item';
import { PathwayService } from '../../../services/pathway.service';
import { PathwayMessage } from '../../../models/pathway-message';
import { PatientService } from '../../../services/patient.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CareModule } from '../../../models/care-module';
import { HospitalService } from '../../../services/hospital.service';
import { PathwayMessageModalComponent } from '../../../modals/pathway-message-modal/pathway-message-modal.component';
import { concatMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {
  public isLoadingMessages: boolean;
  public isLoadingTasks: boolean;
  public hcp_uid: string;
  public currentContext: string;

  public pageTabItems: PageTabItem[] = [];

  public filters: any;
  public filtersAreSet: boolean;

  public filterModelPatient: Patient;
  public filterModelCareModule: CareModule;

  public filter_patients: Array<Patient>;
  public filter_careModules: Array<CareModule>;

  public messages: Array<PathwayMessage>;
  public tasks: Array<UserTaskBasic>;

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;

  public sortingAsc: boolean;

  public ooo: Ooo;
  public dateFormat: DateFormat;

  public selectedMessagesMap = new Map<PathwayMessage, boolean>();
  public selectedMessageUids: string[] = [];
  public selectedAll = false;

  public messagesSubscription: Subscription;
  public tasksSubscription: Subscription;
  public attentionCenterInitSubscription: Subscription;
  public attentionCenterTasksSubscription: Subscription;
  public attentionCenterMessagesSubscription: Subscription;

  public patientLastNameSearch = new Subject<string>();

  public get showBulkActions(): boolean {
    return this.selectedMessageUids.length > 0;
  }

  constructor(
    public taskService: UserTaskService,
    public pathwayService: PathwayService,
    public hcpService: HcpService,
    public localeService: LocaleService,
    public modalService: BsModalService,
    public router: Router,
    public translateService: TranslateService,
    public oooService: OooService,
    public userTaskModalService: UserTaskModalService,
    public generalService: GeneralService,
    public attentionCenterService: AttentionCenterService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService,
    public patientService: PatientService,
    public hospitalService: HospitalService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.clearFilters();

    this.hcp_uid = this.hcpService.getCurrentStoredHcpUid();
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.createTabs();
    this.connectAttentioncenter();
    this.getOoo();
    this.getFilterPatients();
    this.getFilterCareModules();

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.currentPage = 1;
        this.filters.last_name = value;
        this.getFilterPatients(value)
      });
  }

  ngOnDestroy() {
    this.disconnectAttentioncenter();
    this.patientLastNameSearch.complete();
  }

  createTabs(): void {
    const _pageTabItems = [];

    const messagesTab: PageTabItem = new PageTabItem('pages.default.tasks.patient_updates', null, {
      messagesTab: true
    });

    const tasksTab: PageTabItem = new PageTabItem('pages.default.tasks.patient_tasks', null, {
      tasksTab: true
    });

    _pageTabItems.push(messagesTab);
    _pageTabItems.push(tasksTab);

    this.pageTabItems = _pageTabItems;

    if (!this.currentContext) {
      this.onTabChangeHandler(this.pageTabItems[0]);
    }
  }

  connectAttentioncenter() {
    this.fillAttentionCenter();

    this.attentionCenterInitSubscription = this.attentionCenterService.onInitData.subscribe(() => this.fillAttentionCenter());
    this.attentionCenterMessagesSubscription = this.attentionCenterService.onNewPatientPathwayMessagesUnread.subscribe(() => this.fillAttentionCenter());
    this.attentionCenterTasksSubscription = this.attentionCenterService.onNewUserTasksPending.subscribe(() => this.fillAttentionCenter());
  }

  fillAttentionCenter() {
    const messagesTab: PageTabItem = this.pageTabItems.find(_tab => _tab?.data?.messagesTab === true);
    const tasksTab: PageTabItem = this.pageTabItems.find(_tab => _tab?.data?.tasksTab === true);

    messagesTab.badge = this.attentionCenterService.ac?.patientPathwayMessagesUnread;
    tasksTab.badge = this.attentionCenterService.ac?.userTasksPending;
  }

  disconnectAttentioncenter() {
    this.attentionCenterInitSubscription?.unsubscribe();
    this.attentionCenterMessagesSubscription?.unsubscribe();
    this.attentionCenterTasksSubscription?.unsubscribe();
  }

  onTabChangeHandler(pageTabItem: PageTabItem): void {
    if (pageTabItem?.data?.messagesTab && this.currentContext !== 'MESSAGES') {
      this.initTab('MESSAGES');
    } else if (pageTabItem?.data?.tasksTab && this.currentContext !== 'TASKS') {
      this.initTab('TASKS');
    }
  }

  initTab(context: string) {
    this.messages = undefined;
    this.isLoadingMessages = false;
    this.messagesSubscription?.unsubscribe();

    this.tasks = undefined;
    this.isLoadingTasks = false;
    this.tasksSubscription?.unsubscribe();

    this.currentContext = context;
    this.currentPage = 1;
    this.loadContextItems();
  }

  loadContextItems(showLoading: boolean = true) {
    this.selectedAll = false;
    this.selectedMessageUids = [];
    this.selectedMessagesMap.clear();

    switch (this.currentContext) {
      case 'MESSAGES': this.loadMessages(showLoading); break;
      case 'TASKS': this.loadTasks(showLoading); break;
    }
  }

  loadMessages(showLoading: boolean = true) {
    if (showLoading) {
      this.messages = undefined;
      this.isLoadingMessages = true;
    }

    const sorting: string = `send_at,${this.sortingAsc ? 'asc' : 'desc'}`;
    const filters: any = this.prepareFiltersPayload();

    this.messagesSubscription = this.pathwayService.getMessages(filters, this.currentPage - 1, this.currentPageSize, [sorting]).subscribe(result => {
      this.messages = result?.messages;
      this.isLoadingMessages = false;
      this.setPagination(result?.pagination);
    }, () => {
      this.messages = undefined;
      this.isLoadingMessages = false;
    });
  }

  loadTasks(showLoading: boolean = true) {
    if (showLoading) {
      this.tasks = undefined;
      this.isLoadingTasks = true;
    }

    const sorting: string = `created_at,${this.sortingAsc ? 'asc' : 'desc'}`;
    const filters: any = this.prepareFiltersPayload();

    filters.max_priority = UserTaskService.OVERVIEW_MAX_PRIORITY;
    filters.min_priority = UserTaskService.OVERVIEW_MIN_PRIORITY;

    this.tasksSubscription = this.taskService.getUserTasks(this.hcp_uid, filters, this.currentPage - 1, this.currentPageSize, [sorting]).subscribe(result => {
      this.tasks = result?.tasks;
      this.isLoadingTasks = false;
      this.setPagination(result?.pagination);
    }, () => {
      this.tasks = undefined;
      this.isLoadingTasks = false;
    });
  }

  prepareFiltersPayload() {
    const filters: any = Object.assign({}, this.filters);

    if (filters?.patient) {
      filters.patient_uid = filters.patient?.uid;
      delete filters.patient;
    }

    if (filters?.careModule) {
      filters.care_module_uid = filters?.careModule;

      delete filters.careModule;
    }

    return filters;
  }

  get isLoading(): boolean {
    return (this.isLoadingTasks || this.isLoadingMessages);
  }

  setPagination(pagination: any) {
    this.totalPages = pagination?.total_pages;
    this.totalElements = pagination?.total_elements;

    if (this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
      this.currentPage = 1;
      this.loadContextItems(true);
    }
  }

  getOoo(): void {
    this.oooService.getOutOfOffice(this.hcp_uid).subscribe(result => {
      this.ooo = result;
    });
  }

  getFilterPatients(event?) {
    let term = '';

    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.filter_patients = undefined;
    }

    const maxLimit: number = 150;
    const sort: string = 'last_name,desc;first_name,desc';
    const filters: any = {
      last_name: term,
      extensions: null
    };

    if (this.authenticationService.hasCcRole()) {
      const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
      this.patientService.getPatientsByHospital(hospital_uid, filters, sort, 0, maxLimit).subscribe(result => {
        this.filter_patients = result.patients;
      });
    } else {
      const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
      this.patientService.getPatientsByHcp(hcp_uid, filters, sort, 0, maxLimit).subscribe(result => {
        this.filter_patients = result.patients;
      });
    }
  }

  getFilterCareModules() {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModules(hospital_uid).subscribe(careModules => {
      this.filter_careModules = careModules;
    });
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.loadContextItems(true);
  }

  clearFilters() {
    this.filters = {};
    this.filtersAreSet = false;
  }

  actionResetFilters() {
    this.clearFilters();
    this.loadContextItems(true);
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.loadContextItems();
      window.scroll(0, 0);
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.loadContextItems();
      window.scroll(0, 0);
    }
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return `${itemsLabel} ${translatedNumber}`;
    }

    return `${translatedNumber} ${itemsLabel}`;
  }

  goToPatientDetail(event, patientUid: string, pathwayUid?: string) {
    event.preventDefault();

    let params = {
      'back-to-url': this.router.url
    };

    if (pathwayUid) {
      params['pathwayUid'] = pathwayUid;
    }

    this.router.navigate(['/patient/', patientUid], {
      queryParams: params
    });
  }

  openPathwayMessage(event, message: PathwayMessage) {
    event?.preventDefault();

    const initialState = {
      pathwayMessage: message,
    };

    const modalref = this.modalService.show(PathwayMessageModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref?.content?.onRead.subscribe(() => this.whenContextIsUpdated());
  }

  openUserTask(event, userTaskBasic: UserTaskBasic) {
    event?.preventDefault();

    switch (userTaskBasic?.type.toLowerCase()) {
      case 'appointment': this.showUserTaskAppointmentModal(userTaskBasic); break;
      case 'standard': this.showUserTaskStandardModal(userTaskBasic); break;
      case 'checklist': this.showUserTaskStandardModal(userTaskBasic); break;
      case 'questionnaire': this.showUserTaskStandardModal(userTaskBasic); break;
      default: console.error('User-task type not supported'); break;
    }
  }

  showUserTaskAppointmentModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic: userTaskBasic,
      task_id: userTaskBasic.uid,

    };
    const modal = this.modalService.show(UserTaskAppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );

    modal?.content?.taskSubmitSuccess.subscribe(() => this.whenContextIsUpdated());
  }

  showUserTaskStandardModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic
    };
    const modal = this.modalService.show(UserTaskStandardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact ',
        initialState
      })
    );

    modal?.content?.taskSaveSuccess.subscribe(() => this.whenContextIsUpdated());
    modal?.content?.taskSubmitSuccess.subscribe(() => this.whenContextIsUpdated());
  }

  whenContextIsUpdated() {
    this.attentionCenterService.refresh();
    this.loadContextItems(false);
  }

  onToggleCheckbox(msg: PathwayMessage): void {
    const msgWasSelected = this.selectedMessagesMap.get(msg);

    // update selected-list
    if (msgWasSelected) {
      // was true, will toggle to false
      const index = this.selectedMessageUids.indexOf(msg.id);
      if (index > -1) {
        this.selectedMessageUids.splice(index, 1);
      }
    } else {
      this.selectedMessageUids.push(msg.id);
    }

    // toggle select
    this.selectedMessagesMap.set(msg, !msgWasSelected);
  }

  onToggleAllCheckbox(): void {
    if (!this.messages) return;

    this.selectedAll = !this.selectedAll;
    for (const message of this.messages) {
      this.selectedMessagesMap.set(message, this.selectedAll);

      if (this.selectedAll) {
        this.selectedMessageUids.push(message.id);
      }
    }

    if (!this.selectedAll) {
      this.selectedMessageUids = [];
    }
  }

  markSelectionRead() {
    this.handleSelection('READ')
  }

  markSelectionUnread() {
    this.handleSelection('UNREAD');
  }

  handleSelection(action: 'READ' | 'UNREAD') {
    let uids: string[] = [];

    this.selectedMessagesMap.forEach((key: boolean, value: PathwayMessage) => {
      if (key && !value.isRead && action === 'READ') {
        uids.push(value.id);
      }

      if (key && value.isRead && action === 'UNREAD') {
        uids.push(value.id)
      }
    });

    this.selectedAll = false;
    this.selectedMessagesMap.clear();
    this.selectedMessageUids = [];

    if (uids.length) {
      this.isLoadingMessages = true;

      from(uids).pipe(
        concatMap(uid => this.pathwayService.toggleReadStatusMessage(uid))
      ).subscribe({
        complete: () => {
          this.loadMessages();
          this.fillAttentionCenter();
        }
      });
    }
  }
}
