import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {forkJoin, Observable} from 'rxjs';
import {Patient} from '../../models/patient';
import {MdtService} from '../../services/mdt.service';
import {NgSelectComponent} from '@ng-select/ng-select';
import {PathwayService} from '../../services/pathway.service';
import {Pathway} from '../../models/pathway';
import {PatientMdt} from '../../models/patient_mdt';
import {MedicalTeam} from '../../models/medical-team';
import {HealthCareProfessional} from '../../models/health-care-professional';
import {PatientService} from '../../services/patient.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {HcpService} from '../../services/hcp.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CareModule } from '../../models/care-module';


@Component({
  selector: 'app-assign-mdt-modal',
  templateUrl: './assign-mdt-modal.component.html',
  styleUrls: ['./assign-mdt-modal.component.scss']
})
export class AssignMdtModalComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Output() assignmentSuccessEvent = new EventEmitter<any>();

  @Input() type: 'add' | 'edit' | 'bulk-assign' = 'edit';
  @Input() patient: Patient;
  @Input() patientList: Patient[] = [];
  @Input() pathway: Pathway;
  @Input() careModule: CareModule;

  mdtOrHcpSelect: MedicalTeam | HealthCareProfessional;
  patientMdt: PatientMdt = new PatientMdt();
  allMdtsOrHcps: Array<MedicalTeam | HealthCareProfessional> = [];
  addJobs: Array<MedicalTeam | HealthCareProfessional> = [];
  removeJobs: Array<MedicalTeam | HealthCareProfessional> = [];
  hasCCRole: boolean;

  isLoading: boolean;
  isSaving: boolean;
  validationVisible: boolean;
  form: UntypedFormGroup;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly hcpService: HcpService,
    private readonly mdtService: MdtService,
    public readonly pathwayService: PathwayService,
    private readonly patientService: PatientService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.hasCCRole = this.authService.hasCcRole();
    this.getAllMdtsAndHcps('');
    this.getPathway();
  }

  private getPathway(): void {
    if (this.type !== 'bulk-assign') {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      const pathWayObservable = this.hasCCRole
        ? this.pathwayService.getDashboardPatientPathwayByHospital(hospitalUid, this.patient?.uid, this.pathway.uid)
        : this.pathwayService.getDashboardPatientPathwayByHcp(hcpUid, this.patient?.uid, this.pathway.uid);

      this.isLoading = true;

      setTimeout(() => {
        pathWayObservable.subscribe(result => {
          if (result.patient_mdt) {
            this.patientMdt = result.patient_mdt;
          }
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        });
      },500);
    }
  }

  onHandleAssign(): void {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.isSaving = true;

    if (this.type === 'bulk-assign') {
      const patient_pathway_ids: string[] = [];

      if (this.careModule) {
        this.patientList.forEach((patient: Patient) => {
            const pathway = patient.pathways.find(
              (_pathway: Pathway) => _pathway.care_module.uid ===   this.careModule.uid);
            patient_pathway_ids.push(pathway.uid);
        });
      }

      this.patientService.bulkAssignment(
        hospitalUid,
        {
          mdt_uids: this.addJobs.filter(job => job instanceof MedicalTeam).map(mdt => mdt.uid),
          hcp_uids: this.addJobs.filter(job => job instanceof HealthCareProfessional).map(hcp => hcp.uid),
          patient_pathway_ids
        }
      ).subscribe(() => {
        this.isSaving = false;
        this.toastrService.success(this.translateService.instant('modals.assign_mdt.bulk_assign_success'));
        this.assignmentSuccessEvent.emit({"addJobs": this.addJobs});
        this.onHandleCancel();
      }, () => {
        this.isSaving = false;
        this.toastrService.error(this.translateService.instant('form.feedback.something_went_wrong'));
      });
    } else {
      this.pathway.patient_mdt = this.patientMdt;
      const observables = [];

      this.addJobs.forEach(item => {
        if (item.className === 'MedicalTeam') {
          const observable = this.pathwayService.addMdt(hospitalUid, this.patient.uid, this.pathway.uid, item.uid);
          observables.push(observable);
        }
        if (item.className === 'HealthCareProfessional') {
          const observable = this.pathwayService.addHcp(hospitalUid, this.patient.uid, this.pathway.uid, item.uid);
          observables.push(observable);
        }
      });

      this.removeJobs.forEach(item => {
        if (item.className === 'MedicalTeam') {
          const observable = this.pathwayService.deleteMdt(hospitalUid, this.patient.uid, this.pathway.uid, item.uid);
          observables.push(observable);
        }
        if (item.className === 'HealthCareProfessional') {
          const observable = this.pathwayService.deleteHcp(hospitalUid, this.patient.uid, this.pathway.uid, item.uid);
          observables.push(observable);
        }
      });

      forkJoin(observables).subscribe(() => {
        this.isSaving = false;
        this.assignmentSuccessEvent.emit(this.patientMdt);
        this.onHandleCancel();
      }, () => {
        this.isSaving = false;
        this.toastrService.error(this.translateService.instant('form.feedback.something_went_wrong'));
      });
    }
  }

  private addToAddJobs(item): void {
    const index = this.removeJobs.indexOf(item);

    if (index >= 0) {
      this.removeJobs.splice(index, 1);
    } else {
      this.addJobs.push(item);
    }
  }

  private addToRemoveJobs(item): void {
    const index = this.addJobs.indexOf(item);

    if (index >= 0) {
      this.addJobs.splice(index, 1);
    } else {
      this.removeJobs.push(item);
    }
  }

  private getAllMdtsAndHcps(term: string): void {
    forkJoin([this.getMdts(term), this.getHcps(term)]).subscribe(results => {
      this.allMdtsOrHcps = [];
      this.allMdtsOrHcps = [...this.allMdtsOrHcps, ...results[0], ...results[1]];
    });
  }

  private getMdts(term: string): Observable<MedicalTeam[]> {
    return new Observable(observer => {
      this.mdtService.getPaged({name: term}, 'name,asc', 0, 50).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  private getHcps(term: string): Observable<HealthCareProfessional[]> {
    return new Observable(observer => {
      this.hcpService.getPaged({last_name: term, status: 'ACTIVE'}, 'last_name,asc', 0, 50).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  onAddMdtOrHcp(): void {
    if (!this.mdtOrHcpSelect) {
      return;
    }

    if (this.mdtOrHcpSelect instanceof MedicalTeam && !this.isMdtSelected(this.mdtOrHcpSelect)) {
      this.patientMdt.mdts.push(this.mdtOrHcpSelect);
    }
    if (this.mdtOrHcpSelect instanceof HealthCareProfessional && !this.isHcpSelected(this.mdtOrHcpSelect)) {
      this.patientMdt.otherHcps.push(this.mdtOrHcpSelect);
    }

    this.addToAddJobs(this.mdtOrHcpSelect);

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    this.getAllMdtsAndHcps('');
  }

  onRemoveHcp(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const index = this.patientMdt.otherHcps.indexOf(hcp);
    if (index >= 0) {
      this.patientMdt.otherHcps.splice(index, 1);
    }

    this.addToRemoveJobs(hcp);
  }

  onRemoveMdt(event, mdt: MedicalTeam): void {
    event.preventDefault();

    const index = this.patientMdt.mdts.indexOf(mdt);
    if (index >= 0) {
      this.patientMdt.mdts.splice(index, 1);
    }

    this.addToRemoveJobs(mdt);
  }

  isMdtSelected(mdt: MedicalTeam): boolean {
    const filtered = this.patientMdt?.mdts.filter(item => item.uid === mdt.uid);
    return filtered.length > 0;
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    const filtered = this.patientMdt?.otherHcps.filter(item => item.uid === hcp.uid);
    return filtered.length > 0;
  }

  onHandleCancel(): void {
    this.bsModalRef.hide();
  }

  onSearch(event): void {
    this.getAllMdtsAndHcps(event.term || '');
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  buttonDisabled(): boolean {
    return ((!this.addJobs || !this.addJobs.length) && (!this.removeJobs || !this.removeJobs.length));
  }
}
