<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/settings" label="action.back_to_settings" id="settings-preferences-back" />
    </div>

    <div class="container">
      <h5>{{ 'pages.default.settings.preferences.preferences' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <form [formGroup]="unitForm" id="unitForm">
      <div class="form-group width-limit d-none">
        <app-select
          id="preference-measurement-unit"
          [placeholder]="('pages.default.settings.preferences.units' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="measurementUnits"
          [bindValue]="'value'"
          formControlName="measurement_unit"
          (change)="handleUnitFormSubmission()"
        />
      </div>

      <div class="form-group width-limit mt-0">
        <app-select
          id="preference-number-format"
          [placeholder]="('pages.default.settings.preferences.number_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="numberFormats"
          [bindValue]="'value'"
          formControlName="number_format"
          (change)="handleUnitFormSubmission()"
        />
      </div>

      <hr />

      <div class="form-group width-limit">
        <app-select
          id="preference-date-format"
          [placeholder]="('pages.default.settings.preferences.date_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="dateFormats"
          [bindValue]="'value'"
          formControlName="date_format"
          (change)="handleUnitFormSubmission()"
        />
      </div>

      <div class="form-group width-limit">
        <app-select
          id="preference-time-zone"
          [placeholder]="('pages.default.settings.preferences.time_zone' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeZones"
          [bindValue]="'value'"
          formControlName="time_zone"
          (change)="handleUnitFormSubmission()"
        />
      </div>

      <div class="form-group width-limit">
        <app-select
          id="preference-time-24-hours"
          [placeholder]="('pages.default.settings.preferences.time_format' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeFormats"
          [bindValue]="'value'"
          formControlName="time_24_hours"
          (change)="handleUnitFormSubmission()"
        />
      </div>

      <hr />

      <div class="form-group width-limit">
        <app-select
          id="preference-first-day-of-week"
          [placeholder]="('pages.default.settings.preferences.first_day_of_week' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="weekdays"
          [bindValue]="'value'"
          formControlName="first_day_of_week"
          (change)="handleUnitFormSubmission()"
        />
      </div>
    </form>

  </div>
</div>
