<div class="modal-header">
  <h6 class="h7">{{ 'modals.assign_task.title' | translate }}</h6>
  <button type="button" class="btn-close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select
        [label]="('modals.assign_task.hcp' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hcpList"
        [searchFn]="customSearchFn"
        (search)="searchHcpListEvent.next($event)"
        (open)="searchHcpList()"
        [loading]="isLoadingHcps"
        [bindValue]="'value'"
        formControlName="hcp"
        [asFilter]="false"
        [showCheckboxes]="false"
        [showUserDetails]="true"

      ></app-select>
      <app-feedback-field [field]="form.get('hcp')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="assign-task-form-cancel" (click)="handleClose()">{{ 'action.cancel' | translate}}</button>
    <button class="btn btn-primary m-start-2" id="assign-task-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
