<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small" *ngIf="homeFlowService.getSidebarTitleKey()">{{
        homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboarding-icon-steps *ngIf="showOnboardingIconSteps"
        [currentState]="currentState"></app-onboarding-icon-steps>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">

    <div class="container" *ngIf="!isFormDataLoaded">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="isFormDataLoaded">
      <h3 class="mb-3">{{ 'pages.flow.onboarding_details.your_details' | translate }}</h3>
      <app-onboarding-details-form (onFormInitialized)="onFormInitialized($event)" [mode]="'onboarding'"
        [data]="formComponentData" [validationVisible]="validationVisible"></app-onboarding-details-form>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <button id="button_onboarding_details_confirm" class="btn btn-lg btn-primary m-start-auto" (click)="formSubmit()"
          [ngClass]="{'loader': isUpdatingDetails}">
          <span class="loader" *ngIf="isUpdatingDetails" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.continue' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
