<div class="modal-header">
  <ng-container *ngIf="!isRevokeModal">
    <h6 class="m-0">{{ 'action.do_not_consent' | translate }}</h6>
  </ng-container>

  <ng-container *ngIf="isRevokeModal">
    <h6 class="m-0">{{ 'action.do_not_consent' | translate }}</h6>
  </ng-container>

  <button type="button" class="btn-close" id="do-not-consent-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div *ngIf="!isRevokeModal">
    <p class="m-0">{{ 'modals.do_not_consent.consent_confirm' | translate }}<br/>{{ 'modals.do_not_consent.consent_info_no_account' | translate }}</p>
  </div>
  <div *ngIf="isRevokeModal">
    <p class="m-0">{{ 'modals.do_not_consent.consent_confirm' | translate }}<br/>{{ 'modals.do_not_consent.consent_info_deactivate' | translate }}</p>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="do-not-consent-modal-no" (click)="handleNo()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-primary m-start-2" id="do-not-consent-modal-yes" (click)="handleYes()" [ngClass]="{'loader': isLoading}">
      <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.i_am_sure' | translate }}</span>
    </button>
  </div>
</div>
