import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Country } from '../../../../models/country';
import { Faq } from '../../../../models/faq';
import { FaqCategory } from '../../../../models/faq-category';
import { HelpcenterArticle } from '../../../../models/helpcenter-article';
import { HelpcenterSection } from '../../../../models/helpcenter-section';
import { PageTabItem } from '../../../../models/page-tab-item';
import { CountryService } from '../../../../services/country.service';
import { DataService } from '../../../../services/data.service';
import { GeneralService } from '../../../../services/general.service';
import { HcpService } from '../../../../services/hcp.service';
import { HelpcenterService } from '../../../../services/helpcenter.service';

@Component({
  selector: 'app-help-center-overview',
  templateUrl: './help-center-overview.component.html',
  styleUrls: ['./help-center-overview.component.scss']
})
export class HelpCenterOverviewComponent implements OnInit {
  public pageTabItems: PageTabItem[] = [];
  public pageLoading = false;
  public helpcenterSections: HelpcenterSection[];
  public countries: Country[];
  public currentCountry: Country;

  constructor(
    public helpcenterService: HelpcenterService,
    public router: Router,
    public dataService: DataService,
    public generalService: GeneralService,
    public countryService: CountryService,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void {
    this.pageLoading = true;
    this.getHelpcenter();
  }

  getHelpcenter() {
    forkJoin([
      this.helpcenterService.getHelpCenter(),
      this.countryService.getCountriesFromCms(),
    ]).subscribe(data => {
      this.handleHelpCenterResponse(data);
    }, () => {
      this.pageLoading = false;
    });
  }

  handleHelpCenterResponse(data: [HelpcenterSection[], Country[]]) {
    const helpcenterSections = data[0];
    const currentHcp = this.hcpService.getCurrentStoredHcp();

    if (Array.isArray(data[1])) {
      this.countries = data[1].filter(country => country.phones?.length > 0);
    }

    if (currentHcp?.hasOwnProperty('country')) {
      this.currentCountry = this.countries.find(country => country.code === currentHcp['country']);
    } else {
      this.currentCountry = this.countries[0];
    }

    helpcenterSections.forEach((section: HelpcenterSection, i) => {
      if (!section.isBanner) {
        this.pageTabItems.push(new PageTabItem(section.title, `zone-${i}`));
        section['slug'] = `zone-${i}`;
      }
    });

    this.helpcenterSections = helpcenterSections;
    this.pageLoading = false;

    this.goToPageTabItem();
  }

  goToSectionOverview(section: HelpcenterSection, event) {
    event.preventDefault();
    this.dataService.set(DataService.HelpcenterSectionSelected, section);
    this.router.navigateByUrl(`/help-center/${section.type}`);
  }

  goToPageTabItem() {
    const cachedSelectedSection: HelpcenterSection = this.dataService.get(DataService.HelpcenterSectionSelected) as HelpcenterSection;
    let cacheSelectedPageTabItem;
    this.pageTabItems.forEach(element => {
      if (element.translationKey === cachedSelectedSection?.title) {
        cacheSelectedPageTabItem = element;
      }
    });

    if (cacheSelectedPageTabItem) {
      this.generalService.scrollToPageTabItem(cacheSelectedPageTabItem, false);
    }
  }

  goToArticleDetail(event, article: HelpcenterArticle, section) {
    event.preventDefault();
    this.dataService.set(DataService.HelpcenterArticleSelected, article);
    this.dataService.set(DataService.HelpcenterSectionSelected, section);
    this.router.navigate(['/help-center', 'articles', article.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });
  }

  goToFaq(result: { faq: Faq, category: FaqCategory, section: HelpcenterSection }): boolean {
    this.dataService.set(DataService.HelpcenterFaqSelected, result.faq);
    this.dataService.set(DataService.HelpcenterFaqCategorySelected, result.category);
    this.dataService.set(DataService.HelpcenterSectionSelected, result.section);

    this.router.navigate(['/help-center', 'faqs', result.faq.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });

    return false;
  }

  changeCountry(event, country: Country) {
    event.preventDefault();
    this.currentCountry = country;
  }
}
