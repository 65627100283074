<div class="container" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<div *ngIf="!isLoading">
  <div class="card kpi-card" *ngIf="phaseData?.length > 0">
    <div class="card-body">
      <h6 class="kpi-title">{{ 'pages.default.analytics.pathways-overview.phases-overview' | translate }}</h6>

      <div class="row">
        <div class="col-6">
          <div class="highcharts-wrapper" [chart]="pieChart"></div>

          <div id="legend" class="d-none">
            <p
              *ngFor="let phase of phaseData; let i = index; let last = last;"
              class="d-flex align-items-center text-truncate"
              [ngClass]="{ 'mb-0': last, 'mb-1': !last }"
            >
              <span class="m-end-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect y="0.5" width="24" height="24" [attr.fill]="colors[i]" />
                </svg>
              </span>
              <span class="small e2e-phase-title">{{ phase.title }}</span>
            </p>
          </div>
        </div>

        <div class="col-6 d-flex flex-column justify-content-center">
          <div
            *ngFor="let phase of phaseData; let i = index; let last = last;"
            [ngClass]="{ 'mb-1 pb-1 border-bottom': !last }"
          >
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="font-weight-semibold text-truncate mb-0">{{ phase.title }}</p>
              <a *ngIf="phase.has_sub_phases"
                href=""
                class="btn btn-sm btn-link flex-shrink-0 e2e-phase-details"
                (click)="selectPhase($event, phase)"
              >
                {{ 'pages.default.analytics.pathways-overview.phase-details' | translate }}
              </a>
            </div>

            <div *ngIf="phase.value > 0"
              class="text-center text-white font-weight-bold rounded-end mb-1 e2e-phase-value"
              [style.background-color]="colors[i]"
              [style.width]="barPercent(phase.value)"
            >
              {{ phase.value }}
            </div>

            <div *ngIf="(phase.value === 0)"
              class="text-center font-weight-bold mb-1 e2e-phase-value-zero"
              style="background-color: #f2f2f2; width:100%;"
            >
              {{ phase.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
