<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small" *ngIf="homeFlowService.getSidebarTitleKey()">{{ homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboarding-icon-steps *ngIf="showOnboardingIconSteps" [currentState]="currentState" [consents]="allConsents"
        [currentConsent]="currentConsent"></app-onboarding-icon-steps>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <app-page-loader *ngIf="!currentConsent"></app-page-loader>

      <div class="text-content-max" id="consent-{{currentConsent?.type}}-{{currentConsent?.order}}" *ngIf="currentConsent">
        <h3 class="mb-3" id="content_onboarding_consents_consent_title">{{ currentConsent?.title }}</h3>
        <h6 class="mb-5" id="content_onboarding_consents_consent_introduction">{{ currentConsent?.introduction }}</h6>
        <div  id="content_onboarding_consents_consent_body" [innerHtml]="currentConsent?.body"></div>
      </div>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="m-start-auto">
          <button id="button_onboarding_consents_revoke" class="btn btn-lg btn-secondary" *ngIf="canRevokeConsent()" (click)="showRevokeConsentModal()">{{ 'action.i_do_not_consent' | translate }}</button>
          <button id="button_onboarding_consents_skip" class="btn btn-lg btn-secondary m-start-3" *ngIf="canSkipConsent()" (click)="handleSkipConsent()" [ngClass]="{'loader': isLoadingSkip}">
            <span class="loader" *ngIf="isLoadingSkip" [inlineSVG]="'/assets/svg/loader-light-sm.svg'"></span>
            <span>{{ 'pages.flow.onboarding_consent.maybe_later' | translate }}</span>
          </button>
          <button id="button_onboarding_consents_consent" class="btn btn-lg btn-primary m-start-3" *ngIf="currentConsent" (click)="handleDoConsent()" [ngClass]="{'loader': isLoadingConsent}">
            <span class="loader" *ngIf="isLoadingConsent" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
            <span>{{ 'action.i_consent' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
