import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { ConfirmModalComponent } from "../../../modals/confirm-modal/confirm-modal.component";
import { QueryListParticipantsModalComponent } from "../../../modals/query-list-participants-modal/query-list-participants-modal.component";
import { QueryList, QueryListStatus } from "../../../models/query-list";
import { UserTask } from "../../../models/user-task";
import { GeneralService } from "../../../services/general.service";
import { HcpService } from "../../../services/hcp.service";
import { QueryListService } from "../../../services/query-list.service";
import { UserTaskService } from "../../../services/user-task.service";
import { DataService } from "../../../services/data.service";
import { AuthenticationService } from "../../../services/authentication.service";

@Component({
  selector: 'app-query-list-detail',
  templateUrl: './query-list-detail.component.html'
})
export class QueryListDetailComponent implements OnInit, AfterViewChecked {
  public patientPathwayUid: string;
  public patientUid: string;
  public hcpUid: string;
  public formUid: string;
  public isLoading: boolean = false;
  public queryList: QueryList;
  public task: UserTask;
  public form: FormGroup = this.formBuilder.group({});
  public validationVisible = false;
  public isSaving = false;
  public isSubmitting = false;
  public filter: string;
  public visibleCandidateUsers: number = 3;
  public type: string;


  constructor(
    public queryListService: QueryListService,
    public userTaskService: UserTaskService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public modalService: BsModalService,
    private translateService: TranslateService,
    public toastrService: ToastrService,
    public hcpService: HcpService,
    public dataService: DataService
  ) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(result => {
      let params = result[0];
      this.patientPathwayUid = params.patientPathwayUid;
      this.patientUid = params.patientUid;
      this.formUid = params.formUid;
      let qparams = result[1];
      this.filter = qparams['back-to-url'];
      this.type = qparams['type'];
      this.getQueryListItem();
    })
  }

  get isReadOnly(): boolean {
    return this.queryList?.isReadOnly(this.hcpUid);
  }

  getQueryListItem() {
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();

    if (this.type == 'STANDARD') {
      this.getUserTask();
    } else {
      this.queryListService.getQueryList(this.hcpUid, this.patientUid, this.patientPathwayUid, this.formUid).subscribe(result => {
        this.queryList = result;
        this.dataService.set(DataService.BreadCrumbQueryListTitle, this.queryList.title);

        this.queryList.sortedCandidateUsers(this.hcpUid);

        if (!this.isReadOnly) {
          this.getUserTask();
        } else {
          this.task = new UserTask(this.queryList);
        }
      });
    }
  }

  getUserTask() {
    this.userTaskService.getUserTask(this.formUid, this.hcpUid).subscribe((result: UserTask) => {
      this.handleGetUserTask(result);
    });
  }

  handleGetUserTask(result: UserTask) {
    this.task = result;

    if (this.type == 'STANDARD') {
      this.queryList = new QueryList();
      this.queryList.title = this.task.title;
      this.queryList.assignee = this.task.patient;
    }
  }

  editedBy() {
    if (!this.queryList || !this.queryList?.assignee) {
      return '';
    }

    if (this.queryList?.assignee?.uid === this.hcpUid) {
      return this.translate.instant('pages.default.query_lists.you').toLowerCase();
    } else {
      return this.queryList?.assignee?.getFullName();
    }
  }

  saveUserTask() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;
    this.userTaskService.saveUserTask(
      this.task.uid,
      this.hcpUid,
      this.form.value
    ).subscribe(() => {
      this.saveResultHandler();
    }, error => {
      this.errorHandler(error);
    });
  }

  confirmSubmitUserTask() {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.confirm_submit_query_list.title',
          description: 'modals.confirm_submit_query_list.description',
          yes: 'modals.confirm_submit_query_list.submit',
          no: 'modals.confirm_submit_query_list.cancel'
        }
      })
    );

    if (modalRef) {
      modalRef.content.onChoice.subscribe(() => {
        modalRef.hide();
        this.submitUserTask();
      });
    }
  }

  submitUserTask() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSubmitting = true;
    this.userTaskService.completeUserTask(
      this.task.uid,
      this.hcpUid,
      {
        variables: this.form.value
      }
    ).subscribe(() => {
      this.submitResultHandler();
    }, error => {
      this.errorHandler(error);
    });
  }

  saveResultHandler() {
    this.form.markAsPristine();
    this.queryList.changed_at = new Date().toString();
    this.queryList.status = QueryListStatus.SAVED;

    this.isSaving = false;
    this.validationVisible = false;

    this.toastrService.success(this.translate.instant('pages.default.query_lists.save_success'));
  }

  submitResultHandler() {
    this.isSubmitting = false;
    this.validationVisible = false;

    this.form.markAsPristine();
    this.queryList.status = QueryListStatus.COMPLETED;
    this.queryList.completed_at = new Date().toString();

    this.form.disable();
    this.toastrService.success(this.translate.instant('pages.default.query_lists.submit_success'));
  }

  errorHandler(error) {
    this.isSaving = false;
    this.isSubmitting = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {

        this.form.get(err.field)?.setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  // Validates if you're allowed to close the task
  async canDeactivate(): Promise<boolean> {
    if (!this.form?.touched || !this.form?.dirty) {
      return true;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.cancel_query_list.title',
          description: 'modals.cancel_query_list.description',
          yes: 'modals.cancel_query_list.leave',
          no: 'modals.cancel_query_list.cancel'
        }
      })
    );

    return new Promise<boolean>((resolve) => {
      modalRef.content.onChoice.subscribe(() => {
        modalRef.hide();
        resolve(true);
      });
    });
  }

  showCandidateUsersModal(event, participants) {
    event.preventDefault();
    const initialState = {
      participants
    };

    this.modalService.show(QueryListParticipantsModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-compact',
        initialState
      })
    );
  }
}
