import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DateFormat } from '../../models/date-format';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { UserTask } from '../../models/user-task';
import { UserTaskBasic } from '../../models/user-task-basic';
import { AuthenticationService } from '../../services/authentication.service';
import { ErrorService } from '../../services/error.service';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { LocaleService } from '../../services/locale.service';
import { PathwayService } from '../../services/pathway.service';
import { UserTaskService } from '../../services/user-task.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-user-task-standard-modal',
  templateUrl: './user-task-standard-modal.component.html',
  styleUrls: ['./user-task-standard-modal.component.scss']
})
export class UserTaskStandardModalComponent implements OnInit {
  public userTaskBasic: UserTaskBasic;
  public userTask: UserTask;

  public isLoading: boolean = false;
  public isSaving: boolean;

  public isSubmitting: boolean;
  public validationVisible: boolean;
  public form: FormGroup = this.formBuilder.group({});

  public patient_uid: string;
  public patient_pathway_uid: string;
  public hcps: HealthCareProfessional[] = [];

  public dateFormat: DateFormat;
  public asCc: boolean = false;
  public hcpUid: string;
  public showCandidateUsers: boolean = false;

  @Output() taskSaveSuccess: EventEmitter<any> = new EventEmitter();
  @Output() taskSubmitSuccess: EventEmitter<any> = new EventEmitter();

  @ViewChild('hcpselect', { read: NgSelectComponent }) hcpselect: NgSelectComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public localeService: LocaleService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public hcpService: HcpService,
    public authenticationService: AuthenticationService,
    public pathwayService: PathwayService,
    public userTaskService: UserTaskService,
    public errorService: ErrorService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public modalService: BsModalService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();

    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    this.bsDatepickerConfig.adaptivePosition = true;

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.getUserTask(this.userTaskBasic.uid);
  }

  getUserTask(id) {
    this.isLoading = true;

    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const observable = this.asCc ? this.userTaskService.getHospitalUserTask(id, hospitalUid) : this.userTaskService.getUserTask(id, this.hcpUid);

    observable.subscribe(result => {
      this.userTask = result
      this.patient_uid = this.userTask.patient.uid;
      this.patient_pathway_uid = this.userTask.patient_pathway_id;

      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  handleSaveChanges() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSaving = true;

    this.userTaskService.saveUserTask(
      this.userTask.uid,
      this.hcpUid,
      this.form.value
    ).subscribe(() => {
      this.saveResultHandler();
    }, error => {
      this.errorHandler(error);
    });
  }

  saveResultHandler() {
    this.isSaving = false;

    this.toastrService.success(this.translate.instant('modals.user_task_standard.update_success'));

    this.bsModalRef.hide();
    this.taskSaveSuccess.emit();
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return
    }

    this.validationVisible = false;
    this.isSubmitting = true;
    const hcp_uid = this.hcpService.getCurrentStoredHcpUid();

    this.userTaskService.completeUserTask(this.userTask.uid, hcp_uid, { variables: this.form.value }).subscribe(result => {
      this.submitresultHandler();
    }, error => {
      this.errorHandler(error);
    });

  }

  submitresultHandler() {
    this.isSubmitting = false;

    this.toastrService.success(this.translate.instant('modals.user_task_standard.update_success'));

    this.bsModalRef.hide();
    this.taskSubmitSuccess.emit();
  }

  errorHandler(error: any) {
    this.isSaving = false;
    this.isSubmitting = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  handleCancel() {
    if (!this.form?.touched || !this.form?.dirty) {
      this.bsModalRef.hide();
      return;
    }
    this.showConfirmModal();
  }

  setShowCandidateUsers($event: MouseEvent, value: boolean) {
    $event.preventDefault();
    this.showCandidateUsers = value;
  }

  showConfirmModal(): void {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.cancel_query_list.title',
          description: 'modals.cancel_query_list.description',
          yes: 'modals.cancel_query_list.leave',
          no: 'modals.cancel_query_list.cancel'
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.hide();
      this.bsModalRef.hide();
    });
  }
}

