<div class="layout-header-pusher header-back"></div>
<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/help-center" label="pages.default.help_center.help_center" labelPrefix="true" />
    </div>

    <div class="container">
      <h5>{{ helpcenterSection?.title }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="faq-blocks">
      <div class="items">
        <app-faq-block
          *ngFor="let category of faqCategories; last as last;"
          [class]="!last ? 'mb-5' : 'mb-3'"
          [category]="category"
          [section]="helpcenterSection"
          (goToFaq)="goToFaq($event)"
        />
      </div>
    </div>
  </div>
</div>
