<div class="banner-info-modal">
  <div class="modal-header">
    <h6 class="mb-0">{{ title }}</h6>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="hide()"></button>
  </div>

  <div class="modal-body">
    <div [innerHtml]="description" class="description"></div>
  </div>

  <div class="modal-footer no-shadow pt-0">
    <div class="m-start-auto">
      <button class="btn btn-secondary" id="more-info-banner-modal-i-understand" *ngIf="hasMoreInfo" (click)="showMoreInfoModal()">
        {{ 'action.read_more' | translate }}
      </button>

      <button class="btn btn-primary m-start-2" id="more-info-banner-modal-i-understand" (click)="confirm()">
        {{ 'action.i_understand' | translate }}
      </button>
    </div>
  </div>
</div>
