import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Conversation } from '../../models/conversation';
import { PathwayService } from '../../services/pathway.service';
import { MedicalTeam } from '../../models/medical-team';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { ConversationService } from '../../services/conversation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Pathway } from '../../models/pathway';
import { forkJoin } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HcpService } from '../../services/hcp.service';
import { Participant } from '../../models/participant';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-edit-conversation-participants-modal',
  templateUrl: './edit-conversation-participants-modal.component.html',
  styleUrls: ['./edit-conversation-participants-modal.component.scss']
})
export class EditConversationParticipantsModalComponent implements OnInit {
  @Output() public onParticipantsChange: EventEmitter<Array<any>> = new EventEmitter();

  public isLoading: boolean;
  public isSaving: boolean;
  public isLoadingHcps = false;

  public conversation: Conversation;
  public currentPathway: Pathway;

  public fullHcpList: HealthCareProfessional[] = [];
  public hcpList: HealthCareProfessional[];

  public currentHcp: HealthCareProfessional;
  public selectedHcps: any;
  public originalselectedHcps: any;
  public losslessHcpSelection: any[];

  public losslessHcpSelectModel: any;

  public mdts: MedicalTeam[];
  public otherHcps: HealthCareProfessional[];

  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public bsModalRef: BsModalRef,
    public authenticationService: AuthenticationService,
    public conversationService: ConversationService,
    public hcpService: HcpService,
    public pathwayService: PathwayService,
    public toastService: ToastrService,
    public translate: TranslateService,
    public errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.getScope();
    this.selectedHcps = {};
    this.originalselectedHcps = {};
    this.losslessHcpSelection = [];

    if (this.conversation.conversation_type === 'PATIENT_CONVERSATION') {
      this.loadPatientPathwayHandler();
    }

    this.conversation.hcps.forEach(participant => {
      if (!participant.removed) {
        this.selectedHcps[participant.uid] = true;
        this.originalselectedHcps[participant.uid] = true;

        if (this.conversation.conversation_type === 'HCP_CONVERSATION') {
          if (participant.uid !== this.currentHcp?.uid) {
            this.losslessHcpSelection.push(participant);
          }
        }
      }
    });

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHCPList(result);
    });
  }

  getScope() {
    this.currentHcp = this.hcpService.getCurrentStoredHcp();
  }

  loadPatientPathwayHandler() {
    this.isLoading = true;

    const patient_uid = this.conversation.patient.uid;
    const pathway_id = this.conversation.patient_pathway_id;

    if (this.authenticationService.hasCcRole()) {
      const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();

      this.pathwayService.getPathwayByHospital(hospital_uid, patient_uid, pathway_id).subscribe(
        e => this.pathwaySuccessHandler(e),
        e => this.pathwayErrorHandler(e)
      );
    } else {
      const hcp_uid = this.hcpService.getCurrentStoredHcpUid();

      this.pathwayService.getPathwayByHcp(hcp_uid, patient_uid, pathway_id).subscribe(
        e => this.pathwaySuccessHandler(e),
        e => this.pathwayErrorHandler(e)
      );
    }
  }

  pathwaySuccessHandler(pathway: Pathway) {
    this.currentPathway = pathway;
    this.isLoading = false;
    this.mdts = this.currentPathway.patient_mdt?.getMdts(true, [this.currentPathway.clinical_lead.uid, this.currentPathway.case_manager.uid, this.currentHcp.uid]);
    this.otherHcps = this.currentPathway.patient_mdt?.getOtherHcps(true, [this.currentPathway.clinical_lead.uid, this.currentPathway.case_manager.uid, this.currentHcp.uid]);
  }

  pathwayErrorHandler(error: any) {
    this.isLoading = false;
  }

  selectEveryone(event) {
    event?.preventDefault();

    this.selectedHcps[this.currentPathway?.clinical_lead?.uid] = true;
    this.selectedHcps[this.currentPathway?.case_manager?.uid] = true;

    if (this.currentPathway.patient_mdt.mdts) {
      this.currentPathway.patient_mdt.mdts.forEach(mdt => {
        this.selectAllFromMdt(event, mdt);
      });
    }

    this.selectAllFromGroup();
  }

  selectAllFromMdt(event: MouseEvent, mdt: MedicalTeam) {
    event?.preventDefault();

    if (mdt.hcps) {
      mdt.hcps.forEach(hcp => {
        this.selectedHcps[hcp.uid] = true;
        this.fullHcpList.push(hcp);
      });
    }
  }

  selectAllFromGroup() {
    if (this.currentPathway.patient_mdt.otherHcps) {
      this.currentPathway.patient_mdt.otherHcps.forEach(hcp => {
        this.selectedHcps[hcp.uid] = true;
        this.fullHcpList.push(hcp);
      });
    }
  }

  togglePatientHcp(hcp: HealthCareProfessional) {
    if (hcp.uid !== this.currentHcp.uid) {
      if (this.selectedHcps[hcp.uid]) {
        this.selectedHcps[hcp.uid] = false;
      } else {
        this.selectedHcps[hcp.uid] = true;
        this.fullHcpList.push(hcp);
      }
    }
  }

  toggleMdtHcp(hcp: HealthCareProfessional) {
    if (this.selectedHcps[hcp.uid]) {
      this.selectedHcps[hcp.uid] = false;
    } else {
      this.selectedHcps[hcp.uid] = true;
      this.fullHcpList.push(hcp);
    }
  }

  searchHCPList(event?) {
    let term = '';
    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.hcpList = [];
    }

    this.isLoadingHcps = true;
    this.hcpService.getPaged({ last_name: term, status: 'ACTIVE' }, 'last_name,asc', 0, 50).subscribe({
      next: response => {
        this.hcpList = response.items.filter(hcp => hcp.uid !== this.currentHcp.uid);
      }, complete: () => this.isLoadingHcps = false
    });
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  onLosslessHcpSelect(hcp): void {
    if (hcp && !this.isHcpSelected(hcp)) {
      this.losslessHcpSelection.push(hcp);
      this.selectedHcps[hcp.uid] = true;
      this.fullHcpList.push(hcp);
    }

    setTimeout(() => {
      this.losslessHcpSelectModel = null;
    });
  }

  removeLosslessHcp(event, hcp: any) {
    event.preventDefault();

    const index = this.losslessHcpSelection.indexOf(hcp);

    if (index >= 0) {
      this.losslessHcpSelection.splice(index, 1);
      this.selectedHcps[hcp.uid] = false;
    }
  }

  isHcpSelected(hcp: HealthCareProfessional) {
    return this.selectedHcps[hcp.uid];
  }

  handleCancel(): void {
    this.bsModalRef.hide();
  }

  handleSave(): void {
    const removedHcps = [];
    const addedHcps = [];

    Object.keys(this.originalselectedHcps).forEach(key => {
      if (this.originalselectedHcps[key]) {
        if (!this.selectedHcps[key]) {
          removedHcps.push(key);
        }
      }
    });

    Object.keys(this.selectedHcps).forEach(key => {
      if (this.selectedHcps[key]) {
        if (!this.originalselectedHcps[key]) {
          addedHcps.push(key);
        }
      }
    });

    const actions = [];

    if (addedHcps && addedHcps.length) {
      actions.push(this.conversationService.addHcps(this.conversation.uid, addedHcps));
    }

    if (removedHcps && removedHcps.length) {
      actions.push(this.conversationService.removeHcps(this.conversation.uid, removedHcps));
    }

    if (actions && actions.length) {
      this.isSaving = true;
      forkJoin(actions).subscribe(() => {
        this.isSaving = false;
        this.bsModalRef.hide();
        this.onParticipantsChange.emit(this.selectedHcps);
        this.showSuccessToast();
        this.updateConversation(addedHcps, removedHcps);
      }, () => {
        this.isSaving = false;
        this.bsModalRef.hide();
        this.onParticipantsChange.emit(this.originalselectedHcps);
        this.showErrorToast();
      });
    } else {
      this.bsModalRef.hide();
    }
  }

  updateConversation(addedHcps, removedHcps) {
    addedHcps.forEach(uid => {
      this.conversation.hcp_uids.push(uid);
      const toAdd = this.fullHcpList.filter(hcp => hcp.uid === uid)[0];
      this.conversation.hcps.push(new Participant(toAdd));
    });

    removedHcps.forEach(uid => {
      const indexUid = this.conversation.hcp_uids.indexOf(uid);
      if (indexUid > -1) {
        this.conversation.hcp_uids.splice(indexUid, 1);
      }

      const toRemove = this.conversation.hcps.filter(hcp => hcp.uid === uid)[0];
      const indexHcp = this.conversation.hcps.indexOf(toRemove);
      if (indexHcp > -1) {
        this.conversation.hcps.splice(indexHcp, 1);
      }
    });
  }

  showSuccessToast() {
    this.toastService.success(this.translate.instant('modals.edit_conversation_participants.edit_success_notification'));
  }

  showErrorToast() {
    this.errorService.showGeneralBackendErrorToast();
  }
}
