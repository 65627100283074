import { HttpHeaders } from '@angular/common/http';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService {
  constructor() {
  }

  public static SLUG_MAIN_TRANSLATIONS = 'polyphonic_pro_web';
  public static SLUG_CROSS_APP_TRANSLATIONS = 'cross_app_shared_labels';
  public static SLUG_BUSINESS_ERROR_TRANSLATIONS = 'business_error_keys';
  public static SLUG_BUSINESS_FUNCTION_TRANSLATIONS = 'business_function_keys';
  public static SLUG_PATHWAY_QUESTIONNAIRE_TRANSLATIONS = 'pathway_questionnaire';

  public static GetAllTranslations(http, lang: string): Observable<any> {
    return new Observable(observer => {
      forkJoin([
        TranslationLoaderService.MainTranslationLoader(http, lang),
        TranslationLoaderService.SharedTranslationLoader(http, lang),
        TranslationLoaderService.PermissionsTranslationLoader(http, lang)

      ]).subscribe(responses => {
        const mainTranslations = responses[0];
        const sharedTranslations = responses[1];
        const permissionsTranslations = responses[2];

        let result: any = {};

        // add Main Translations
        result = Object.assign({}, mainTranslations);

        // create 'shared' property
        result.shared = {};

        // add shared translations to shared
        Object.keys(sharedTranslations).forEach(key => {
          result.shared[key] = sharedTranslations[key];
        });

        // add permission translations to shared
        Object.keys(permissionsTranslations).forEach(key => {
          result.shared[key] = permissionsTranslations[key];
        });

        observer.next(result);
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  public static MainTranslationLoader(http, lang: string) {
    return new Observable(observer => {

      TranslationLoaderService.GetAllEntries(http, lang, TranslationLoaderService.SLUG_MAIN_TRANSLATIONS).subscribe(entries => {
        observer.next(TranslationLoaderService.ParseCmsKeyValueList(entries));
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  public static PermissionsTranslationLoader(http, lang: string) {
    const permissions = JSON.parse(sessionStorage.getItem(UserService.StorageKeyPermissions));
    let result: any = {};

    if (!permissions || !permissions['regions']) {
      return new Observable(observer => {
        observer.next(result);
        observer.complete();
      });
    }

    const regions = permissions['regions'];

    const pageObservables: Array<any> = [];
    for (let i = 0; i < regions.length; i++) {
      pageObservables.push(TranslationLoaderService.GetAllEntries(http, lang, regions[i]));
    }

    return new Observable(observer => {
      if (!pageObservables || !pageObservables.length) {
        observer.next(result);
        observer.complete();
        return;
      }

      forkJoin(pageObservables).subscribe(results => {
        results.forEach((resultList: any, index) => {
          result[regions[index]] = this.ParseCmsKeyValueList(resultList);

          if (resultList && resultList.length) {
            let firstEntry = resultList[0];

            if (firstEntry['functions']) {
              Object.keys(firstEntry.functions).forEach(key => {
                result[regions[index]][key] = firstEntry.functions[key];
              });
            }

            if (firstEntry['caremodules']) {
              Object.keys(firstEntry.caremodules).forEach(key => {
                result[regions[index]][key] = firstEntry.caremodules[key];
              });
            }
          }
        });

        observer.next(result);
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  public static SharedTranslationLoader(http, lang: string) {
    return new Observable(observer => {
      const content_slug_shared_labels = TranslationLoaderService.SLUG_CROSS_APP_TRANSLATIONS;
      const content_slug_errors = TranslationLoaderService.SLUG_BUSINESS_ERROR_TRANSLATIONS;
      const content_slug_functions = TranslationLoaderService.SLUG_BUSINESS_FUNCTION_TRANSLATIONS;
      const content_slug_questionnaire = TranslationLoaderService.SLUG_PATHWAY_QUESTIONNAIRE_TRANSLATIONS;

      forkJoin([
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_shared_labels),
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_errors),
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_functions),
        TranslationLoaderService.GetAllEntries(http, lang, content_slug_questionnaire)
      ]).subscribe(responses => {
        const crossAppSharedLabels = responses[0];
        const businessErrorKeys = responses[1];
        const businessFunctionKeys = responses[2];
        const questionnaireKeys = responses[3];

        const result: any = {};

        if (crossAppSharedLabels && crossAppSharedLabels.length) {
          result.languages = crossAppSharedLabels[0].languages;
        }

        result[content_slug_errors] = {};
        if (businessErrorKeys && businessErrorKeys.length) {
          result[content_slug_errors] = TranslationLoaderService.ParseCmsKeyValueList(businessErrorKeys);
        }

        result[content_slug_functions] = {};
        if (businessFunctionKeys && businessFunctionKeys.length) {
          result[content_slug_functions] = TranslationLoaderService.ParseCmsKeyValueList(businessFunctionKeys);
        }

        result[content_slug_questionnaire] = {};
        if (questionnaireKeys && questionnaireKeys.length) {
          result[content_slug_questionnaire] = TranslationLoaderService.ParseCmsKeyValueList(questionnaireKeys);
        }

        observer.next(result);
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  public static GetAllEntries(http, lang: string, type: string, skip: number = 0, allEntries?: Array<any>, passedObserver?: any): Observable<Array<any>> {
    if (!allEntries) {
      allEntries = [];
    }

    const contentHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'api_key': environment.cmsApiKey,
      'access_token': environment.cmsAccessToken,
      [NgHttpCachingHeaders.ALLOW_CACHE]: '1'
    });

    return new Observable(observer => {
      const finalObserver = passedObserver || observer;

      const url = `${environment.cmsUrl}/v3/content_types/${type}/entries?environment=${environment.cmsEnvironment}&locale=${lang.toLowerCase()}&include_fallback=true&include_count=true&skip=${skip}`;

      http.get(url, { headers: contentHeader }).subscribe(response => {

        allEntries = allEntries.concat(response['entries']);

        const count = response['count'];
        const length = response['entries']?.length;


        if (count > length + skip) {
          TranslationLoaderService.GetAllEntries(http, lang, type, skip + 100, allEntries, finalObserver).subscribe(() => {
          });
        } else {
          finalObserver.next(allEntries);
          finalObserver.complete();
        }

      }, error => {

        if (environment.trans_loader_skip_cs_errors) {
          finalObserver.next(allEntries);
        } else {
          finalObserver.error(error);
        }

        finalObserver.complete();
      });
    });
  }

  public static ParseCmsKeyValueList(entries: Array<any>): any {
    const parsed: any = {};

    entries.forEach(entry => {
      const slug = entry['title'];
      const value = entry['value'];

      if (slug && value) {
        parsed[slug] = value;
      }
    });

    return parsed;
  }
}
