import { Component, OnInit, OnChanges, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, HostListener, Input, Inject, Output, EventEmitter, OnDestroy, QueryList } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageTabItem } from './../../models/page-tab-item';
import { GeneralService } from '../../services/general.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page-tabs',
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss']
})
export class PageTabsComponent implements OnInit, OnDestroy, OnChanges {
  public activeItem: PageTabItem;
  public usedItemsCount: number = 0;

  private _onViewRedrawRequestSubscription: Subscription;
  private _onPageTabScrollRequestSubscription: Subscription;
  private _computeInterval: any;

  @Input()
  set items(items: PageTabItem[]) {
    if(this._items!==items) {
      this._items = items;
    }

    if(this._items) {
      if(!this.activeItem || !this.items.includes(this.activeItem)) {
        this.activeItem = this._items[0];
      }
    }

    // this.computeItems();
    setTimeout(() => {
      this.computeItems();
    });
  }
  get items(): PageTabItem[] {
    return this._items;
  }
  private _items: PageTabItem[];

  get moreItems(): PageTabItem[] {
    if(!this._items) {
      return [];
    } else {
      return this._items.filter((item, index) => index >= (this.usedItemsCount));
    }
  }

  @Input() asClassicTabs: boolean;

  @Output() onTabChange: EventEmitter<PageTabItem> = new EventEmitter();

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('moreBtn') moreBtn: ElementRef;
  @ViewChildren('viewItems') viewItems: QueryList<ElementRef>;

  constructor(
    @Inject(DOCUMENT) public document: any,
    public translateService: TranslateService,
    public changeDetectorRef: ChangeDetectorRef,
    public generalService: GeneralService
  ) {}

  ngOnInit() {
    // this._onViewRedrawRequestSubscription = this.generalService.onViewRedrawRequest.subscribe(() => {
    //   this.computeItems();
    // });

    this._onPageTabScrollRequestSubscription = this.generalService.onPageTabScrollRequest.subscribe(request => {
      let _pageTabItem = null;
      let _animated = true;

      if(request.hasOwnProperty('pageTabItem')) {
        _pageTabItem = request.pageTabItem;

        if(request.hasOwnProperty('animated')) {
          _animated = request.animated;
        }
      }

      if(_pageTabItem) {
        this.clickHandler(null, _pageTabItem, _animated);
      }
    });

    // this.computeItems();
    // this._computeInterval = setInterval(() => {
    //   this.computeItems();
    // }, 250);
  }

  ngOnChanges() {
    this.computeItems();
  }

  ngOnDestroy() {
    if(this._onViewRedrawRequestSubscription) {
      this._onViewRedrawRequestSubscription.unsubscribe();
    }

    if(this._onPageTabScrollRequestSubscription) {
      this._onPageTabScrollRequestSubscription.unsubscribe();
    }

    // clearInterval(this._computeInterval);
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();

    this.translateService.onLangChange.subscribe(() => {
      setTimeout(() => {
        this.computeItems();
      });
    });

    setTimeout(() => {
      this.computeItems();
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.computeItems();
  }

  @HostListener('window:scroll')
  onScroll() {
    if(!this.asClassicTabs) {
      this.updateActiveTab();
    }
  }

  computeItems() {
    if(!this.viewItems) {
      return;
    }

    this.showMoreBtn(false);

    var availableWidth = this.getAvailableWidth();

    var usedWidth = 0;
    // var usedEls: Array<any> = new Array();
    var _usedItems = 0;

    this.viewItems.forEach((viewItem, index) => {
      var viewItemEl = viewItem?.nativeElement;
      var elClass = viewItemEl?.classList;

      elClass.remove('hidden-item');
      elClass.add('invisible-item');
    });

    this.viewItems.forEach((viewItem, index) => {
      var viewItemEl = viewItem?.nativeElement;
      var elClass = viewItemEl?.classList;
      var elWidth = viewItemEl?.offsetWidth;
      // var elBoundingWidth = viewItemEl?.getBoundingClientRect().width;

      if((usedWidth + elWidth) < availableWidth) {
        usedWidth += elWidth;
        _usedItems += 1;

        // usedEls.push(viewItemEl);
        elClass.remove('hidden-item');
      } else {
        elClass.add('hidden-item');
      }

      elClass.remove('invisible-item');
    });

    this.showMoreBtn(this.viewItems?.length > _usedItems);
    this.usedItemsCount = _usedItems;

  }

  getMoreBtnWidth(): number {
    return this.moreBtn?.nativeElement?.offsetWidth || 0;
  }

  showMoreBtn(visible: boolean) {
    var el = this.moreBtn?.nativeElement;
    var elClass = el?.classList;

    if(visible) {
      elClass.add('visible');
    } else {
      elClass.remove('visible');
    }
  }

  activateMoreBtn(activate: boolean) {
    var el = this.moreBtn?.nativeElement;
    var buttonEl = el?.querySelector('button');
    var buttonElClass = buttonEl?.classList;

    if(activate) {
      buttonElClass.add('active');
    } else {
      buttonElClass.remove('active');
    }
  }

  getAvailableWidth(): number {
    var wrapperWidth: number = this.wrapper?.nativeElement?.offsetWidth || 0;

    var moreBtnWidth: number = this.getMoreBtnWidth();
    var marge = 16*3;
    return wrapperWidth - marge - moreBtnWidth;
  }

  updateActiveTab() {
    const bodyContent:any = document.getElementsByClassName('body-content')[0];
    const scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    const scrollEnd = document.body.offsetHeight - window.innerHeight;
    const margin: number = 80;
    let tab;

    this._items.forEach((item, index) => {
      const zone:any = document.getElementById(item.htmlID);

      if(zone && bodyContent) {
        if (zone.offsetTop - scroll < bodyContent.offsetTop + margin) {
          tab = item;
        }
      }
    });

    if(scroll === scrollEnd) {
      tab = this._items[this._items.length-1];
    }

    if(tab) {
      this.setActiveTab(tab);

    } else {
      this.setActiveTab(this._items[0]);
    }
  }

  setActiveTab(tab: PageTabItem) {
    if(this.activeItem!==tab) {
      this.activeItem = tab;

      let activeTabIndex: number = this._items.indexOf(this.activeItem);
      let activeTabNum: number = activeTabIndex+1;
      let isInMoreBtn: boolean = (activeTabNum > this.usedItemsCount) && (this.usedItemsCount > 0);

      this.activateMoreBtn(isInMoreBtn);


      this.onTabChange.emit(this.activeItem);
    }
  }

  clickHandler(event, item: PageTabItem, animated: boolean = true) {
    if (event) {
        event.preventDefault();
    }

    if (this.asClassicTabs) {
        this.setActiveTab(item);
    }

    const bodyContent: HTMLElement | null = document.getElementsByClassName('body-content')[0] as HTMLElement;
    const bodyContentStyle = getComputedStyle(bodyContent);
    const margin = parseInt(bodyContentStyle.paddingTop) || 40;

    const targetElement = document.getElementById(item.htmlID);
    if (targetElement) {
        // Get the top position of the target element relative to the document
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

        // Calculate the final scroll position, adjusting for any offsets
        const scrollPosition = targetPosition - (bodyContent.offsetTop + margin);

        if (animated) {
            // Smooth scroll to the calculated position
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        } else {
            // Instant scroll to the calculated position
            window.scrollTo({
                top: scrollPosition,
                behavior: 'auto'
            });
        }
    }
  }
}
