<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-phone-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <div *ngIf="(step == 'phone')">
      <div class="row">
        <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
          <div class="form-group required">
            <app-select
              [placeholder]="('form.labels.country_code' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dialCodeOptions"
              [bindValue]="'value'"
              formControlName="country_code"
              [asFilter]="false"
              [showCheckboxes]="false"
            />
            <app-feedback-field [field]="form.get('country_code')"></app-feedback-field>
          </div>
        </div>
        <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
          <div class="form-group">
            <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
            <input type="tel" id="inputPhone" class="form-control w-100" [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
            <app-feedback-field [field]="form.get('phone')"></app-feedback-field>
          </div>
        </div>
      </div>

      <!--
      <div class="form-group">
        <div class="custom-control custom-checkbox custom-control-inline mb-1">
          <input type="checkbox" class="custom-control-input" id="checkboxNotificationsPhone" formControlName="notifications_method_sms">
          <label class="custom-control-label" for="checkboxNotificationsPhone">{{ 'modals.edit_contact_details.send_notification_number' | translate }}</label>
        </div>
      </div>
      !-->
    </div>

    <div *ngIf="(step === 'code')">
      <p class="mb-1">{{ 'modals.edit_contact_details.enter_code' | translate }}</p>
      <p class="text-muted">{{ 'modals.edit_contact_details.not_mobile_number' | translate }}</p>

      <div class="row align-items-end">
        <div class="col">
          <div class="form-group">
            <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>
            <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="code" required>
          </div>
        </div>
        <div class="col-auto">
          <a class="btn btn-link btn-lg" href="" (click)="resendCode($event)">{{ 'modals.edit_contact_details.resend'| translate }}</a>
        </div>
      </div>
    </div>

    <div *ngIf="(step === 'forget')">
      <h6 class="mb-1">{{ 'modals.edit_contact_details.forget_title' | translate }}</h6>
      <p>{{ 'modals.edit_contact_details.forget_description'| translate }}</p>
      <a href="/settings/security" class="btn btn-secondary btn-sm" id="edit-phone-modal-forget-link">{{ 'modals.edit_contact_details.forget_link'| translate }}</a>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-phone-modal-cancel" (click)="handleCancel()" *ngIf="(step !== 'forget')">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" id="edit-phone-modal-submit" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'phone')" (click)="handleSubmit()">
      <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span *ngIf="verificationCodeRequired()">{{ 'action.next_step' | translate }}</span>
      <span *ngIf="!verificationCodeRequired()">{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-primary m-start-2" id="edit-phone-modal-confirm" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'code')" (click)="handleSubmit()">
      <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-primary" id="edit-phone-modal-cancel"  *ngIf="(step === 'forget')" (click)="handleCancel()">
      <span>{{ 'action.close' | translate }}</span>
    </button>
  </div>
</div>
