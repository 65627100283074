<div class="app-wrapper" [ngClass]="{
  'browser-ie-edge': isIeOrEdge,
  'browser-not-ie-edge': !isIeOrEdge
}">
  <router-outlet (activate)="onActivate()"></router-outlet>
</div>

<!-- -->
<!-- Notice - Screen Size - Start -->
<!-- -->
<div class="notice notice-overlay hide-desktop" id="notice_screensize">
  <div class="logo text-jnj mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>

  <div class="position-relative">
    <h3 class="">{{ 'app_component.oops' | translate }}</h3>
    <h6>{{ 'app_component.screen_size_no_support' | translate }}</h6>
    <p>{{ 'app_component.screen_size_no_support_text' | translate }}</p>

    <p class="mt-4">{{ 'app_component.use_c4t_mobile' | translate }}</p>

    <div class="d-flex" *ngIf="showAppStoreIcons">
        <a *ngIf="appStore" href="{{appStore?.url}}" class="btn pl-0">
          <span class="icon store" [inlineSVG]="appStore?.icon"></span>
        </a>

        <a *ngIf="playStore" href="{{playStore?.url}}" class="btn pl-0">
          <span class="icon store" [inlineSVG]="playStore?.icon"></span>
        </a>
    </div>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights [languageSelect]="true" [cookiesButton]="false"></app-flow-copyrights>
  </div>
</div>
<!-- -->
<!-- Notice - Screen Size - End -->
<!-- -->


<!-- -->
<!-- Notice - Only Web Portal Enabled - Start -->
<!-- -->
<div class="notice notice-overlay hide-desktop" *ngIf="onlyWebPortalEnabled" id="notice_only_web">
  <div class="logo mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>

  <div class="position-relative">
    <h3>{{ 'app_component.oops' | translate }}</h3>
    <h6>{{ 'app_component.device_no_support' | translate }}</h6>
    <p>{{ 'app_component.device_no_support_text1' | translate }}</p>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights [languageSelect]="true" [cookiesButton]="false"></app-flow-copyrights>
  </div>
</div>
<!-- -->
<!-- Notice - Only Web Portal Enabled - End -->
<!-- -->


<!-- -->
<!-- Notice - Only Mobile App Enabled - Start -->
<!-- -->
<div class="notice notice-overlay" *ngIf="onlyMobileAppEnabled" id="notice_only_mobile">
  <div class="logo mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>

  <div class="position-relative">
    <h3>{{ 'app_component.oops' | translate }}</h3>
    <h6>{{ 'app_component.device_no_support_text2' | translate }}</h6>

    <p>
      <a href="{{ environmentVars?.appStore }}" class="text-icon-link hoverable text-secondary">
        <span class="label">{{ 'app_component.c4t_app_store' | translate }}</span>
        <span class="icon-end">
          <span class="rtl-d-none" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
          <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/arrow-left-24.svg'"></span>
        </span>
      </a>
    </p>

    <p>
      <a href="{{ environmentVars?.playStore }}" class="text-icon-link hoverable text-secondary">
        <span class="label">{{ 'app_component.c4t_google_play' | translate }}</span>
        <span class="icon-end">
          <span class="rtl-d-none" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
          <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/arrow-left-24.svg'"></span>
        </span>
      </a>
    </p>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights [languageSelect]="true" [cookiesButton]="false"></app-flow-copyrights>
  </div>
</div>
<!-- -->
<!-- Notice - Only Mobile App Enabled - End -->
<!-- -->
