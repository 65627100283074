import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { HcpService } from '../../services/hcp.service';
import { BulkApprovalService } from '../../services/bulk-approval.service';
import { ErrorService } from '../../services/error.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HospitalService } from '../../services/hospital.service';
import { MedicalTeam } from '../../models/medical-team';
import { CareModule } from '../../models/care-module';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { MdtService } from '../../services/mdt.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BulkAssignmentPost } from '../../interfaces/bulk-assignment-post.interface';
import { BulkApprovalState } from '../../enums/bulk-approval-state';

@Component({
  selector: 'app-bulk-approval-modal',
  templateUrl: './bulk-approval-modal.component.html'
})
export class BulkApprovalModalComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Output() public requestUpdated = new EventEmitter();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public CLList: Array<HealthCareProfessional> = [];
  public CMList: Array<HealthCareProfessional> = [];
  public patientUids: Array<string>;
  public careModuleUid: string;
  public careModule: CareModule;

  public mdtOrHcpSelect: MedicalTeam | HealthCareProfessional;
  public allMdtsOrHcps: Array<MedicalTeam | HealthCareProfessional> = [];

  public searchCMListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchCLListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchMdtOtherHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public hcpService: HcpService,
    public bulkApprovalService: BulkApprovalService,
    public errorService: ErrorService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public mdtService: MdtService
  ) { }

  ngOnInit(): void {
    this.formSetup();
    this.getAllMdtsAndHcps('');
    this.getCareModule(this.careModuleUid);

    this.searchCLListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchCLList(result?.term);
    });

    this.searchCMListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchCMList(result?.term);
    });

    this.searchMdtOtherHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getAllMdtsAndHcps(result.term);
    });
  }

  getCareModule(uid: string) {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModule(hospitalUid, uid).subscribe((careModule: CareModule) => {
      this.careModule = careModule;

      if (careModule.default_case_manager) {
        this.form.get('case_manager').setValue(careModule.default_case_manager);

        if (!this.CMList.includes(careModule.default_case_manager)) {
          this.CMList.push(careModule.default_case_manager);
        }
      } else {
        this.form.get('case_manager').setValue('');
      }

      if (careModule.default_clinical_lead) {
        this.form.get('clinical_lead').setValue(careModule.default_clinical_lead);

        if (!this.CLList.includes(careModule.default_clinical_lead)) {
          this.CLList.push(careModule.default_clinical_lead);
        }
      } else {
        this.form.get('clinical_lead').setValue('');
      }
    })
  }

  getAllMdtsAndHcps(term: string): void {
    forkJoin([
      this.getMdts(term),
      this.getHcps(term)
    ]).pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(results => {
      this.allMdtsOrHcps = [];
      this.allMdtsOrHcps = [...this.allMdtsOrHcps, ...results[0], ...results[1]];

      if(term === '') {
        this.CLList = results[1];
        this.CMList = results[1];
      }
    });
  }

  getMdts(searchTerm: string): Observable<MedicalTeam[]> {
    return new Observable(observer => {
      this.mdtService.getPaged({ name: searchTerm }, 'name,asc', 0, 50).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  getHcps(searchTerm?: string): Observable<HealthCareProfessional[]> {
    return new Observable(observer => {
      this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc').subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  searchCMList(searchTerm?): void {
    if (!searchTerm) {
      this.CMList = [];
    }

    this.getHcps(searchTerm).subscribe(response => {
      this.CMList = response;
    });
  }

  searchCLList(searchTerm?): void {
    if (!searchTerm) {
      this.CLList = [];
    }

    this.getHcps(searchTerm).subscribe(response => {
      this.CLList = response;
    });
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      clinical_lead: ['', [Validators.required]],
      case_manager: ['', [Validators.required]]
    });
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    this.bulkApprovalService.updateBulkApprovals(this.hcpService.getCurrentStoredHospitalUid(), this.bulkAssignmentParams()).subscribe(() => {
      this.successHandler();
    }, (error) => this.errorHandler(error));
  }

  successHandler() {
    this.requestUpdated.emit();
    this.isSaving = false;
    this.toastrService.success(this.translate.instant('pages.default.administration.onboarding_requests.approve_success_notif'));
    this.bsModalRef.hide();
  }

  errorHandler(error: any) {
    this.isSaving = false;
    this.bsModalRef.hide();
  }

  bulkAssignmentParams(): BulkAssignmentPost {
    const params: BulkAssignmentPost = {
      care_module_uid: this.careModule.uid,
      patient_uids: this.patientUids,
      approval: BulkApprovalState.APPROVED,
      clinical_lead_uid: this.form.get('clinical_lead').value.uid,
      case_manager_uid: this.form.get('case_manager').value.uid,
      mdt_uids: this.careModule.default_mdts.map(mdt => mdt.uid),
      hcp_uids: this.careModule.default_hcps.map(hcp => hcp.uid),
    };

    return params;
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  handleClose() {
    return this.bsModalRef.hide();
  }

  onAddMdtOrHcp(): void {
    if (!this.mdtOrHcpSelect) {
      return;
    }

    if (this.mdtOrHcpSelect instanceof MedicalTeam && !this.isMdtSelected(this.mdtOrHcpSelect)) {
      if (!this.careModule?.default_mdts) {
        this.careModule.default_mdts = [];
      }

      this.careModule.default_mdts.push(this.mdtOrHcpSelect);
    }

    if (this.mdtOrHcpSelect instanceof HealthCareProfessional && !this.isHcpSelected(this.mdtOrHcpSelect)) {
      if (!this.careModule.default_hcps) {
        this.careModule.default_hcps = [];
      }

      this.careModule.default_hcps.push(this.mdtOrHcpSelect);
    }

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    this.getAllMdtsAndHcps('');
  }

  isMdtSelected(mdt: MedicalTeam): boolean {
    const filtered = this.careModule?.default_mdts?.filter(item => item.uid === mdt.uid);
    return filtered?.length > 0;
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    const filtered = this.careModule?.default_hcps?.filter(item => item.uid === hcp.uid);
    return filtered?.length > 0;
  }

  onRemoveMdt(event, mdt: MedicalTeam): void {
    event.preventDefault();

    const index = this.careModule.default_mdts.indexOf(mdt);
    if (index >= 0) {
      this.careModule.default_mdts.splice(index, 1);
    }
  }

  onRemoveHcp(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const index = this.careModule.default_hcps.indexOf(hcp);

    if (index >= 0) {
      this.careModule.default_hcps.splice(index, 1);
    }
  }
}
