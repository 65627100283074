<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <h5>{{ 'pages.default.administration.care_modules.care_modules' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      <div class="list-group">
        <div class="list-group-item list-group-header p-end-5">
          <div class="row align-items-center w-100">
            <div class="col-4">
              <p>
                {{ 'pages.default.administration.care_modules.name' | translate }}
              </p>
            </div>

            <div class="col-4">
              <p>
                {{ 'pages.default.administration.care_modules.description' | translate }}
              </p>
            </div>

            <div class="col-2">
              <p>
                {{ 'pages.default.administration.care_modules.linked_mdts' | translate }}
              </p>
            </div>

            <div class="col-2">
              <p>
                {{ 'pages.default.administration.care_modules.care_module_type' | translate }}
              </p>
            </div>

          </div>
        </div>

        @for (careModule of careModules; track careModule.uid) {
          <a href="" [routerLink]="['/administration/care-modules/' + careModule?.uid]"
              id="care-module-detail-{{careModule.uid}}" class="list-group-item clickable">
            <div class="row w-100 align-items-center">
              <div class="col-4">
                <p class="fw-semibold">
                  {{ careModule.translationKey | translate }}
                </p>
              </div>

              <div class="col-4">
                <p>
                  {{ careModule.descriptionTranslationKey | translate }}
                </p>
              </div>

              <div class="col-2">
                @if (careModule.default_mdts?.length) {
                  <p class="font-weight-medium">
                    {{ careModule.default_mdts?.length }} {{ 'pages.default.administration.care_modules.linked' |
                    translate }}
                  </p>
                } @else {
                  <p class="text-muted small">
                    {{ 'pages.default.administration.care_modules.none_linked' | translate }}
                  </p>
                }
              </div>

              <div class="col-2">
                @if (careModule?.isClinical) {
                  <p class="status-badge clinical d-inline-block">
                    {{ 'pages.default.administration.care_module_detail.clinical' | translate }}
                  </p>
                } @else if (careModule?.isEducation) {
                  <p class="status-badge educational d-inline-block">
                    {{ 'pages.default.administration.care_module_detail.education' | translate }}
                  </p>
                }
              </div>
            </div>

            <div class="btn btn-sm btn-tertiary btn-icon">
              <span [inlineSVG]="'/assets/svg/arrow-right-24.svg'" class="rtl-mirrored-inline-svg"></span>
            </div>
          </a>
        } @empty {
          <div class="empty-state my-5 my-lg-7 my-xl-9">
            <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
            <h6 class="h7">{{ 'pages.default.administration.care_modules.no_care_modules' | translate }}</h6>
          </div>
        }
      </div>
    }
  </div>

</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1">
          <app-pagination [totalPages]="totalPages" [activePage]="currentPage"
                          (pageChange)="onPaginationPageChanged($event)"></app-pagination>
        </div>
        <!-- pagination range -->
        <div class="m-start-auto">
          <app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)"
                                [rangeOptions]="[5,10, 20]"></app-pagination-range>
        </div>
      </div>
    </div>
  </div>
</ng-container>
