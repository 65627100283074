<div class="row" *ngIf="isLoading">
  <app-page-loader padding="50px"></app-page-loader>
</div>

<div class="row" *ngIf="!isLoading && kpis?.length">
  <div class="col-3" *ngFor="let kpi of kpis">
    <div class="card kpi-card">
      <div class="card-body">
        <div class="kpi-content">
          <h6 class="kpi-title">{{ kpi.title }}</h6>
          <p class="kpi-description">{{ kpi.description }}</p>
        </div>

        <div class="kpi-value">
          <p class="h6 kpi-value--current">{{ kpi.value }}{{ kpi.unit }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
