import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class AfterFieldsValidator {
  static validate(startTimeLabel: string, endTimeLabel: string, errorKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      const startControl: AbstractControl = group.get(startTimeLabel);
      const endControl: AbstractControl = group.get(endTimeLabel);

      if (!startControl || !endControl) {
        return null; // Return null if the controls don't exist
      }

      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);

      if (endTime <= startTime) {
        const error = { [errorKey]: true };

        // Set the error on the endTime control
        endControl.setErrors(error);
        return null; // Return null to avoid adding the error at the form group level
      } else {
        // Clear the error if validation passes
        if (endControl.hasError(errorKey)) {
          endControl.setErrors(null);
        }
        return null;
      }
    };
  }
}
