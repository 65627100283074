import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AttentionCenterService } from '../services/attention-center.service';
import { AuthenticationService } from '../services/authentication.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected toastrService: ToastrService,
    protected translate: TranslateService,
    protected apiService: ApiService,
    protected attentionCenterService: AttentionCenterService,
    protected loginService: LoginService
  ) { }

  public refreshTokenInProgress = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (request.body && request.body.grant_type === 'refresh_token' && error.status === 401) {
            this.logout();
            return throwError(error);
          }

          // Ignore requests from the attentioncenter
          if (request.url.indexOf('/user/attention-center') > -1 && error.status === 401) {
            this.authenticationService.accessTokenValid = false;
            return throwError(error);
          }

          // Catch token expired
          if (error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
        })
      );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // The first failing request comes here...
    if (!this.refreshTokenInProgress) {

      this.refreshTokenInProgress = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);


      return this.loginService.refreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            const accessToken = newToken['access_token'];

            // Trigger other failed requests
            this.tokenSubject.next(accessToken);

            this.authenticationService.accessTokenValid = true;

            this.refreshTokenInProgress = false;

            return next.handle(this.addToken(req, accessToken));
          }
        }),
        catchError(() => {
          return this.logout();
        }),
        finalize(() => {
            this.refreshTokenInProgress = false;
        }));
    //  The next failing requests comes here and are waiting until the tokenSubject is resolved.
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null), // Only react when the token is filled in.
        take(1), // Make sure you only react once.
        switchMap(token => {
            return next.handle(this.addToken(req, token));
        }));
    }
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
  }

  logout() {
    this.refreshTokenInProgress = false;
    this.toastrService.info(this.translate.instant('app_component.auto_logout'));

    this.authenticationService.logout();

    return throwError('');
  }
}
