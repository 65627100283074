import { TranslationInterface } from "../interfaces/translation.interface";
import { FormField } from "./form-field";
import { HealthCareProfessional } from "./health-care-professional";
import { Patient } from "./patient";
import { QueryListSection } from "./query-list-section";

export enum QueryListStatus {
  NEW = 'NEW',
  SAVED = 'SAVED',
  COMPLETED = 'COMPLETED',
  TIMED_OUT = 'TIMED_OUT'
}

export class QueryList {
  public uid;
  public title: TranslationInterface;
  public description: TranslationInterface;
  public form_fields: Array<FormField> = [];
  public sections: Array<QueryListSection> = [];
  public candidate_users: Array<HealthCareProfessional> = [];
  public accessible_by_patient: boolean;

  public assignee: HealthCareProfessional | Patient;
  public assignee_type: string;
  public status: string;
  public type: string;
  public timedout_at: string;
  public changed_at: string;
  public completed_at: string;
  public saved_at: string;
  public created_at: string;
  public due_days_ago: number;
  public accessible_by_patient_mdt: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.id;
    this.type = item.type;
    this.status = item.status;
    this.title = item.title;
    this.description = item.description;
    this.timedout_at = item.timedout_at;
    this.changed_at = item.changed_at;
    this.completed_at = item.completed_at;
    this.saved_at = item.saved_at;
    this.created_at = item.created_at;
    this.accessible_by_patient_mdt = item.accessible_by_patient_mdt;
    this.accessible_by_patient = item.accessible_by_patient;
    this.assignee_type = item.assignee?.type;

    if (item.assignee?.type === 'PATIENT') {
      this.assignee = new Patient(item.assignee);
    }

    if (item.assignee?.type === 'HCP') {
      this.assignee = new HealthCareProfessional(item.assignee);
    }

    if (item.uid) {
      this.uid = item.uid;
    }

    var diff = new Date().valueOf() - new Date(this.timedout_at).valueOf();
    this.due_days_ago = Math.ceil(diff / (1000 * 3600 * 24));

    if (item.questions) {
      item.questions.forEach(formField => {
        this.form_fields.push(new FormField(formField));
      });
    }

    if (item.sections) {
      item.sections.forEach(section => {
        this.sections.push(new QueryListSection(section));
      });
    }

    if (item.candidate_users) {
      item.candidate_users.forEach(user => {
        this.candidate_users.push(new HealthCareProfessional(user));
      })
    }
  }

  sortedCandidateUsers(hcpUid) {
    return this.candidate_users.sort((a, b) => a.uid == hcpUid ? -1 : 1);
  }

  get isCompleted(): boolean {
    return this.status === QueryListStatus.COMPLETED;
  }

  get isTimedOut(): boolean {
    return this.status === QueryListStatus.TIMED_OUT;
  }

  get isNew(): boolean {
    return this.status === QueryListStatus.NEW;
  }

  get isSaved(): boolean {
    return this.status === QueryListStatus.SAVED;
  }

  isAssignedTo(hcpUid: string): boolean {
    const inCandidateList = this.candidate_users?.filter((item) => item.uid === hcpUid);
    return (hcpUid === this.assignee?.uid || inCandidateList?.length > 0);
  }

  isReadOnly(hcpUid: string):boolean {
    return this?.isCompleted || this?.isTimedOut || !this?.isAssignedTo(hcpUid);
  }

  get hasDff(): boolean {
    for (const form_field of this.form_fields) {
      if (form_field?.evaluation_required) {
        return true;
      }
    }

    for (const section of this.sections) {
      if (section.form_fields.length) {
        for (const form_field of section.form_fields) {
          if (form_field?.evaluation_required) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
