import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CareModule } from '../../models/care-module';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { MedicalTeam } from '../../models/medical-team';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { HospitalService } from '../../services/hospital.service';
import { MdtService } from '../../services/mdt.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-edit-hospital-team-modal',
  templateUrl: './edit-hospital-team-modal.component.html'
})
export class EditHospitalTeamModalComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() careModule: CareModule;
  @Output() hospitalTeamUpdatedEvent = new EventEmitter<void>();

  public hospitalUid: string;
  public isLoading = false;
  public isSaving = false;
  public isLoadingMdts= false;
  public form: UntypedFormGroup;
  public validationVisible = false;
  public defaultMdtsError: string;

  public hcps: HealthCareProfessional[] = [];
  public mdts: MedicalTeam[] = [];
  public allMdtsOrHcps: Array<MedicalTeam | HealthCareProfessional> = [];
  public mdtOrHcpSelect: MedicalTeam | HealthCareProfessional;
  public maxNumberOfDefaultMdts = 10;

  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchMdtOtherHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public bsModalRef: BsModalRef,
    public mdtService: MdtService,
    public modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.getCareModule();
    this.getAllMdtsAndHcps('');

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });

    this.searchMdtOtherHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getAllMdtsAndHcps(result.term);
    });
  }

  getCareModule(): void {
    this.isLoading = true;

    this.hospitalService.getCareModule(this.hospitalUid, this.careModule?.uid).subscribe((careModule: CareModule) => {
      this.careModule = careModule;
      this.formSetup();
      this.evaluateMaxAmountDefaultMdts();
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  getAllMdtsAndHcps(term: string): void {
    this.isLoadingMdts = true;

    forkJoin([
      this.getMdts(term),
      this.getHcps(term)
    ]).pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe({
      next: results => {
        this.allMdtsOrHcps = [ ...results[0], ...results[1]];

        this.isLoadingMdts = false;
      },
      error: () => this.isLoadingMdts = false
    });
  }

  getMdts(searchTerm: string): Observable<MedicalTeam[]> {
    return new Observable(observer => {
      this.mdtService.getPaged({ name: searchTerm }, 'name,asc', 0, 50).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  getHcps(searchTerm?: string): Observable<HealthCareProfessional[]> {
    return new Observable(observer => {
      this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc').subscribe(result => {
        this.hcps = result.items;
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  searchHcpList(searchTerm?): void {
    if (!searchTerm) {
      this.hcps = [];
    }

    this.getHcps(searchTerm).subscribe(response => {
      this.hcps = response;
    });
  }

  evaluateMaxAmountDefaultMdts(): void {
    if (this.careModule.default_mdts?.length >= this.maxNumberOfDefaultMdts) {
      this.defaultMdtsError = 'modals.edit_hospital_team_modal.max_mdt_limit';
    } else {
      this.defaultMdtsError = undefined;
    }
  }

  onAddMdtOrHcp(): void {
    if (!this.mdtOrHcpSelect) {
      return;
    }

    if (this.mdtOrHcpSelect instanceof MedicalTeam && !this.isMdtSelected(this.mdtOrHcpSelect)) {
      if (!this.careModule?.default_mdts) {
        this.careModule.default_mdts = [];
      }

      if (this.careModule.default_mdts.length < this.maxNumberOfDefaultMdts) {
        this.careModule.default_mdts.push(this.mdtOrHcpSelect);
        this.evaluateMaxAmountDefaultMdts();
      }
    }

    if (this.mdtOrHcpSelect instanceof HealthCareProfessional && !this.isHcpSelected(this.mdtOrHcpSelect)) {
      if (!this.careModule.default_hcps) {
        this.careModule.default_hcps = [];
      }

      this.careModule.default_hcps.push(this.mdtOrHcpSelect);
    }

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    this.getAllMdtsAndHcps('');
  }

  onRemoveHcp(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const index = this.careModule.default_hcps.indexOf(hcp);

    if (index >= 0) {
      this.careModule.default_hcps.splice(index, 1);
    }
  }

  onRemoveMdt(event, mdt: MedicalTeam): void {
    event.preventDefault();

    const index = this.careModule.default_mdts.indexOf(mdt);
    if (index >= 0) {
      this.careModule.default_mdts.splice(index, 1);
    }

    this.evaluateMaxAmountDefaultMdts();
  }

  isMdtSelected(mdt: MedicalTeam): boolean {
    const filtered = this.careModule?.default_mdts?.filter(item => item.uid === mdt.uid);
    return filtered?.length > 0;
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    const filtered = this.careModule?.default_hcps?.filter(item => item.uid === hcp.uid);
    return filtered?.length > 0;
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = new UntypedFormGroup({
      default_case_manager: new UntypedFormControl(this.careModule.default_case_manager),
      default_clinical_lead: new UntypedFormControl(this.careModule.default_clinical_lead),
    });
  }

  handleConfirm(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.showConfirmModal();
  }

  showConfirmModal(): void {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.confirm_hospital_team_save.title',
          description: 'modals.confirm_hospital_team_save.description',
          yes: 'modals.confirm_hospital_team_save.confirm',
          no: 'modals.confirm_hospital_team_save.cancel'
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.content.showYesLoading = true;

      this.handleSubmission().subscribe(() => {
        modalRef.hide();
        this.hospitalTeamUpdatedEvent.emit();
        this.onHandleClose();
      });
    });

    modalRef.content.onClose.subscribe(() => this.isSaving = false);
  }

  handleSubmission(): Observable<any> {
    this.defaultMdtsError = undefined;

    const params = this.mapParams();

    return new Observable(observer => {
      this.hospitalService.changeCareModuleDetails(this.hospitalUid, this.careModule.uid, params).subscribe(() => {
        setTimeout(() => {
          observer.next();
          observer.complete();
        }, 1000);
      }, error => {
        observer.error();
        observer.complete();

        this.isSaving = false;
        this.defaultMdtsError = `shared.business_error_keys.${error.error.errors?.find(error => error.field === 'default_mdts')?.key}`;
      });
    });
  }

  mapParams() {
    const params: {
      default_case_manager_uid?: string,
      default_clinical_lead_uid?: string,
      default_mdts?: string[],
      default_hcps?: string[]
    } = {};

    if (this.form?.get('default_case_manager')?.value) {
      params.default_case_manager_uid = this.form.get('default_case_manager')?.value.uid;
    }

    if (this.form?.get('default_clinical_lead')?.value) {
      params.default_clinical_lead_uid = this.form.get('default_clinical_lead')?.value.uid;
    }

    if (this.careModule?.default_mdts?.length) {
      params.default_mdts = this.careModule.default_mdts.map(mdt => mdt.uid);
    }

    if (this.careModule?.default_hcps?.length) {
      params.default_hcps = this.careModule.default_hcps.map(hcp => hcp.uid);
    }

    return params;
  }

  customSearchFn() {
    return true; // always return, searching is done at the backend
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }
}
