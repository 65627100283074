import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-restrict-processing-data-modal',
  templateUrl: './restrict-processing-data-modal.component.html',
  styleUrls: ['./restrict-processing-data-modal.component.scss']
})
export class RestrictProcessingDataModalComponent implements OnInit {
  public isVerificationStep: boolean;
  public form: UntypedFormGroup;

  public validationVisible: boolean;
  public isRequesting: boolean;
  public isWrongPassword: boolean;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public authenticationService: AuthenticationService,
    public toastrService: ToastrService,
    public router: Router,
    public translate: TranslateService,
    public userService: UserService,
    public errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      reason: ['', [Validators.required]],
      explanation: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.form.controls['explanation'].disable();
  }

  reasonProvided() {
    this.form.controls['explanation'].enable();
  }

  isInputProvided(): boolean {
    const reasonField = this.form.get('reason');
    const explanationField = this.form.get('explanation');
    return (reasonField.valid && explanationField.valid);
  }

  handleContinue() {
    if(this.isInputProvided()) {
      this.isVerificationStep = true;
    }
  }

  handleDelete() {
    if (this.form.valid) {
      this.validationVisible = false;
      this.doRestrictRequest();
    } else {
      this.validationVisible = true;
    }
  }

  doRestrictRequest() {
    if (this.isRequesting) {
      return;
    }

    this.isRequesting = true;
    const password = this.form.value.password;
    const reasonType = this.form.value.reason;
    const reasonDescription = this.form.value.explanation;

    this.userService.rightOfRestriction(password, reasonType, reasonDescription).subscribe(result => {
      this.onRestrictRequestSuccess();
    }, error => {
      this.onRestrictRequestError(error);
    });
  }

  onRestrictRequestSuccess() {
    this.isRequesting = false;
    this.toastrService.success(this.translate.instant('modals.restrict_processing_data.request_processing_sent'));
    return this.bsModalRef.hide();
  }

  onRestrictRequestError(response) {
    this.isRequesting = false;

    if (response.status === 400) {
      if (response.error && response.error.key === 'ERROR_IDENTITY_GDPR_PASSWORD_INVALID') {
        this.validationVisible = true;
        this.isWrongPassword = true;
      }

      if (response.error && response.error.key === 'ERROR_IDENTITY_ACCOUNT_LOCKED') {
        this.bsModalRef.hide();
        this.errorService.showBackendErrorToastByKey(response.error.key);

        this.authenticationService.logout();

      }
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }
}
