import { Component, OnInit } from '@angular/core';
import { PageTabItem } from './../../../../models/page-tab-item';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HomeFlowService } from "./../../../../services/home-flow.service";
import { GeneralService } from "./../../../../services/general.service";
import { DialCode } from "./../../../../models/dial-code";
import { TranslateService } from '@ngx-translate/core';
import { HcpService } from '../../../../services/hcp.service';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { UserService } from "../../../../services/user.service";
import { Profile } from "../../../../models/profile";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LanguageService } from "../../../../services/language.service";
import { EditPersonalDetailsModalComponent } from "../../../../modals/edit-personal-details-modal/edit-personal-details-modal.component";
import { DataService } from '../../../../services/data.service';
import { EditEmailModalComponent } from '../../../../modals/edit-email-modal/edit-email-modal.component';
import { EditPhoneModalComponent } from '../../../../modals/edit-phone-modal/edit-phone-modal.component';
import { ScopeService } from '../../../../services/scope.service';
import { LanguageCode } from '../../../../models/language-code';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings-personal-info',
  templateUrl: './settings-personal-info.component.html',
  styleUrls: ['./settings-personal-info.component.scss']
})
export class SettingsPersonalInfoComponent implements OnInit {
  public pageTabItems: PageTabItem[];

  public dialCode: DialCode;
  public function: string;
  public language: LanguageCode;

  public hcp: HealthCareProfessional;
  public profile: Profile;

  public contactForm: UntypedFormGroup;
  public notificationsForm: UntypedFormGroup;
  private modalRef: BsModalRef;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public homeFlowService: HomeFlowService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public modalService: BsModalService,
    public hcpService: HcpService,
    public translate: TranslateService,
    public userService: UserService,
    public router: Router,
    public dataService: DataService,
    public scopeService: ScopeService,
    public toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.contactFormSetup();
    this.notificationsFormSetup();

    this.pageTabItems = [
      new PageTabItem('pages.default.settings.personal_info.personal_details', 'zone_your_information'),
      new PageTabItem('pages.default.settings.personal_info.contact_details', 'zone_contact_details'),
      new PageTabItem('pages.default.settings.personal_info.notifications', 'zone_notifications')
    ];

    this.getDetails();
  }

  getDetails() {
    this.hcp = this.hcpService.getCurrentStoredHcp();
    this.profile = this.userService.getStoredProfile();

    this.alignlanguage();

    forkJoin([
      this.scopeService.refreshCurrentHcp(),
      this.userService.getProfile()
    ]).subscribe((response: [HealthCareProfessional, Profile]) => {
      this.handleGetDetails(response);
    });
  }

  handleGetDetails(response: [HealthCareProfessional, Profile]) {
    this.hcp = response[0];
    this.profile = response[1];

    this.setDialCode();
    this.alignlanguage();
    this.contactForm.get('contact_channel').setValue(this.profile.contact_channel);
    this.notificationsFormSetup();

  }

  alignlanguage() {
    this.language = this.languageService.getLanguageCodeFromValue(this.profile.locale.language);
  }

  setDialCode() {
    if (this.profile.mobile_number) {
      this.dialCode = this.generalService.mapPhoneNumberCodeToDialCode(this.profile.mobile_number.code);
    }
  }

  contactFormSetup() {
    if (this.contactForm) {
      return;
    }

    this.contactForm = this.formBuilder.group({
      contact_channel: [this.profile?.contact_channel]
    });
  }

  notificationsFormSetup() {
    if (this.notificationsForm) {
      delete this.notificationsForm;
    }

    const profile: Profile = this.profile || new Profile();

    this.notificationsForm = this.formBuilder.group({
      email_notifications: [profile.locale?.notification_channels?.includes('EMAIL')],
      phone_notifications: [profile.locale?.notification_channels?.includes('PUSH')]
    });
    this.notificationsForm.controls['phone_notifications'].disable();
  }

  showEditPersonalDetailsModal(event) {
    event.preventDefault();

    const initialState = {
      hcp: this.hcp
    };

    this.modalRef = this.modalService.show(EditPersonalDetailsModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-lg',
        initialState
      })
    );

    if (this.modalRef) {
      this.modalRef.content.hcpUpdatedEvent.subscribe(() => {
        this.hcp = this.hcpService.getCurrentStoredHcp();
        this.onUpdateSuccess();
      });
    }

  }

  showEditEmailModal(event) {
    event.preventDefault();

    const initialState = {
      profile: this.profile
    };

    this.modalRef = this.modalService.show(EditEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    if (this.modalRef) {
      this.modalRef.content?.onProfileUpdated.subscribe(result => {
        this.visualUpdateProfile(result);
      });
    }
  }

  showEditPhoneModal(event) {
    event.preventDefault();

    const initialState = {
      profile: this.profile
    };

    this.modalRef = this.modalService.show(EditPhoneModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    if (this.modalRef) {
      this.modalRef.content?.onProfileUpdated.subscribe(result => {
        this.visualUpdateProfile(result);
      });
    }
  }

  visualUpdateProfile(result) {
    if (result['mobile_number']) {
      this.profile.mobile_number = result['mobile_number'];
    }

    if (result['email']) {
      this.profile.email = result['email'];
    }

    this.setDialCode();
  }

  handleContactFormSubmission() {
    this.profile.contact_channel = this.contactForm.value.contact_channel;

    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess()
    );
  }

  onUpdateSuccess() {
    this.hcp = this.hcpService.getCurrentStoredHcp();
    this.profile = this.userService.getStoredProfile();

    this.alignlanguage();
    this.setDialCode();

    this.toastrService.success(this.translate.instant('pages.default.settings.personal.update_success'));
  }

  // Notifications
  onHandleNotificationsFormSubmission() {
    this.profile.locale.notification_channels = [];
    if (this.notificationsForm.get('email_notifications').value === true) {
      this.profile.locale.notification_channels.push('EMAIL');
    }

    if (this.notificationsForm.get('phone_notifications').value === true) {
      this.profile.locale.notification_channels.push('PUSH');
    }

    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess());
  }

}
