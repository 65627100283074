import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Appointment, Status } from '../models/appointment';
import { PatientService } from './patient.service';
import { HcpService } from './hcp.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from './general.service';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends ApiService {
  public static StatusAppointmentPending = 'PENDING';
  public static StatusAppointmentAccepted = 'ACCEPTED';
  public static StatusAppointmentDeclined = 'DECLINED';
  public static StatusAppointmentRemoved = Status.REMOVED;
  public static StatusAppointmentNone = Status.NONE;
  public static StatusAppointmentViewed = Status.VIEWED;

  public static TypeSurgery = 'SURGERY';
  public static TypeTreatment = 'TREATMENT';
  public static TypeTherapy = 'THERAPY';

  public onAppointmentAdded: EventEmitter<Appointment> = new EventEmitter();
  public onAppointmentChange: EventEmitter<Appointment> = new EventEmitter();
  public onAppointmentStatusChanged: EventEmitter<any> = new EventEmitter();
  public onAppointmentRemoved: EventEmitter<Appointment> = new EventEmitter();
  public onAppointmentCancelled: EventEmitter<Appointment> = new EventEmitter();
  public onAppointmentDeclined: EventEmitter<Appointment> = new EventEmitter(); // => still needs listeners to trigger any behaviour

  public onShowMoreEvents: EventEmitter<Date> = new EventEmitter();


  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public patientService: PatientService,
    public hcpService: HcpService,
    public translate: TranslateService,
    public modalService: BsModalService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getAllAppointments(
    filters: any = { startDate: '', endDate: '', userStatus: '' },
    size: number = 1000,
    page: number = 0,
    allItems?: Appointment[],
    passedObserver?: any
  ): Observable<any> {


    if (!allItems) {
      allItems = [];
    }

    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (this.authenticationService.hasCcRole()) {
        urlParts.push(`${this.platformUrl}/hospitals/${this.hcpService.getCurrentStoredHospitalUid()}/appointments`);
      } else {
        urlParts.push(`${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/appointments`);
      }

      if (filters.hasOwnProperty('startDate')) {

        const isoStartDate = filters.startDate.toISOString();
        urlParams.push(`start_date=${isoStartDate}`);
      }

      if (filters.hasOwnProperty('endDate')) {
        const isoEndDate = filters.endDate.toISOString();
        urlParams.push(`end_date=${isoEndDate}`);
      }

      if (filters.hasOwnProperty('userStatus')) {
        urlParams.push(`status_of_user=${filters.userStatus}`);
      }

      if (filters.hasOwnProperty('invitees_uid') && filters['invitees_uid'].length > 0) {
        filters['invitees_uid'].forEach(invitee => {
          urlParams.push(`invitees.uuid=${invitee}`);
        });
      }

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        const appointments = this.mapAppointments(result['items']);

        allItems = allItems.concat(appointments);

        if (result.pagination.total_pages - 1 === page || result.pagination.total_pages === 0) {
          const finalObserver = passedObserver || observer;
          finalObserver.next(allItems);
          finalObserver.complete();
        } else {

          this.getAllAppointments(filters, size, page + 1, allItems, observer).subscribe();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPendingAppointments(): Observable<any> {
    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [];

      urlParts.push(`${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/appointments`);

      urlParams.push(`page=0`);
      urlParams.push(`size=1000`);
      urlParams.push(`status_of_user=${AppointmentService.StatusAppointmentNone}`);
      urlParams.push(`cancelled=false`);

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        const appointments = this.mapAppointments(result['items']);
        const returnList = [];

        appointments.forEach(appointment => {
          if (appointment.status_of_user === AppointmentService.StatusAppointmentNone && !appointment.cancelled) {
            returnList.push(appointment);
          }
        });

        observer.next(returnList);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getAppointmentsByType(patient_uid: string, type: string): Observable<any> {
    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [];

      if (this.authenticationService.hasCcRole()) {
        const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
        urlParts.push(`${this.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/appointments`);
      } else {
        const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
        urlParts.push(`${this.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/appointments`);
      }

      urlParams.push(`page=0`);
      urlParams.push(`size=1000`);
      urlParams.push(`type=${type}`);
      urlParams.push(`cancelled=false`);

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapAppointments(result['items']));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }


  cancelAppointment(appointment: Appointment): Observable<any> {
    return new Observable(observer => {
      let url = '';

      if (this.authenticationService.hasCcRole()) {
        const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
        url = `${this.platformUrl}/hospitals/${hospital_uid}/patients/${appointment.patient_invitee.uid}/appointments/${appointment.uid}`;
      } else {
        const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
        url = `${this.platformUrl}/hcps/${hcp_uid}/patients/${appointment.patient_invitee.uid}/appointments/${appointment.uid}`;
      }

      this.authenticatedDelete(url).subscribe(() => {
        appointment.cancelled = true;
        observer.next(appointment.cancelled);
        observer.complete();
        this.onAppointmentCancelled.emit(appointment);
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  deleteAppointment(appointment: Appointment): Observable<any> {
    return new Observable(observer => {
      let url = '';

      if (this.authenticationService.hasCcRole()) {
        const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
        url = `${this.platformUrl}/hospitals/${hospital_uid}/patients/${appointment.patient_invitee.uid}/appointments/${appointment.uid}`;
      } else {
        const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
        url = `${this.platformUrl}/hcps/${hcp_uid}/patients/${appointment.patient_invitee.uid}/appointments/${appointment.uid}`;
      }

      this.authenticatedPatch(url, { status: 'REMOVED' }).subscribe(result => {
        observer.next(result);
        observer.complete();
        this.onAppointmentRemoved.emit(appointment);
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  proposeDeclineAppointment(appointment) {
    const initialState = {
      title: 'modals.appointment.decline_appointment',
      description: 'modals.appointment.are_you_sure_no_undo',
      no: 'action.cancel',
      yes: 'modals.appointment.yes_decline'
    };

    const modalref = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref.content.onChoice.subscribe(() => {
      this.changeAppointment(appointment, 'DECLINED').subscribe(() => {
        modalref.hide();
        appointment.status_of_user = 'DECLINED';
        this.onAppointmentDeclined.emit(appointment);
      });
    });
  }

  getAppointment(patient_uid: string, appointment_uid: string): Observable<Appointment> {
    return new Observable<Appointment>(observer => {
      let url = '';

      if (this.authenticationService.hasCcRole()) {
        const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
        url = `${this.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/appointments/${appointment_uid}`;
      } else {
        const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
        url = `${this.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/appointments/${appointment_uid}`;
      }

      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapAppointment(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createAppointment(appointmentData: any): Observable<Appointment> {
    return new Observable(observer => {
      let url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/patients/${appointmentData['patient_uid']}/appointments`;

      if (this.authenticationService.hasCcRole()) {
        url = `${this.platformUrl}/hospitals/${this.hcpService.getCurrentStoredHospitalUid()}/patients/${appointmentData['patient_uid']}/appointments`;

      }

      this.authenticatedPost(url, appointmentData).subscribe(result => {
        const appointment = this.mapAppointment(result);
        this.onAppointmentAdded.emit(appointment);
        observer.next(appointment);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  updateAppointment(appointment_uid, patient_uid, appointmentData: any): Observable<Appointment> {
    return new Observable(observer => {
      let url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/patients/${patient_uid}/appointments/${appointment_uid}`;

      if (this.authenticationService.hasCcRole()) {
        url = `${this.platformUrl}/hospitals/${this.hcpService.getCurrentStoredHospitalUid()}/patients/${patient_uid}/appointments/${appointment_uid}`;

      }


      this.authenticatedPut(url, appointmentData).subscribe(result => {
        const appointment = this.mapAppointment(result);
        // this.appointmentChangedSource.next(appointment);
        this.onAppointmentChange.emit(appointment);
        observer.next(appointment);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  changeAppointment(appointment: Appointment, status): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/patients/${appointment.patient_invitee.uid}/appointments/${appointment.uid}`;
      this.authenticatedPatch(url, { status: status }).subscribe(result => {
        observer.next(result);
        observer.complete();
        appointment.status_of_user = status;
        this.onAppointmentChange.emit(appointment);
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  mapAppointments(list: any[]): Array<Appointment> {
    const appointments = new Array();
    list.forEach(item => {
      const appointment = this.mapAppointment(item);
      if (appointment.status_of_user !== AppointmentService.StatusAppointmentRemoved) {
        appointments.push(appointment);
      }
    });
    return appointments;
  }

  mapAppointment(input: any): Appointment {
    const appointment = new Appointment(input);
    if (appointment.title_key && appointment.title === "") {
      appointment.title = this.translate.instant(appointment.translationKey);
    }

    if (appointment.description_key && appointment.description === "") {
      appointment.description = this.translate.instant(appointment.translationDescriptionKey);
    }
    return appointment;
  }

  showMoreEventsInYear(date: Date) {
    this.onShowMoreEvents.emit(date);
  }
}
