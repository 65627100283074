import { Component, OnInit } from '@angular/core';
import { BulkApprovalService } from '../../../../services/bulk-approval.service';
import { HcpService } from '../../../../services/hcp.service';
import { Observable, Subject } from 'rxjs';
import { BulkApproval } from '../../../../models/bulk-approval';
import { ConfirmModalComponent } from '../../../../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from '../../../../services/general.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../services/user.service';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HospitalService } from '../../../../services/hospital.service';
import { CareModule } from '../../../../models/care-module';
import { BulkApprovalModalComponent } from '../../../../modals/bulk-approval-modal/bulk-approval-modal.component';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../../services/language.service';
import { PhoneNumberInterface } from './../../../../interfaces/phone-number.interface';
import { AttentionCenterService } from '../../../../services/attention-center.service';
import { BulkApprovalState } from '../../../../enums/bulk-approval-state';


@Component({
  selector: 'app-admin-onboarding-requests',
  templateUrl: './admin-onboarding-requests.component.html',
  styleUrls: ['./admin-onboarding-requests.component.scss']
})
export class AdminOnboardingRequestsComponent implements OnInit {
  public showPathwaysFilter: boolean;
  public filtersAreSet: boolean;
  public isLoading: boolean;
  public hospital_uid: string;
  public hcp_uid: string;
  public careModules: Array<CareModule>;
  public requests: Array<BulkApproval>;
  public selected: Array<string>;
  public dateFormat: DateFormat;
  public filters: any;
  public showApprovedRequests: boolean;
  public patientLastNameSearch = new Subject<string>();

  public sorting: string = 'submission_date,desc';
  public sortingOptions = [];

  public currentPage: number = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize: number = 25;

  constructor(
    public bulkApprovalService: BulkApprovalService,
    public hcpService: HcpService,
    public modalService: BsModalService,
    public translateService: TranslateService,
    public generalService: GeneralService,
    public userService: UserService,
    public hospitalService: HospitalService,
    public localeService: LocaleService,
    public toastrService: ToastrService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService,
    public attentionCenterService: AttentionCenterService
  )
  {
    // Debounce patient last name search
    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.last_name = value;

        this.getRequests().subscribe(() => {});
        this.filtersAreSet = true;
      });
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    const sortingLabel: string = this.translateService.instant('pages.default.administration.onboarding_requests.date_requested');
    const sortingDesc: string = this.translateService.instant('general.sort_dir_desc');
    const sortingAsc: string = this.translateService.instant('general.sort_dir_asc');

    const sortingLabelDesc: string = [sortingLabel, '(' + sortingDesc + ')'].join(' ');
    const sortingLabelAsc: string = [sortingLabel, '(' + sortingAsc + ')'].join(' ');

    this.sortingOptions = [
      { value: 'submission_date,desc', translationKey: sortingLabelDesc },
      { value: 'submission_date,asc', translationKey: sortingLabelAsc }
    ];

    this.clearFilters();

    this.getScope().subscribe(() => {
      this.getRequests().subscribe(() => {});
    });
  }

  clearFilters() {
    this.selected = [];
    this.showApprovedRequests = false;
    this.filtersAreSet = false;

    let selectedCareModule = [];
    if (this.filters && this.filters.care_module_uid) {
      selectedCareModule = this.filters.care_module_uid;
    }

    this.filters = {
      last_name: '',
      approval_status: ['PENDING_APPROVAL'],
      care_module_uid: selectedCareModule,
    };
  }

  actionResetFilters() {
    this.clearFilters();
    this.getRequests().subscribe(() => {});
  }

  getScope(): Observable<any> {
    return new Observable(observer => {
      const hcp = this.hcpService.getCurrentStoredHcp();
      this.hcp_uid = hcp.uid;
      this.hospital_uid = hcp.hospital_uid;

      this.hospitalService.getCareModules(this.hospital_uid).subscribe(careModules => {
        this.careModules = careModules;
        this.filters.care_module_uid = this.careModules[0].uid;

        observer.next();
        observer.complete();
      });
    });
  }

  getRequests(showLoading = true): Observable<any> {
    if (showLoading) {
      this.requests = undefined;
      this.isLoading = true;
    }


    return new Observable(observer => {
      this.bulkApprovalService.getBulkApprovals(this.hospital_uid, this.filters, this.sorting, this.currentPage-1, this.currentPageSize).subscribe(result => {
        this.isLoading = false;
        this.requests = result.approvals;

        if(result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        if(this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
          this.currentPage = 1;
          this.getRequests().subscribe(() => {
            observer.next();
            observer.complete();
          })
        }

        observer.next();
        observer.complete();
      })
    });
  }

  onPaginationPageChanged(page) {
    if(page!==this.currentPage) {
      this.currentPage = page;
      this.getRequests().subscribe(() => {});
    }
  }

  onPaginationRangeChanged(range) {
    if(range!==this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getRequests().subscribe(() => {});
    }
  }

  onShowApprovedCheck() {
    if(this.showApprovedRequests) {
      this.filters.approval_status = ['PENDING_APPROVAL','APPROVED'];
    } else {
      this.filters.approval_status = ['PENDING_APPROVAL'];
    }

    this.filtersAreSet = true;
    this.getRequests().subscribe(() => {});
  }

  onChangeSort(): void {
    this.getRequests().subscribe(() => {});
  }

  onCareModuleFilterChange() {
    this.filtersAreSet = true;
    this.getRequests().subscribe(() => {});
  }

  checked(bulkApproval: BulkApproval) {
    const index = this.selected.indexOf(bulkApproval.uid);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(bulkApproval.uid);
    }
  }

  isChecked(bulkApproval: BulkApproval) {
    return (this.selected.indexOf(bulkApproval.uid) > -1);
  }

  togglePathwaysFilter(shown: boolean = undefined) {
    if (shown !== undefined) {
      this.showPathwaysFilter = shown;
    } else {
      this.showPathwaysFilter = !this.showPathwaysFilter;
    }
  }

  clickOutsideHeaderFilters() {
    this.togglePathwaysFilter(false);
  }

  bulkActionApprove(event) {
    event.preventDefault();
    if (this.selected.length > 0) {
      this.showBulkApproveModal(this.selected);
    }
  }

  bulkActionDecline(event) {
    event.preventDefault();
    if (this.selected.length > 0) {
      this.showBulkDeclineModal(this.selected);
    }
  }

  showApproveModal(event, onboardingRequest: BulkApproval) {
    event.preventDefault();
    this.showBulkApproveModal([onboardingRequest.uid]);
  }

  showDeclineModal(event, onboardingRequest: BulkApproval) {
    event.preventDefault();
    this.showBulkDeclineModal([onboardingRequest.uid]);
  }

  showBulkApproveModal(onboardingRequestUids: string[]) {
    const modalRef = this.modalService.show(BulkApprovalModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          patientUids: onboardingRequestUids,
          careModuleUid: this.filters.care_module_uid
        }
      })
    );

    if(modalRef) {
      modalRef.content.requestUpdated.subscribe(() => {
        this.getRequests(false).subscribe(() => {
          this.selected = [];
        });

        this.attentionCenterService.refresh();
      });
    }
  }

  showBulkDeclineModal(onboardingRequestUids: string[]) {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'pages.default.administration.onboarding_requests.decline_modal_title',
          description: 'pages.default.administration.onboarding_requests.decline_modal_description',
          yes: 'action.i_am_sure'
        }
      })
    );

    if (modalRef) {
      modalRef.content.onChoice.subscribe(() => {
        modalRef.content.showYesLoading = true;
        this.bulkApprovalService.updateBulkApprovals(this.hospital_uid, { patient_uids: onboardingRequestUids, approval: BulkApprovalState.REJECTED }).subscribe(
          () => this.onBulkUpdateSuccess(modalRef, false),
          (e) => this.onBulkUpdateError(modalRef, e));
      });
    }
  }

  onBulkUpdateSuccess(modalRef: BsModalRef, accepted: boolean) {
    let msg;

    if (accepted) {
      msg = this.translateService.instant('pages.default.administration.onboarding_requests.approve_success_notif');
    } else {
      msg = this.translateService.instant('pages.default.administration.onboarding_requests.approve_error_notif');
    }
    this.toastrService.success(msg);

    this.getRequests(false).subscribe(() => {
      modalRef.content.showYesLoading = false;
      modalRef.hide();
      this.selected = [];

      this.attentionCenterService.refresh();
    });
  }

  onBulkUpdateError(modalRef: BsModalRef, error: any) {
    this.toastrService.error(this.translateService.instant('error.general'));

    modalRef.content.showYesLoading = false;
    modalRef.hide();
  }

  getPhonePrefix(phone: PhoneNumberInterface): string {
    return this.generalService.getDialCodeForCountry(phone.code).label;
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1){
      return itemsLabel + " " + translatedNumber;
    }

    return  translatedNumber + " " + itemsLabel;
  }
}
