import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserTask } from '../../models/user-task';
import { FormField } from '../../models/form-field';
import { AuthenticationService } from '../../services/authentication.service';
import { UserTaskService } from '../../services/user-task.service';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-user-task-form',
  standalone: false,
  templateUrl: './user-task-form.component.html',
  styleUrl: './user-task-form.component.scss'
})
export class UserTaskFormComponent implements OnInit {
  @Input() isReadOnly: boolean;
  @Input() validationVisible: boolean;
  @Input() userTask: UserTask;

  @Input() form: FormGroup = new FormGroup({});
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public isEvaluating = false;

  constructor(
    public authenticationService: AuthenticationService,
    public userTaskService: UserTaskService,
    public hcpService: HcpService,
  ) { }

  ngOnInit() {
    this.addFormControls();
  }

  addFormControls() {
    if (this.userTask?.form_fields) {
      this.userTask.form_fields.forEach(field => {
        console.log(field);
        this.createControl(field);
      });
    }

    if (this.userTask?.sections) {
      this.userTask.sections.forEach(section => {
        section.form_fields.forEach(field => {
          this.createControl(field);
        });
      });
    }

    if (this.isReadOnly) {
      this.form.disable();
    }

    this.form.updateValueAndValidity();

    if (this.userTask.hasDff) {
      this.evaluateControls();
    }
  }

  createControl(field: FormField) {
    const control = new FormControl(null, []);
    this.form.addControl(field.id, control);

    if (field.field_value?.value) {
      this.form.get(field.id)?.setValue(field.field_value?.value);
    }

    this.form.get(field.id)?.updateValueAndValidity();
  }

  evaluateControls() {
    const data = this.userTaskService.mapPayload(this.form.value);

    if (Object.keys(data).length <= 0 || this.isReadOnly) return;

    this.isEvaluating = true;
    const observable = this.authenticationService.hasCcRole() ?
      this.userTaskService.evaluateHospitalUserTask(this.userTask.uid, this.hcpService.getCurrentStoredHospitalUid(), this.form.value) :
      this.userTaskService.evaluateHcpUserTask(this.userTask.uid, this.hcpService.getCurrentStoredHcpUid(), this.form.value);

    observable.subscribe({
      next:
        result => {
          this.userTask.form_fields.forEach(field => {
            field.visible = !!result?.visible_fields.find(f => f.field_id === field.id && f.visible);
            if (!field.visible) this.resetControl(field);
          });

          this.userTask.sections.forEach(section => {
            section.visible = !!result?.visible_sections.find(s => (s.section_id === section.id) && s.visible);

            section.form_fields.forEach(field => {
              field.visible = !!result?.visible_fields.find(f => f.field_id === field.id && f.visible);
              if (!field.visible) this.resetControl(field);
            });
          });

          this.isEvaluating = false;
        }, error: () => this.isEvaluating = false
    });
  }

  resetControl(field: FormField) {
    this.form.get(field.id)?.reset();
    this.form.get(field.id)?.updateValueAndValidity();
  }

  getVisibleFormFields(): any[] {
    return this.userTask?.form_fields.filter(field => field?.visible === true) || [];
  }


}
