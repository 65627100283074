import { Component, HostBinding, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../services/general.service';
import { BannerInfoModalComponent } from '../../modals/banner-info-modal/banner-info-modal.component';
import { BannerInitModalComponent } from '../../modals/banner-init-modal/banner-init-modal.component';
import { BannerService } from '../../services/banner.service';
import { Banner, BannerType } from '../../models/banner';

@Component({
  selector: 'app-sidebar-banner',
  templateUrl: './sidebar-banner.component.html'
})
export class SidebarBannerComponent implements OnInit {
  public hasMoreInfo = false;
  public banner: Banner | null;
  public bannerType = BannerType;

  // @HostBinding('class.mb-lg-2') isContentPartVisible: boolean = false;

  constructor(
    public modalService: BsModalService,
    public bannerService: BannerService
  ) { }

  ngOnInit() {
    this.getBanners();
  }

  getBanners() {
    this.bannerService.getBanners().subscribe((banner: Banner) => {
      this.handleBanner(banner);
    });
  }

  handleBanner(banner: Banner) {
    if(!banner) return;
      this.banner = banner;
      this.hasMoreInfo = !!banner?.more_information;
      // this.isContentPartVisible = true;

      if (
        this.banner.type === BannerType.CRITICAL &&
        this.banner.modal_content &&
        !this.bannerService.isBannerHidden(this.banner.uid, this.banner.updated_at) &&
        !this.bannerService.isBannerShown(this.banner.uid)
      ) {
        this.showInitModal(this.banner.title, this.banner.modal_content, this.hasMoreInfo);
      }
  }

  showMoreInfoModal(title: string, description: string) {
    const initialState = {
      title,
      description
    };

    this.modalService.show(BannerInfoModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl modal-compact',
        initialState
      })
    );
  }

  showInitModal(title: string, description: string, hasMoreInfo: boolean) {
    const initialState = {
      title,
      description,
      hasMoreInfo,
    };

    const modalRef = this.modalService.show(BannerInitModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-compact',
        initialState
      })
    );

    modalRef.content?.onShowMoreInfoModal.subscribe(() => {
      this.showMoreInfoModal(this.banner.title, this.banner.more_information);
      this.closeInitModal(modalRef)
    });

    modalRef.content?.onAcknowledgeBanner.subscribe(() => {
      this.bannerService.updateHiddenBanners(this.banner.uid, this.banner.updated_at);
      this.closeInitModal(modalRef)
    });

    modalRef.content?.onHide.subscribe(() => {
      this.closeInitModal(modalRef)
    });
  }

  closeInitModal(modalRef: BsModalRef) {
    modalRef.hide();
    this.bannerService.updateShownBanners(this.banner.uid);
  }
}
