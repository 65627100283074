<div class="modal-header">
  <h6 class="h7">{{ 'modals.candidate_users.other_candidate_users' | translate }}</h6>
  <button type="button" class="btn-close" id="query-list-candidate-users-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div class="participant-item" *ngFor="let participant of participants">
    <app-avatar [profilePicture]="participant?.profile_picture" />

    <div class="meta">
      <p class="title">{{ participant?.getFullName() }}</p>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-primary" id="query-list-candidate-users-modal-handle-close" (click)="handleClose()">{{ 'action.close' | translate }}</button>
  </div>
</div>
