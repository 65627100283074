
<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part">
      <h4>{{ 'pages.general.maintenance.title' | translate }}</h4>
      <h6 class="mb-4">{{ 'pages.general.maintenance.description' | translate }}</h6>
      <p *ngIf="from && until"><strong>{{ 'pages.general.maintenance.info' | translate:{ from: from, until: until } }}</strong></p>
      <p class="text-muted">{{ 'pages.general.maintenance.help' | translate }}</p>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights [languageSelect]="false"></app-flow-copyrights>
    </div>
  </div>

</div>
<div class="layout-body fixed with-background d-flex flex-column">
  <div class="layout-background"></div>
</div>
