export enum ConsentStatus {
  OPEN = 'OPEN',
  CONSENTED = 'CONSENTED',
  DECLINED = 'DECLINED'
}

export class Consent {
  public key: string;
  public title: string;
  public introduction: string;
  public body: string;
  public order: number;
  public type: string;
  public handled: boolean;
  public status: string;
  public version: number;
  public hospital_uid: string;
  public language: string;
  public category: string;
  public date: string;
  public status_at: string;
  public hcp_uid: string


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.type  = item.type ? item.type.toUpperCase() : '';
    this.hospital_uid = item.hospital_uid ? item.hospital_uid :  '';
    this.hcp_uid = item.hcp_uid ? item.hcp_uid :  '';
    this.key = item.key ? item.key :  '';
    this.language = item.language ? item.language : '';
    this.version = item.version;
    this.order = item.order;
    this.date = item.date ? item.date : '';
    this.category = item.category ? item.category : '';
    this.status_at = item.status_at ? item.status_at :  '';
    this.status = item.status ? item.status.toUpperCase() :  '';
  }

  addDetails(details) {
    this.title = details.title;
    this.introduction = details.introduction;
    this.body = details.body;
  }

  getType() {
    if(this.type) {
      return this.type.toUpperCase();
    }
  }

  isPlatformContactConsent(): boolean {
    if(this.category && this.type) {
      const upperCategory = this.category?.toUpperCase();
      const upperType = this.type?.toUpperCase();
      return (upperCategory === 'CONTACT' && upperType === 'PLATFORM');
    } else {
      return false;
    }
  }

  icon(): string {
    let iconString;

    if (this.category) {
      const upperCategory = this.category.toUpperCase();

      switch (upperCategory) {
        case 'CONTACT':
          iconString = '/assets/svg/conversation-outline-24.svg';
          break;
        case 'PRIVACY':
          iconString = '/assets/svg/privacy-outline-24.svg';
          break;
        case 'ANALYTICS':
        case 'LEGAL':
        default:
          iconString = '/assets/svg/file-general-24.svg';
          break;
      }
      return iconString;
    }
  }

  isConsented(): boolean {
    return this.status.toUpperCase() === 'CONSENTED';
  }

  isHospitalConsent(): boolean {
    return this.type === 'HOSPITAL';
  }

  isPlatformConsent(): boolean {
    return this.type === 'PLATFORM';
  }
}
