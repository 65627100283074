<div class="modal-header">
  <h6 class="h7">{{ 'modals.mobile_verification.receive_notifications' | translate}}</h6>

  <button type="button" class="btn-close" id="mobile-verification-code-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <p>{{  'modals.mobile_verification.enter_code' | translate}}</p>

    <div class="form-group requiredmb-0">
      <label for="inputCode">Verification code</label>

      <input type="text" id="inputCode" class="form-control w-100" placeholder="Verification code" formControlName="code"   required>

      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>
      <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>
    </div>
    <p class="text-muted mt-3 mb-0">{{  'modals.mobile_verification.not_mobile' | translate}}</p>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="mobile-verification-code-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" id="mobile-verification-code-modal-submit" (click)="handleSubmit()">
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
