<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!isLoadingGoal"></app-breadcrumb>
    </div>

    <div class="container my-auto">
      <h5>
        @if (goal?.title) {
          {{ goal?.title | translateHelp | translate }}
        } @else {
          ...
        }
      </h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>

  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoadingGoal">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoadingGoal">

    <div id="zone_goal">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.goal_detail.goal' | translate }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="goal-thumb goal-thumb-graph rounded-3 w-100" *ngIf="goal?.evaluation?.evaluation_graph === 'PROGRESS' && goal.evaluation?.available()">
            <circle-progress class="extra-large"
              [percent]="goalService.getProgressPercentage(goal)"
              [title]="goalService.getProgressTitle(goal)"
              [subtitle]="goalService.getProgressSubtitle(goal) | translate"

            ></circle-progress>
          </div>

          <div class="goal-thumb goal-thumb-graph rounded-3" *ngIf="goal?.evaluation?.evaluation_graph === 'PROGRESS' && !goal.evaluation?.available()">
            <label class="text-center py-3"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
          </div>

          <div class="goal-thumb goal-thumb-text rounded-3 w-100" *ngIf="goal?.evaluation?.evaluation_graph === 'TEXT'">
            <p class="title text-truncate">{{ goal?.evaluation?.evaluation_value || '-' }}</p>
            <p *ngIf="goal?.evaluation?.evaluation_unit">{{ goal?.evaluation?.evaluation_unit | translateHelp | translate }}</p>
            <label class="text-center mt-2" *ngIf="!goal.evaluation?.available()"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
          </div>
        </div>

        <div class="col-9 col-lg-6">
          <div class="p-start-1">
            <h6 class="h7 mb-2" *ngIf="goal?.title">{{ goal?.title | translateHelp | translate }}</h6>
            <div *ngIf="descriptionContent" [innerHtml]="descriptionContent?.phase_description"></div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div id="zone_details">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.goal_detail.details' | translate }}</h5>
        </div>

        <button id="user-task-update" href="" class="block-action btn btn-secondary m-start-auto" *ngIf="goalUserTaskBasic" (click)="actionGoalUserTask($event)">
          {{ 'action.update' | translate }}
        </button>
      </div>

      <div class="row g-2">
        <div class="col-6">
          <div class="single-item-block">
            <div id="details-last-input" class="block-wrapper">
              <p>{{ 'pages.default.goal_detail.details_last_input' | translate }}</p>
              <p class="text-muted font-weight-semibold" *ngIf="!goal?.input_latest">...</p>
              <p class="text-dark font-weight-semibold" *ngIf="goal?.input_latest">{{ goal?.input_latest }}&nbsp;{{ goal?.input_unit | translateHelp | translate }}</p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="single-item-block">
            <div id="details-target" class="block-wrapper">
              <p>{{ 'pages.default.goal_detail.details_target' | translate }}</p>
              <p class="text-muted font-weight-semibold" *ngIf="!goal?.target_latest">...</p>
              <p class="text-dark font-weight-semibold" *ngIf="goal?.target_latest">{{ goal?.target_latest }}&nbsp;{{ goal?.input_unit | translateHelp | translate }}</p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="single-item-block">
            <div id="details-initial-input" class="block-wrapper">
              <p>{{ 'pages.default.goal_detail.details_initial_input' | translate }}</p>
              <p class="text-muted font-weight-semibold" *ngIf="!goal?.input_initial">...</p>
              <p class="text-dark font-weight-semibold" *ngIf="goal?.input_initial">{{ goal?.input_initial }}&nbsp;{{ goal?.input_unit | translateHelp | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div id="zone_history" class="mb-5">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.goal_detail.history' | translate }}</h5>
        </div>
      </div>

      <div class="row justify-content-between align-items-center mb-2">
        <div class="col col-auto">
          <div class="custom-scope-range">
            <button class="previous" id="goal-history-time-scope-prev" (click)="prevTimeScope()">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-left-24.svg'"></span>
            </button>
            <span class="label mx-1">{{ getTimeScopeLabel() }}</span>
            <button class="next" id="goal-history-time-scope-next" (click)="nextTimeScope()">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-right-24.svg'"></span>
            </button>
          </div>
        </div>
        <div class="col col-auto">
          <div class="btn-group" role="group">
            <button type="button" class="btn" id="calendar-change-view-to-week" [ngClass]="(view_type === 'WEEK') ? 'btn-link active' : 'btn-tertiary'" (click)="switchViewType('WEEK')">{{ 'pages.default.goal_detail.week' | translate }}</button>
            <button type="button" class="btn" id="calendar-change-view-to-month" [ngClass]="(view_type === 'MONTH') ? 'btn-link active' : 'btn-tertiary'" (click)="switchViewType('MONTH')">{{ 'pages.default.goal_detail.month' | translate }}</button>
            <button type="button" class="btn" id="calendar-change-view-to-year" [ngClass]="(view_type === 'YEAR') ? 'btn-link active' : 'btn-tertiary'" (click)="switchViewType('YEAR')">{{ 'pages.default.goal_detail.year' | translate }}</button>
          </div>
        </div>
      </div>

      <div class="highcharts-wrapper" [chart]="historyChart" [ngClass]="{'loading-state': isLoadingGoalEntries}"></div>
    </div>

  </div>
</div>
