<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_hcp.title' | translate }}</h6>
  <button (click)="onHandleClose()" aria-label="Close" class="btn-close" id="edit-hcp-modal-close" data-dismiss="modal" type="button"></button>
</div>

<div class="modal-body">
  <div *ngIf="!form || !hcp">
    <app-page-loader padding="1rem"></app-page-loader>
  </div>

  <form *ngIf="form && hcp" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">

    <!-- Gender -->
    <div class="form-group mb-n1">
      <label class="required">{{ 'form.labels.gender' | translate }}</label>

      <div class="form-checks-inline ">
        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexMale" type="radio" value="MALE">
          <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexFemale" type="radio" value="FEMALE">
          <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexOther" type="radio" value="UNKNOWN">
          <label class="form-check-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
        </div>
      </div>

      <app-feedback-field [field]="form.get('gender')"/>
    </div>

    <!-- First name -->
    <div class="form-group ">
      <label class="required" for="inputFirstName">{{ 'form.labels.first_name' | translate }}</label>
      <input placeholder="" class="form-control w-100"
        formControlName="first_name"
        id="inputFirstName" type="text">
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group ">
      <label class="required" for="inputLastName">{{ 'form.labels.last_name' | translate }}</label>
      <input placeholder="" class="form-control w-100" formControlName="last_name"
        id="inputLastName" type="text">
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Phone number -->
    <div class="my-3">
      <div class="row">
        <div class="col-6" [ngClass]="{'order-last': languageService.isHebrew}">
          <div class="form-group ">
            <label for="inputPhoneCode">{{ 'form.labels.country_code' | translate }}</label>
            <input placeholder="" class="form-control w-100"
              formControlName="phoneNumberCountryCode" id="inputPhoneCode"
              type="tel">
          </div>
        </div>
        <div class="col-6" [ngClass]="{'order-first': languageService.isHebrew}">
          <div class="form-group ">
            <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
            <input placeholder="" class="form-control w-100"
              formControlName="phoneNumber" id="inputPhone"
              type="tel">
          </div>
        </div>
      </div>
    </div>

    <!-- Email -->
    <div class="mb-3">
      <div class="row align-items-end">
        <div class="col">
          <div class="form-group  mb-0">
            <label for="inputEmail">{{ 'form.labels.email' | translate }}</label>
            <input type="email" id="inputEmail" class="form-control w-100" placeholder="" formControlName="email">
          </div>
        </div>
        <div class="col-auto" *ngIf="hcp?.email_change_allowed">
          <button class="btn btn-secondary btn-lg" (click)="showEditEmailModal()" id="edit-hcp-email">{{ 'modals.edit_hcp.edit_email' | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [label]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Function -->
    <div class="form-group required">
      <app-select
        [label]="('form.labels.function' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"

        formControlName="function"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
    </div>

    <!-- Job title -->
    <div class="form-group ">
      <label for="jobTitle" class="required">{{ 'form.labels.job_title' | translate }}</label>
      <input placeholder="" class="form-control"
        formControlName="job_title" id="jobTitle" type="text">
    </div>

    <!-- Location -->
    <div class="form-group ">
      <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
      <input placeholder="" class="form-control"
        formControlName="location" id="inputLocation" type="text">
    </div>

    <!-- Checkbox: use location URL -->
    <div class="form-group">
      <div class="form-check m-0">
        <input class="form-check-input" formControlName="useLocationUrl" id="checkboxUseLocationUrl" type="checkbox">
        <label class="form-check-label" for="checkboxUseLocationUrl">{{ 'modals.edit_hcp.include_location_url' | translate }}</label>
      </div>
    </div>

    <!-- Location URL -->
    <div *ngIf="form.get('useLocationUrl').value" class="form-group ">
      <label for="inputLocationUrl">{{ 'form.labels.location_url' | translate }}</label>
      <input placeholder="" class="form-control" formControlName="locationUrl" id="inputLocationUrl" type="text">
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-secondary" id="edit-hcp-modal-cancel">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" id="edit-hcp-modal-submit" [ngClass]="{'loader': isSaving}" class="btn btn-primary m-start-2">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
