import { Component, OnInit, Input } from '@angular/core';
import { Patient } from '../../models/patient';
import { DateFormat } from '../../models/date-format';
import { LanguageService } from '../../services/language.service';
import { LocaleService } from '../../services/locale.service';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignMdtModalComponent } from '../../modals/assign-mdt-modal/assign-mdt-modal.component';
import { ScopeService } from '../../services/scope.service';
import { Pathway, PathwayStatus } from '../../models/pathway';
import { AddPathwayModalComponent } from '../../modals/add-pathway-modal/add-pathway-modal.component';
import { PatientService } from '../../services/patient.service';
import { HcpService } from '../../services/hcp.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { StopPathwayModalComponent } from '../../modals/stop-pathway-modal/stop-pathway-modal.component';
import { CareModuleType } from '../../models/care-module';

@Component({
  selector: 'app-patient-tabbed-details',
  templateUrl: './patient-tabbed-details.component.html',
  styleUrls: ['./patient-tabbed-details.component.scss']
})
export class PatientTabbedDetailsComponent implements OnInit {
  @Input() patient: Patient;

  public dateFormat: DateFormat;
  public activePathway: Pathway;
  public patientEnriched: boolean = false;
  public showUserDetails: boolean = false;
  public showUserDetailsTimeout: ReturnType<typeof setTimeout>;
  public hideUserDetailsTimeout: ReturnType<typeof setTimeout>;

  constructor(
    public generalService: GeneralService,
    public languageService: LanguageService,
    public localeService: LocaleService,
    public authenticationService: AuthenticationService,
    public modalService: BsModalService,
    public scopeService: ScopeService,
    public patientService: PatientService,
    public hcpService: HcpService,
    public dataService: DataService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    if (this.patient?.pathways?.length > 0) {
      this.activePathway = this.patient.pathways[0];
    }
  }

  isCC(): boolean {
    return this.authenticationService.hasCcRole();
  }

  isActivePathway(pathway) {
    return pathway === this.activePathway;
  }

  isClinical(pathway: Pathway): boolean {
    return pathway?.care_module?.type === CareModuleType.CLINICAL;
  }

  isStopped(pathway: Pathway): boolean {
    return pathway?.status === PathwayStatus.STOPPED;
  }

  isEnded(pathway: Pathway): boolean {
    return pathway?.status === PathwayStatus.ENDED;
  }

  setActivePathway(event, pathway) {
    event.preventDefault();
    this.activePathway = pathway;
  }

  editMdt(event) {
    event.preventDefault();
    this.openEditMdtModal();
  }
  openEditMdtModal() {
    const initialState = {
      type: 'add',
      patient: this.patient,
      pathway: this.patient.pathways[0]  /// this needs to be changed to the current pathway
    };

    const modalref = this.modalService.show(AssignMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  addPathway(event?) {
    event?.preventDefault();
    this.openAddPathwayModal();
  }


  stopPathway(event?) {
    event?.preventDefault();
    this.openStopPathwayModal();
  }

  openAddPathwayModal() {
    const initialState = {
      patientUid: this.patient.uid,
      pathwayList: this.patient.pathways
    };

    const modalref = this.modalService.show(AddPathwayModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-m',
        initialState
      })
    );

    modalref.content.pathwayAdded.subscribe(result => {
      this.router.navigate(['/patient', this.patient.uid], {
        queryParams: {
          'pathwayUid': result['uid']
        }
      });
    });
  }

  openStopPathwayModal() {
    const initialState = {
      patient: this.patient,
      selectedPathway: this.activePathway
    };

    const modalref = this.modalService.show(StopPathwayModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    modalref.content.pathwayStopped.subscribe(() => {
      modalref.hide();
    });
  }

  getLanguage() {
    return this.languageService.findLanguageCodeByEnum(this.patient.language);
  }

  enrichPatient() {
    if (!this.patientEnriched) {
      const uid = this.patient.uid;
      const patientObservable = this.isCC()
        ? this.patientService.getDashboardPatientByHospital(this.hcpService.getCurrentStoredHospitalUid(), uid)
        : this.patientService.getDashboardPatientByHcp(this.hcpService.getCurrentStoredHcpUid(), uid);

      patientObservable.subscribe(result => {
        this.patient.electronic_medical_record = result.electronic_medical_record;
        this.patient.preferred_name = result.preferred_name;
        this.patient.email = result.email;
        this.patient.phone_number = result.phone_number;
        this.patient.gender = result.gender;
        this.patient.language = result.language;

        this.patientEnriched = true;
      });
    }
  }

  enterUserInfo() {
    this.enrichPatient();
    clearTimeout(this.hideUserDetailsTimeout);

    this.showUserDetailsTimeout = setTimeout(() => {
      this.showUserDetails = true;
    }, 500);
  }

  leaveUserInfo() {
    clearTimeout(this.showUserDetailsTimeout);

    this.hideUserDetailsTimeout = setTimeout(() => {
      this.showUserDetails = false;
    }, 200);
  }

  enterUserDetails() {
    clearTimeout(this.hideUserDetailsTimeout);
  }

  leaveUserDetails() {
    this.hideUserDetailsTimeout = setTimeout(() => {
      this.showUserDetails = false;
    }, 200);
  }
}
