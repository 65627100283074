import { TranslationInterface } from '../interfaces/translation.interface';
import { CareModule } from './care-module';
import { FormField } from './form-field';
import { HealthCareProfessional } from './health-care-professional';
import { Patient } from './patient';
import { UserTaskBasic } from './user-task-basic';
import { UserTaskSection } from './user-task-section';

export class UserTask {
  public uid: string;
  public title: TranslationInterface;
  public description: TranslationInterface;
  public form_fields: Array<FormField> = [];
  public sections: Array<UserTaskSection> = [];
  public candidate_users: Array<HealthCareProfessional> = [];
  public accessible_by_patient: boolean = true;

  public patient: Patient;
  public care_module: CareModule;
  public patient_pathway_id: string;
  public due_date: string;
  public created_at: string;
  public type: string;
  public priority: number;
  public translationKeyTitle: string;
  public translationKeyDescription: string;
  public assignee: HealthCareProfessional | Patient;
  public assignee_type: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.id) {
      this.uid = item.id;
    }

    if (item.uid) {
      this.uid = item.uid;
    }

    this.title = item.title;
    this.description = item.description;
    this.patient_pathway_id = item.patient_pathway_id;
    this.due_date = item.due_date;
    this.created_at = item.created_at;
    this.priority = item.priority;
    this.accessible_by_patient = item.accessible_by_patient;

    this.type = item.type;

    if (item.patient) {
      this.patient = new Patient(item.patient);
    }

    if (item.care_module) {
      this.care_module = new CareModule(item.care_module);
    }

    if (item.form_fields) {
      item.form_fields.forEach(formField => {
        this.form_fields.push(new FormField(formField));
      });
    }

    if (item.sections) {
      item.sections.forEach(section => {
        this.sections.push(new UserTaskSection(section));
      });
    }

    if (this.title && this.title.region && this.title.key) {
      this.translationKeyTitle = 'shared.' + this.title.region + '.' + this.title.key;
    }
    if (this.description && this.description.region && this.description.key) {
      this.translationKeyDescription = 'shared.' + this.description.region + '.' + this.description.key;
    }
    if (item.candidate_users) {
      item.candidate_users.forEach(user => {
        this.candidate_users.push(new HealthCareProfessional(user));
      })
    }

    this.assignee_type = item.assignee?.type;

    if (item.assignee?.type === 'PATIENT') {
      this.assignee = new Patient(item.assignee);
    }

    if (item.assignee?.type === 'HCP') {
      this.assignee = new HealthCareProfessional(item.assignee);
    }

  }

  addBasicData(userTaskBasic: UserTaskBasic) {
    this.uid = userTaskBasic.uid;
    this.title = userTaskBasic.title;
    this.due_date = userTaskBasic.due_date;
    this.created_at = userTaskBasic.created_at;
    this.care_module = userTaskBasic.care_module;
    this.patient = userTaskBasic.patient;
    this.type = userTaskBasic.type;
    this.priority = userTaskBasic.priority;
  }

  passed() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    const hours48Ago: Date = date;

    if (new Date(this.created_at).getTime() < hours48Ago.getTime()) {
      return true;
    }
    return false;
  }

  sortedCandidateUsers(hcpUid) {
    return this.candidate_users.sort((a, b) => a.uid == hcpUid ? -1 : 1);
  }

  isAssignedTo(hcpUid): boolean {
      const inCandidateList = this.candidate_users?.filter((item) => item.uid === hcpUid);
      return (hcpUid === this.assignee?.uid || inCandidateList?.length > 0);
  }

  get canSave(): boolean {
    return this.type === 'CHECKLIST';
  }

  get hasDff(): boolean {
    for (const form_field of this.form_fields) {
      if (form_field?.evaluation_required) {
        return true;
      }
    }

    for (const section of this.sections) {
      if (section.form_fields.length) {
        for (const form_field of section.form_fields) {
          if (form_field?.evaluation_required) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
