<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
</div>

<div class="body-content">
  <!-- Loader -->
  <div class="container" *ngIf="mustShowLoader">
    <app-page-loader></app-page-loader>
  </div>


  <div class="container " *ngIf="!mustShowLoader">
    <div class="row align-items-center">
      <div class="col-2"></div>
      <div class="col">


        <div class="d-flex w-100">
          <div class="m-start-auto">
            <button class="btn btn-tertiary btn-icon m-start-1" id="delete-note" *ngIf="note  && note?.created_by?.uid == currentHcpUid && !isEditingMode" (click)="deleteNote($event, note?.uid)">
              <span class=" text-danger icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/delete-24.svg'"></span>
            </button>

            <button class="btn btn-secondary m-start-1" id="cancel-note" *ngIf="isEditingMode && (note?.created_by?.uid == currentHcpUid || !note)" (click)="onCancelBtnClicked($event)">
              {{ 'action.cancel' | translate }}
            </button>

            <button class="btn btn-primary m-start-1" id="save-note" [disabled]="!isDirty()" *ngIf="isEditingMode && (note?.created_by?.uid == currentHcpUid || !note)" (click)="onSaveBtnClicked($event)">
              {{ 'action.save' | translate }}
            </button>

            <button class="btn btn-secondary m-start-1" id="edit-note" *ngIf="!isEditingMode && note?.created_by?.uid == currentHcpUid" (click)="onEditBtnClicked($event)" >
              {{ 'action.edit' | translate }}
            </button>
          </div>
        </div>


        <p class="text-muted extra-small font-weight-medium m-1" *ngIf="note">
          <!-- When created, but not edited yet -->
          <span *ngIf="note?.changed_at === note?.created_at">{{ 'general.created_on' | translate }} {{ note?.created_at | timeZoneDate}} {{ 'general.at' | translate }} {{ note?.created_at | time}}</span>
          <!-- When created, and edited later -->
          <span *ngIf="note?.changed_at !== note?.created_at">{{ 'general.edited_on' | translate }} {{ note?.changed_at | timeZoneDate}} {{ 'general.at' | translate }}  {{ note?.changed_at | time}}</span>
        </p>
        <div class="pb-3" *ngIf="!note"></div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <!--  -->
  <!-- Editor Mode - Start -->
  <!--  -->
  <div class="container" [ngClass]="{ 'd-none': (mustShowLoader || !isEditingMode), 'validation-visible': validationVisible }">
    <div class="row align-items-center">
      <div class="col-2"></div>
      <div class="col">
        <div class="content-editor-rows">
          <div class="editor-row input lg">
            <textarea placeholder="{{ 'note.untitled' | translate }}" #titleTextArea class="no-resize" maxlength="255" [(ngModel)]="title"> </textarea>
          </div>
          <p class="validation-feedback general-feedback">{{ 'form.feedback.field_required' | translate }}</p>
          <div class="editor-row cke">
            <div id="editor"></div>
          </div>

        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
  <!--  -->
  <!-- Editor Mode - End -->
  <!--  -->


  <!--  -->
  <!-- View Mode - Start -->
  <!--  -->
  <div class="container" *ngIf="!mustShowLoader && !isEditingMode && note">
    <div class="row align-items-center">
      <div class="col-2"></div>
      <div class="col">
        <h5 class="mb-3">{{ note?.title }}</h5>

        <div class="user-compact mb-6">
          <app-avatar [profilePicture]="note?.created_by?.profile_picture"></app-avatar>
          <div class="user-compact-info">
            <p class="title text-truncate">{{ note?.created_by?.getFullName() }}</p>
            <p>{{ note?.created_by?.job_title }}</p>
          </div>
        </div>

        <div [innerHtml]="getEditorDataHtml()"></div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
  <!--  -->
  <!-- View Mode - End -->
  <!--  -->


  <!--  -->
  <!-- attachments - Start -->
  <!--  -->
  <div class="container" *ngIf="!mustShowLoader">
    <div class="row align-items-center">
      <div class="col-2"></div>
      <div class="col">

        <hr/>

        <div class="mt-4">
          <div class="heading-block mb-2">
            <div class="block-wrapper overflow-hidden">
              <h6 class="text-truncate">{{  'pages.default.notes.attachments' | translate }}</h6>
            </div>

            <label for="file-input" class="block-action btn btn-link m-start-auto m-end-0 mb-0 cursor-pointer" id="add-attachment" flow-btn *ngIf="isEditingMode">
              <span [inlineSVG]="'/assets/svg/add-24.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
              <span>{{  'pages.default.notes.add_attachment' | translate }}</span>
              <input id="file-input" type="file" class="invisible position-absolute" accept="{{ extensionList }}" (change)="handleFileInput($event.target.files)" multiple>
            </label>
          </div>

          <!-- attachment -->
          <div class="single-item-block mb-2 attachment" [ngClass]="{
            'block-danger': ['TOO_LARGE', 'VIRUS_DETECTED', 'ERROR', 'INCONSISTENT_MIME_TYPE'].includes(attachment.status)
          }" *ngFor="let attachment of attachments">

          <!-- ['DELETED', 'DELETED_UNSAVED'].includes(attachment.status) => not used for styling anymore -->

            <div class="block-icon attachment-icon">
              <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/file-general-24.svg'"></span>
            </div>

            <div class="block-wrapper">
              <p class="label attachment-name">{{ attachment.meta.file_name}} {{ 'general.at' | translate }} {{ attachment.meta?.created_at | timeZoneDate }} {{ attachment.meta?.created_at | time }}</p>
              <p class="value attachment-info"><span class="text-uppercase">{{ attachment.meta.extension }}</span> {{  'pages.default.notes.file' | translate }}<span *ngIf="attachment.meta.size"> ({{ attachment.meta.size | bytes}})</span></p>
            </div>


            <div class="block-btn-wrapper m-start-auto attachment-done" *ngIf="attachment.status === 'SAFE' || attachment.status === 'PENDING' || attachment.status === 'UNSAVED'">
              <a href="" class="block-action btn btn-lg btn-tertiary btn-icon" *ngIf="isEditingMode" (click)="setStateToDeleted($event, attachment)">
                <span [inlineSVG]="'/assets/svg/delete-24.svg'" class="rtl-mirrored-inline-svg text-danger"></span>
              </a>

              <a href="" class="block-action btn btn-lg btn-tertiary btn-icon" (click)="downloadAttachment($event, attachment)" *ngIf="attachment.status === 'SAFE'">
                <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
              </a>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-processing" *ngIf="attachment.status === 'WAITING'">
              <p class="status-badge in-progress" [tooltip]="'pages.default.notes.added_tooltip' | translate">
                {{ 'pages.default.notes.processing' | translate }}
              </p>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-removed" *ngIf="(attachment.status === 'DELETED' || attachment.status === 'DELETED_UNSAVED')">
              <p class="status-badge badge-sm danger" [tooltip]="'pages.default.notes.removed_tooltip' | translate">
                {{ 'pages.default.notes.file_removed' | translate }}
              </p>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-exceeds" *ngIf="(attachment.status === 'TOO_LARGE')">
              <p class="m-0 text-danger small">{{ 'pages.default.notes.size_exceeds' | translate }}</p>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-unsafe" *ngIf="(attachment.status === 'VIRUS_DETECTED')">
              <p class="m-0 text-danger small">{{ 'pages.default.notes.upload_failed_file_unsafe' | translate }}</p>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-error" *ngIf="(attachment.status === 'ERROR')">
              <p class="m-0 text-danger small">{{ 'pages.default.notes.upload_failed' | translate }}</p>
            </div>

            <div class="block-wrapper no-flex m-start-auto attachment-incosistent-mime-type" *ngIf="(attachment.status === 'INCONSISTENT_MIME_TYPE')">
              <p class="m-0 text-danger small">{{ 'pages.default.notes.upload_failed_inconistent_mime_type' | translate }}</p>
            </div>
          </div>

        </div>
        <label class="text-muted small attachment-allowed-formats">{{ 'pages.default.notes.allowed_formats' | translate }} <br/> {{ extensionList }}</label>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <!--  -->
  <!-- attachments - End -->
  <!--  -->
</div>
