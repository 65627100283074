import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MedicalTeam } from '../../models/medical-team';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { MdtService } from '../../services/mdt.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../services/error.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-add-mdt-modal',
  templateUrl: './add-mdt-modal.component.html',
  styleUrls: ['./add-mdt-modal.component.scss']
})
export class AddMdtModalComponent implements OnInit {
  @Output() mdtCreatedEvent = new EventEmitter<boolean>();

  mdt = new MedicalTeam();
  hcpSearchResults: HealthCareProfessional[] = [];
  selectedHcp: HealthCareProfessional;

  form: UntypedFormGroup;
  validationVisible = false;
  isSaving = false;

  searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();
  isLoadingHcps = false;

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty || this.hcpsAreValid();
  }

  constructor(
    public readonly bsModalRef: BsModalRef,
    public readonly hcpService: HcpService,
    public readonly mdtService: MdtService,
    public readonly formBuilder: UntypedFormBuilder,
    public readonly toastService: ToastrService,
    public readonly errorService: ErrorService,
    public readonly translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.formSetup();

    // this.searchHcpList(); // fetch all hcps

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      name: [this.mdt.name, [Validators.required]]
    });
  }

  searchHcpList(searchTerm?): void {
    if (!searchTerm) {
      this.hcpSearchResults = [];
    }

    this.isLoadingHcps = true;
    this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc', 0, 50).subscribe(
      {
        next: response => {
          this.hcpSearchResults = response.items;
        }, complete: () => this.isLoadingHcps = false
      }
    );
  }

  onAddHcp(hcp: any): void {
    if (hcp) {
      const index = this.mdt.hcps.indexOf(hcp);
      if (index < 0) {
        this.mdt.hcps.push(hcp);
      }

      setTimeout(() => {
        this.selectedHcp = null;
      }, 0);
    }
  }

  onRemoveHcp(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const index = this.mdt.hcps.indexOf(hcp);
    if (index >= 0) {
      this.mdt.hcps.splice(index, 1);
    }

    setTimeout(() => {
      this.selectedHcp = null;
    });
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    const hcpUids = this.mdt.hcps.map(a => {
      return a.uid;
    });

    return hcpUids.filter(uid => {
      return uid === hcp.uid;
    }).length > 0;
  }

  hcpsAreValid(): boolean {
    return this.mdt?.hcps?.length > 0;
  }

  onHandleSubmit(): void {
    if (!this.isSaving) {
      if (!this.form.valid || !this.hcpsAreValid()) {
        this.validationVisible = true;
      } else {
        this.mdt.name = this.form.get('name').value;

        this.isSaving = true;
        this.validationVisible = false;

        this.mdtService.create(this.mdt).subscribe(() => {
          this.isSaving = false;
          this.mdtCreatedEvent.emit(true);
          this.showSuccessToast();
          this._closeAnyway = true;
          this.onHandleClose();
        }, error => {
          this.onRequestError(error);
        });
      }
    }
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  showSuccessToast(): void {
    this.toastService.success(this.translate.instant('modals.mdt.add_mdt_hcps_success_notification'));
  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }

  }


  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
