import { Injectable, RendererFactory2, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService extends BsModalService {
  confirmPromise?: Promise<void>;

  constructor(
    public translateService: TranslateService,
    rendererFactory: RendererFactory2,
    componentLoaderFactory: ComponentLoaderFactory,
    modalOptions: ModalOptions,

  ) {
    super(rendererFactory, componentLoaderFactory, modalOptions);
  }

  //
  // Intercepts onHide and shows confirmationModal.
  // If confirmationModal should popup depending on modal/form state,
  // use 'showModalInterceptor' in given modal.
  //
  showWithInterceptor(
    modal: TemplateRef<any> | string | (new (...args: any[]) => any),
    modalOptions: ModalOptions,
    confirmTemplateParams: ModalOptions = GeneralService.BsModalOptions({
      class: 'modal-dialog-centered',
      initialState: {
        title: 'modals.general.confirm.title',
        description: 'modals.general.confirm.description',
        yes: 'modals.general.confirm.confirm',
        no: 'modals.general.confirm.cancel'
      }
    })
  ): BsModalRef {
    let confirmResolve: () => void;
    let bsModalRef = new BsModalRef();

    const closeInterceptor = () => {
      this.confirmPromise = new Promise((resolve) => {
        confirmResolve = resolve;
      });

      if (
        bsModalRef.content.showModalInterceptor === true ||
        bsModalRef.content.showModalInterceptor === undefined
      ) {
        const confirmModalRef = this.show(ConfirmModalComponent, confirmTemplateParams);

        confirmModalRef.content.onChoice.subscribe(value => {
          if (value) {
            confirmResolve();
            confirmModalRef?.hide();
          } else {
            confirmModalRef?.hide();
          }
        });
      } else {
        confirmResolve();
      }

      return this.confirmPromise;
    };

    bsModalRef = this.show(modal, { closeInterceptor, ...modalOptions });
    return bsModalRef;
  }
}
