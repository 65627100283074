import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NotesService } from '../../services/notes.service';

@Component({
  selector: 'app-delete-note-modal',
  templateUrl: './delete-note-modal.component.html'
})
export class DeleteNoteModalComponent implements OnInit {

  @Output() noteDeleted: EventEmitter<any> = new EventEmitter();


  public noteUid;
  public patientUid;
  public hospitalUid;
  public hcpUid;
  public patientPathwayUid;
  public hasCcRole;


  public form: UntypedFormGroup;

  isRequesting =  false;
  isLoading = false;
  validationVisible = false;

  reasonOptions = [
    { value: 'OBSOLETE', translationKey: 'modals.delete_note.reason.obsolete'},
    { value: 'CREATED_BY_MISTAKE', translationKey: 'modals.delete_note.reason.created_by_mistake'}
  ];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public notesService: NotesService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      reason: ['', [Validators.required]]
    });
  }

  handleSubmit() {
    if (this.form.get('reason').errors) {
      this.validationVisible = true;
      return;
    }

    this.handleDelete();
  }

  handleDelete() {
    this.isLoading = true;
    const reason = this.form.get('reason').value;
    const notesObservable = this.hasCcRole
        ? this.notesService.deleteNoteByCc(this.hospitalUid, this.patientUid, this.patientPathwayUid, this.noteUid, reason)
        : this.notesService.deleteNoteByHcp(this.hcpUid, this.patientUid, this.patientPathwayUid, this.noteUid, reason);

    notesObservable.subscribe(result => {
      this.onUpdateSuccess();
    }, error => {
      this.onRequestError(error);
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  onUpdateSuccess() {
    this.isLoading = false;
    this.toastrService.success(this.translate.instant('modals.delete_note.successfully_deleted'));

    this.noteDeleted.emit();
    this.bsModalRef.hide();
  }

  onRequestError(error) {
    this.isLoading = false;
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'));
  }
}
