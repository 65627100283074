<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">

    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.notes.notes' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <a href="" class="btn btn-primary" id="admin-hcps-add-hcp"
            (click)="createNewNote($event)">{{ 'pages.default.notes.add_note' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="body-content">
  <div class="container pb-4">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="hcpNameSearch"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('pages.default.notes.hcp' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="hcps"
              [searchFn]="customSearchFn"
              [bindValue]="'last_name'"
              (open)="getHCPs()"
              [loading]="isLoadingHCPs"
              [(ngModel)]="filters.hcp_name"

              (ngModelChange)="onFilterChange()"
              (search)="hcpNameSearch.next($event)"

              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-hcps-reset-filters" *ngIf="filtersAreSet" (click)="resetFilters()">{{ 'action.reset' | translate }}</button>
        </div>

      </div>

      <div class="col col-auto">
        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="dropdown-btn-sort"
              [label]="'general.sort_by' | translate"
              [selectClass]="'ng-select-sm'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="sortingOptions"
              [bindValue]="'value'"
              [(ngModel)]="sorting"

              (ngModelChange)="onChangeSort()"

              [asFilter]="false"
            ></app-select>
          </div>
        </div>

        <!-- <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
          <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
            <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

            <span *ngIf="sorting == 'changed_at,asc'">{{ 'form.labels.date_edited_sort_asc' | translate }}&#x200E;</span>
            <span *ngIf="sorting == 'changed_at,desc'">{{ 'form.labels.date_edited_sort_desc' | translate }}&#x200E;</span>

            <span [inlineSVG]="'/assets/svg/chevron-down-24.svg'" class="icon"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
            <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'changed_at', 'asc')">{{ 'form.labels.date_edited_sort_asc' | translate }}&#x200E; </a></li>
            <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'changed_at', 'desc')">{{ 'form.labels.date_edited_sort_desc' | translate }}&#x200E; </a></li>
          </ul>
        </div> -->

      </div>
    </div>
  </div>

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="notes?.length === 0">
      <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.notes.no_notes_yet' | translate }}</h6>
    </div>

    <div class="row">
      <div class="col-6 mb-2" *ngFor="let note of notes">
        <div class="card h-100">

          <div class="card-header">
            <p class="caption">{{ note?.changed_at | timeZoneDate}}</p>
            <a href="" class="e2e-title" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/notes/' + note?.uid + '/view']" [queryParams]="{'back-to': 'patient-detail'}">{{ note?.title }}</a>
          </div>

          <div class="card-body">
            <div class="user-compact mb-3">
              <app-avatar [profilePicture]="note?.created_by?.profile_picture"></app-avatar>

              <div class="user-compact-info">
                <p class="title text-truncate">{{ note?.created_by?.getFullName() }}</p>
                <p>{{ note?.created_by?.job_title }}</p>
              </div>
            </div>

            <p class="m-0">{{ note?.note_preview }}</p>
          </div>
          <div class="card-footer">
            <div class="m-end-auto"></div>

            @if(note?.created_by?.uid === currentHcpUid) {
              <div class="dropdown m-end-2" dropdown placement="top right">

                <button id="button_note_dropdown" dropdownToggle type="button" class="dropdown-toggle btn btn-tertiary btn-icon btn-sm" aria-controls="note_dropdown">
                  <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
                </button>

                <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_note_dropdown">
                  <li role="menuitem">
                    <a class="dropdown-item" href="" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/notes/' + note?.uid + '/edit']" [queryParams]="{'back-to': 'patient-detail'}">{{ 'pages.default.patient_detail.edit_note' | translate }}</a>
                    <a class="dropdown-item text-danger" href="" (click)="deleteNote($event, note?.uid)">{{ 'pages.default.patient_detail.delete_note' | translate }}</a>
                  </li>
                </ul>
              </div>
            }

            <a href="" class="btn btn-tertiary btn-icon" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/notes/' + note?.uid + '/view']" [queryParams]="{'back-to': 'patient-detail'}">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
