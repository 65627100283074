<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!isLoading"></app-breadcrumb>
    </div>

    <div class="container my-auto">
      <h5>{{ 'pages.default.timeline.timeline' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="timeline-vertical">

      <ng-container *ngFor="let phase of phases; last as last; first as first; index as index">

        <!-- TODAY MARKER -->
        @if (printToday(index)) {
          <div class="timeline-item-wrapper heading today-marker">
            <div class="tl-day">
              <!--              <div class="tl-bullet"></div>-->
              <p class="large mb-0 fw-semibold">
                {{ 'pages.default.timeline.today' | translate }}, {{ today | date:dateFormat.format }}
              </p>
            </div>
          </div>
        }
        <!-- /TODAY MARKER -->

        @if (printUpcoming(index)) {
          @if (futureAppointments?.length > 0) {

            <!-- UPCOMING APPOINTMENTS MARKER -->
            <div class="timeline-item-wrapper muted upcoming-appointment-marker">
              <div class="tl-title">
              <span class="icon sm rtl-mirrored-inline-svg m-end-2"
                    [inlineSVG]="'/assets/svg/calendar-outline-24.svg'"></span>
                <h6 class="h7">{{ 'pages.default.timeline.upcoming_appointments' | translate }}</h6>
              </div>
            </div>

            <!-- UPCOMING APPOINTMENTS -->
            <div id="future-appointment-{{appointmentIndex}}" class="timeline-item-wrapper muted appointment"
                  *ngFor="let appointment of futureAppointments; let appointmentIndex = index"
                  [ngClass]="{'pb-0': last}">
              <div class="single-item-block">
                <div class="block-icon">
                  <span class="icon" [inlineSVG]="'/assets/svg/calendar-outline-24.svg'"></span>
                </div>
                <div class="block-wrapper">
                  <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format }}</p>

                  <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                  <p class="label"
                      *ngIf="!appointment?.title">{{ appointment?.translationKey | translate| evalTranslation }}
                  </p>

                </div>
              </div>
            </div>
            <!-- /UPCOMING APPOINTMENTS -->
          }

          <!-- UPCOMING MARKER -->
          @if (!phase.isDummy) {
            <div class="timeline-item-wrapper muted upcoming-marker">
              <div class="tl-title">
                <span class="icon sm rtl-mirrored-inline-svg m-end-2"
                      [inlineSVG]="'/assets/svg/reload-24.svg'"></span>
                <h6 class="h7">{{ 'pages.default.timeline.upcoming' | translate }}</h6>
              </div>
            </div>
          }
          <!-- /UPCOMING MARKER -->
        }

        <!-- PHASE  -->
        @if (!phase.isSubPhase && !phase.isDummy) {
          <div class="timeline-item-wrapper heading phase"
                [ngClass]="{muted: phase.inTheFuture}"
                id="phase-{{phase?.id}}">

            <!-- force pt-4 on the item that markes the treatment as stopped, which is the very very last item -->
            <div class="tl-title-block">
              <div class="tl-bullet"></div>
              <h6>{{ phase?.translationKey | translate | evalTranslation }}</h6>
            </div>
          </div>
        }
        <!-- /PHASE  -->

        <!-- SUBPHASE subphases are never shown in future -->
        @if (phase.isSubPhase && !phase.inTheFuture && phase.phase_visible) {
          <div id="sub-phase-{{phase?.id}}" class="timeline-item-wrapper sub-phase"
                [ngClass]="{muted: phase.inTheFuture, 'pb-0': phase.isDummy}">
            <div class="tl-sub-phase-card">
              <div class="tl-bullet"></div>
              <div class="card">
                <div class="card-body">
                  <p class="small date mb-0">{{ phase?.started_at | timeZoneDate:dateFormat.format }}</p>
                  <h6 class="h7 mb-0">{{ phase?.translationKey | translate | evalTranslation }}</h6>
                </div>
              </div>
            </div>
          </div>
        }
        <!-- /SUBPHASE -->

        @if (!phase.inTheFuture) {

          <!-- PASSED APPOINTMENTS -->
          <div class="timeline-item-wrapper appointment"
                *ngFor="let appointment of phase?.appointments; let appointmentIndex = index">
            <div class="single-item-block" id="appointment-{{appointmentIndex}}">
              <div class="block-icon">
                <span class="icon" [inlineSVG]="'/assets/svg/calendar-outline-24.svg'"></span>
              </div>

              <div class="block-wrapper">
                <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format }}</p>

                <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                <p class="label"
                    *ngIf="!appointment?.title">{{ appointment?.translationKey | translate | evalTranslation }}
                </p>
              </div>
            </div>
          </div>
          <!-- PASSED APPOINTMENTS -->
        }

      </ng-container>

    </div>

    <!-- Spacer - do not remove -->
    <div class="pb-4"></div>
    <!-- Spacer - do not remove -->

  </div>
</div>
