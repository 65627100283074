import { Injectable, Output, EventEmitter } from '@angular/core';
import { CodeService } from './code.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginService } from './login.service';
import { CreatingAccountModalComponent } from '../modals/creating-account-modal/creating-account-modal.component';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { OnboardingLoadingModalComponent } from '../modals/onboarding-loading-modal/onboarding-loading-modal.component';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { GeneralService } from './general.service';
import { LanguageService } from './language.service';
import { HcpService } from './hcp.service';
import { HealthCareProfessional } from '../models/health-care-professional';
import { ScopeService } from './scope.service';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { OnboardingFlow } from '../enums/onboarding-flow';

@Injectable({
  providedIn: 'root'
})
export class HomeFlowService {
  public static SupportPortal = 'support_portal';
  public static SupportApp = 'support_app';
  public static SupportAll = 'support_all';

  public static OnMobile = 'on_mobile';
  public static OnWeb = 'on_web';

  public static FlowOnboarding = OnboardingFlow.ONBOARDING;
  public static FlowForgotPassword = OnboardingFlow.FORGOT_PASSWORD;
  public static FlowLogin = OnboardingFlow.LOGIN;

  @Output() onSupportChanged: EventEmitter<string> = new EventEmitter();



  public lastRole: string;
  public onboardingModal: BsModalRef;

  constructor(
    public codeService: CodeService,
    public loginService: LoginService,
    public router: Router,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public hcpService: HcpService,
    public userService: UserService,
    public languageService: LanguageService,
    public dataService: DataService,
    public modalService: BsModalService,
    public scopeService: ScopeService,
    public apiService: ApiService
  ) {}

  initialize() {
    const flow = this.getQueryParam('flow', true).toUpperCase();

    this.syncLanguageByUrl();

    this.evaluateDevice().subscribe(result => {
      if (result === HomeFlowService.OnMobile) {
        this.onSupportChanged.emit(HomeFlowService.SupportApp);
      } else if (result === HomeFlowService.OnWeb) {
        this.onSupportChanged.emit(HomeFlowService.SupportPortal);
        if (flow === HomeFlowService.FlowOnboarding) {
          this.startOnboarding();
        } else if (flow === HomeFlowService.FlowForgotPassword) {
          const code = this.getQueryParam('code', true);
          this.syncLanguageByUrl();
          this.router.navigateByUrl(`password-reset?code=${code}`);
        }
      }
    });
  }

  isFlowUrl(): boolean {
    return !!this.getQueryParam('flow', false).toUpperCase();
  }

  getSidebarTitleKey() {
    const flow = this.getQueryParam('flow', false).toUpperCase();
    if(flow && (flow === HomeFlowService.FlowOnboarding || flow === HomeFlowService.FlowLogin)) {
      return 'action.create_acc';
    }
  }

  syncLanguageByUrl() {
    const lang = this.getQueryParam('language').toUpperCase();

    if (lang) {
      setTimeout(() => {
        this.languageService.setCurrentLanguageByEnum(lang);
      });
    }
  }

  getQueryParam(param: string, required = false): string {
    const queryParams:Params = this.route.snapshot.queryParams;
    if (queryParams[param] || !required) {
      return queryParams[param] || '' as string;
    } else {
      this.escape();
      return '';
    }
  }


  evaluateDevice() {
    return new Observable((observer) => {
      setTimeout(() => {
        if (navigator.userAgent) {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
            observer.next(HomeFlowService.OnMobile);
            observer.complete();
          } else {
            observer.next(HomeFlowService.OnWeb);
            observer.complete();
          }
        }
      });
    });
  }

  evaluateClientsInParams() {
    const clients:string = this.getQueryParam('clients');
    let clientsArray: string[];

    if(clients) {
      clientsArray = clients.split(',');

      if(clientsArray.includes('portal') && clientsArray.includes('app')) {
        this.onSupportChanged.emit(HomeFlowService.SupportAll);
      } else if(clientsArray.includes('app')) {
        this.onSupportChanged.emit(HomeFlowService.SupportApp);
      } else if(clientsArray.includes('portal')) {
        this.onSupportChanged.emit(HomeFlowService.SupportPortal);
      }
    }
  }

  redirectToStore() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    const isIphone = ua.indexOf('iphone') > -1;

    if (isIphone) {
      window.location.href = environment.appStore;
    } else if (isAndroid) {
      window.location.href = environment.playStore;
    }
  }

  startOnboarding() {
    const clients:string = this.getQueryParam('clients');

    if (!clients || (clients && clients.includes('portal'))) {
      this.validateCode();
    } else {
      this.escape();
    }
  }

  validateCode() {
    const code = this.getQueryParam('code', true);

    this.onboardingModal = this.modalService.show(OnboardingLoadingModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-backdrop-light',
        ignoreBackdropClick: true,
        keyboard: false
      })
    );

    setTimeout(() => {
      this.loginService.loginWithCode(code).subscribe(
        () => this.onLoginWithCodeSuccess(),
        error => this.onCodeValidationError(error)
      );
    }, 1500);
  }

  onCodeValidationError(error) {
    this.onboardingModal.hide();

    if(error.status === 400) {
      this.router.navigateByUrl('link-invalid');
    } else {
      this.router.navigateByUrl(`error`);
    }
  }

  onLoginWithCodeSuccess() {
    this.syncLanguageByUrl();
    this.onboardingModal.hide();

    //
    // Do NOT redirect to language selection, as per discisson with SA's and BA's
    //
    // this.goOnboardingLanguageSelection();

    //
    // But, for MVP, just continue to the next onboarding screen
    //
    this.goNextStepOrDone().subscribe();
  }

  // goOnboardingLanguageSelection() {
  //   const queryParams:any = Object.assign({}, this.route.snapshot.queryParams);
  //   this.router.navigate(['/onboarding/language'], {
  //     queryParams: queryParams
  //   });
  // }

  goNextStepOrDone(overrideFlow?: string): Observable<any> {
    const queryParams:any = Object.assign({}, this.route.snapshot.queryParams);
    let flow = this.getQueryParam('flow').toUpperCase();

    if(overrideFlow) {
      flow = overrideFlow.toUpperCase();
      queryParams.flow = flow;
    }


    if(flow === HomeFlowService.FlowOnboarding && !this.authenticationService.authentication) {
      this.escape();
      return new Observable(observer => {
        observer.error();
      });
    }


    const role: string = this.authenticationService.getCurrentFirstRole();
    const nextRoute: string = this.getNextRouteByRole(flow);

    if(queryParams.support) {
      delete queryParams.support;
    }

    if(nextRoute) {
      return new Observable(observer => {
        this.router.navigate([nextRoute], {
          queryParams: queryParams
        });
        observer.next(null);
        observer.complete();
        this.lastRole = role;
      });
    } else {
      return this.completeFlow(queryParams);
    }
  }

  completeFlow(queryParams): Observable<any> {
    const role: string = this.authenticationService.getCurrentFirstRole();
    const flow = this.getQueryParam('flow').toUpperCase();

    return new Observable(observer => {
      if(flow === HomeFlowService.FlowOnboarding) {

        this.getFirstHcpByPermissions().subscribe(hcp => {
          if(hcp.reviewed) {
            if(AuthenticationService.isFinalRole(this.lastRole)) {
              this.simulateAccountCreation();
              observer.next(null);
              observer.complete();
            } else {
              this.finaliseFlow().subscribe(() => {
                observer.next(null);
                observer.complete();
              });
            }
          } else {
            this.goOnboardingDetails(queryParams);
            observer.next(null);
            observer.complete();
          }
          this.lastRole = role;
        });
      } else {
        this.getFirstHcpByPermissions().subscribe(hcp => {
          if(hcp.reviewed) {
            this.lastRole = role;
            this.finaliseFlow().subscribe(() => {
              observer.next(null);
              observer.complete();
            });
          } else {
            this.goOnboardingDetails(queryParams);
          }
        });
      }
    });
  }

  getNextRouteByRole(flow: string) {
    let role: string = this.authenticationService.getCurrentFirstRole();
    let nextRoute = '';

    if(role) {
      role = role.toUpperCase();



      if(role === AuthenticationService.RoleMfaRequired) {
        nextRoute = '/security-code';
      }

      if(role === AuthenticationService.RoleConsentRequired) {
        nextRoute = '/onboarding/consent';
      }

      if(role === AuthenticationService.RolePwChangeRequired) {
        if(flow === HomeFlowService.FlowOnboarding) {
          nextRoute = '/onboarding/password'
        } else if(flow === HomeFlowService.FlowLogin) {
          nextRoute = '/password-expired'
        } else {
          this.escape();
        }
      }
    }

    return nextRoute;
  }

  simulateAccountCreation() {
    const modal = this.modalService.show(CreatingAccountModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-backdrop-light',
        ignoreBackdropClick: true,
        keyboard: false
      })
    );

    setTimeout(() => {
      this.finaliseFlow().subscribe(() => {
        modal.hide();
      });
    },1500);
  }

  getFirstHcpByPermissions(): Observable<HealthCareProfessional> {
    return new Observable(observer => {
      this.userService.getPermissions().subscribe(data => {
        if(!data.hospitals || !data.hospitals[0].hcp) {
          observer.error();
          return;
        }

        const hospitalCompact = data.hospitals[0];
        const hcpCompact = hospitalCompact.hcp;

        this.hcpService.get(hcpCompact.uid).subscribe(hcp => {
          observer.next(hcp);
          observer.complete();
        });
      });
    });
  }

  goOnboardingDetails(queryParams) {
    this.router.navigate(['onboarding/details'], {
      queryParams: queryParams
    });
  }

  fetchRequiredContexts() {
    return new Observable(observer => {
      this.scopeService.initializeScope().subscribe(() => {
        this.userService.getProfile().subscribe(() => {
          this.languageService.reloadLanguage().subscribe(() => {
            observer.next();
            observer.complete();
          }, () => {
            observer.error();
          });
        }, () => {
          observer.error();
        });
      }, () => {
        observer.error();
      });
    });
  }

  finaliseFlow(): Observable<any> {
    return new Observable(observer => {
      this.fetchRequiredContexts().subscribe(() => {
        this.navigateAfterFinalisation();
        observer.next(null);
        observer.complete();
      });
    });
  }

  navigateAfterFinalisation() {
    const params = this.route.snapshot.queryParams;
    if(params && params.returnUrl) {
      this.router.navigateByUrl(params.returnUrl);
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  escape() {
    this.router.navigateByUrl('/');
    return null;
  }

  getStoreIconUrl(store: 'android' | 'ios'): Observable<any> {
    return new Observable(observer => {
      let id: string;

      if (store === 'android') {
        id = 'bltbe0ad791c70dfbd7';
      } else if (store === 'ios') {
        id = 'bltd945a45ea1ff10a7';
      }

      if (id) {
        const url = environment.cmsUrl + `/v3/content_types/hcp_web_images/entries/${id}`;
        const locale = this.languageService.getCurrentLanguage().locale;

        this.apiService.cmsGet(url, locale, null, null, true).subscribe(result => {
          observer.next(result.entry?.image?.url);
          observer.complete();
        });
      } else {
        observer.error();
        observer.complete();
      }
    });
  }
}
