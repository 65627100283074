<div class="modal-header">
  <h6 class="h7">
    @if(formSet) {
      {{ 'modals.surgery_date.title.set' | translate}}
    }
    @else {
      {{ 'modals.surgery_date.title.edit' | translate}}
    }
  </h6>

  <button type="button" id="surgery-date-modal-close" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <label for="surgeryDate" class="required">{{ 'modals.surgery_date.form.date' | translate }}</label>
      <input type="text" id="surgeryDate" class="form-control w-100" formControlName="surgeryDate" required bsDatepicker>
      <app-feedback-field [field]="form.get('surgeryDate')"/>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary m-end-2" id="surgery-date-modal-handle-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary" id="surgery-date-modal-handle-edit" [ngClass]="{'loader': isSaving}" (click)="handleSave()" [disabled]="!formInitialized">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
