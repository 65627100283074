import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { CareModule } from "../../models/care-module";
import { HealthCareProfessional } from "../../models/health-care-professional";
import { Pathway } from "../../models/pathway";
import { Patient } from "../../models/patient";
import { HcpService } from "../../services/hcp.service";
import { PatientService } from "../../services/patient.service";

@Component({
  selector: 'app-cm-cl-modal',
  templateUrl: './edit-cm-cl-modal.component.html'
})
export class EditCmClModalComponent implements OnInit {
  @Output() assignmentSuccessEvent = new EventEmitter<any>();
  @Output() assignmentFailedEvent = new EventEmitter<any>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public hcpList: Array<HealthCareProfessional> = [];
  public type: 'CL' | 'CM' = 'CL';
  public isSaving: boolean;
  public patients: Array<Patient> =  [];
  public careModule: CareModule;
  public placeholder: string;

  constructor(
    private bsModalRef: BsModalRef,
    private hcpService: HcpService,
    private readonly formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private patientService: PatientService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.formSetup();
    this.placeholder = (this.type === 'CL') ? this.translate.instant('modals.edit_cl_cm.select_cl') : this.translate.instant('modals.edit_cl_cm.select_cm');
    this.searchHcpList();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      hcp: ['', [Validators.required]]
    });
  }

  searchHcpList(event?) {
    let term = '';
    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.hcpList = [];
    }

    this.hcpService.getPaged({hcpLastName: term, status: 'ACTIVE'}).subscribe(response => {
      this.hcpList = response.items;
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const params = {};
    const hcpUid = this.form.get('hcp').value;
    const hcp = this.hcpList.find((hcp:HealthCareProfessional) => { return hcp.uid === hcpUid});
    params['patient_pathway_ids'] = this.getPathwayIds();

    if (this.type === 'CM') {
      params['case_manager_uid'] = hcpUid;
    }

    if (this.type === 'CL') {
      params['clinical_lead_uid'] = hcpUid;
    }

    this.isSaving = true;

    this.patientService.bulkAssignment(
      hospitalUid,
      params
    ).subscribe((result) => {
      this.isSaving = false;

      if (result && result['failed_patients']) {
        this.failedHandler(result['failed_patients'], this.patients ,this.type, hcp);
      } else {
        this.successHandler(hcp, this.patients, this.type);
      }
      this.onHandleClose();
    }, (error) => {
      this.isSaving = false;
      this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'));
    });
  }

  failedHandler(failed, allPatients, type, hcp) {
    let key = '';
    if (type === 'CL') {
      key = (failed.length == 1) ? 'modals.assign_cl_cm.bulk_assign_cl_failed_single' : 'modals.assign_cl_cm.bulk_assign_cl_failed';
    }
    if (type === 'CM') {
      key = (failed.length == 1) ? 'modals.assign_cl_cm.bulk_assign_cm_failed_single' : 'modals.assign_cl_cm.bulk_assign_cm_failed';
    }

    this.toastrService.error(this.translate.instant(key, {value: failed.length}));

    const failedIds: Array<string> = failed.map((patient) => patient.uid);
    const succeeded = allPatients.filter((patient) => {return !failedIds.includes(patient.uid)});

    this.assignmentFailedEvent.emit( { "failed": failed, "succeeded": succeeded, type: type, hcp } );
  }

  successHandler(hcp, patients, type) {
    let key = '';
    if (type === 'CL') {
      key = (patients.length == 1) ? 'modals.assign_cl_cm.bulk_assign_cl_success_single' : 'modals.assign_cl_cm.bulk_assign_cl_success';
    }
    if (type === 'CM') {
      key = (patients.length == 1) ? 'modals.assign_cl_cm.bulk_assign_cm_success_single' : 'modals.assign_cl_cm.bulk_assign_cm_success';
    }

    this.toastrService.success(this.translate.instant(key, {value: patients.length}));

    this.assignmentSuccessEvent.emit( { "patients": patients, "hcp": hcp, type: type } );
  }

  customSearchFn(term: string, item: HealthCareProfessional) {
    term = term.toLowerCase();
    return item.last_name.toLowerCase().indexOf(term) > - 1;
  }

  getPathwayIds() {
    const patient_pathway_ids: string[] = [];

    if (this.careModule) {
      this.patients.forEach((patient: Patient) => {
          const pathway = patient.pathways.find(
            (_pathway: Pathway) => _pathway.care_module.uid ===   this.careModule.uid);
          patient_pathway_ids.push(pathway.uid);
      });
    }
    return patient_pathway_ids;
  }

}
