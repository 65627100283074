import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Patient } from '../../models/patient';
import { ErrorService } from '../../services/error.service';
import { LanguageService } from '../../services/language.service';
import { LocaleService } from '../../services/locale.service';
import { CountryService } from './../../services/country.service';
import { PatientService } from './../../services/patient.service';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { HcpService } from '../../services/hcp.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LanguageCode } from '../../models/language-code';
import moment, { Moment } from 'moment';
import { Country } from '../../models/country';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { GeneralService } from '../../services/general.service';
import { EditOtherEmailModalComponent } from '../edit-other-email-modal/edit-other-email-modal.component';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-edit-patient-modal',
  templateUrl: './edit-patient-modal.component.html'
})
export class EditPatientModalComponent implements OnInit {
  @Output() onPatientUpdated = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public patient: Patient;
  public profilePicturePreview: string;
  public validationVisible: boolean;
  public languageOptions: LanguageCode[];
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public countryOptions: Country[];
  public contactConsented: boolean = true;
  public emrMaxLength = 50;

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty
  }

  constructor(
    public bsModalRef: BsModalRef,
    public errorService: ErrorService,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public languageService: LanguageService,
    public countryService: CountryService,
    public localeService: LocaleService,
    public patientService: PatientService,
    public hcpService: HcpService,
    public toastService: ToastrService,
    private readonly translate: TranslateService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public modalService: BsModalService,
    public authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.getPatientDetails(this.patient.uid);

    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat();
    this.bsDatepickerConfig.adaptivePosition = true;

    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });

    this.countryService.getCountriesFromCms().subscribe(countries => {
      this.countryOptions = countries;
    });
  }

  getPatientDetails(patientUid) {
    this.isLoading = true;

    const patientObservable = this.authService.hasCcRole()
      ? this.patientService.getPatientByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid)
      : this.patientService.getPatientByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid);

    patientObservable.subscribe((patient: Patient) => {
      this.patient = patient;

      this.formSetup();
      this.isLoading = false;
    });
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      first_name: [this.patient?.first_name, [Validators.required]],
      last_name: [this.patient?.last_name, [Validators.required]],
      preferred_name: [this.patient?.preferred_name],
      profile_picture: [this.patient?.profile_picture],
      gender: [this.patient?.gender, [Validators.required]],
      date_of_birth: [this.patient?.getBirthDate(), [Validators.required]],
      language: [this.patient?.language, [Validators.required]],
      country: [this.patient?.country, [Validators.required]],
      email: [this.patient?.email],
      electronic_medical_record: [this.patient.electronic_medical_record],
    });

    this.form.get('email').disable();
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleConfirm() {
    if (this.form.pristine) {
      this.bsModalRef.hide();
      return;
    }

    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.showConfirmModal();
    }
  }

  handleSubmission() {
    this.isSaving = true;
    this.validationVisible = false;

    const clonedPatient: Patient = cloneDeep(this.patient);

    clonedPatient.first_name = this.form.get('first_name').value;
    clonedPatient.last_name = this.form.get('last_name').value;
    clonedPatient.gender = this.form.get('gender').value;
    clonedPatient.profile_picture = this.form.get('profile_picture').value;
    clonedPatient.language = this.form.get('language').value;
    clonedPatient.country = this.form.get('country').value;
    clonedPatient.date_of_birth = this.convertDate(moment(this.form.get('date_of_birth').value));
    clonedPatient.preferred_name = this.form.get('preferred_name').value ? this.form.get('preferred_name').value : undefined;
    clonedPatient.electronic_medical_record = this.form.get('electronic_medical_record').value


    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    this.patientService.updatePatientByHospital(hospital_uid, this.patient.uid, clonedPatient).subscribe(result => {
      this.isSaving = false;
      this.showSuccessToast();

      this._closeAnyway = true;
      this.bsModalRef.hide();
      this.onPatientUpdated.emit(result);
    }, error => {
      this.showErrors(error);
    });
  }

  public showSuccessToast(): void {
    this.toastService.success(this.translate.instant('modals.edit_patient.success_notification'));
  }

  public showErrors(error): void {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  convertDate(date: Moment): string {
    const current_locale = moment().locale();
    const dateString = moment(date).format('YYYY-MM-DD');
    moment().locale(current_locale);
    return dateString;
  }

  isDirty(): boolean {
    return this.form.dirty;
  }

  showConfirmModal(): void {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.confirm_patient_detail_save.title',
          description: 'modals.confirm_patient_detail_save.description',
          yes: 'modals.confirm_patient_detail_save.confirm',
          no: 'modals.confirm_patient_detail_save.cancel'
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      this._closeAnyway = true;
      modalRef.hide();
      this.handleSubmission();
    });
  }

  showEditEmailModal() {
    const initialState = {
      patient: this.patient
    };

    const modalRef = this.modalService.show(EditOtherEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalRef.content.emailChanged.subscribe(() => modalRef.hide());
  }
}
