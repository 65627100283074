<div [formGroup]="form">
  <!-- TYPE Boolean Checkbox -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'BOOLEAN' && !field.required" >
    <div class="form-check m-0">
      <input type="checkbox" class="form-check-input" [id]="field.id" [formControlName]="field.id" (change)="onChange($event)">
      <label class="form-check-label" [for]="field.id">{{ field.translationKey | translate | evalTranslation }}</label>
    </div>

    <app-feedback-field [field]="control"></app-feedback-field>
  </div>

  <!-- TYPE Boolean Radiobutton -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'BOOLEAN' && field.required">
    <label [ngClass]="{'required' : field.required}">{{ field.translationKey | translate | evalTranslation }}</label>

    <div class="form-checks-inline">
      <div class="form-check">
        <input class="form-check-input" [id]="'yes_'+field.id"  type="radio" [formControlName]="field.id" [value]="true" (change)="onChange($event)">
        <label class="form-check-label" [for]="'yes_'+field.id">{{ 'action.yes' | translate }}</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" [id]="'no_'+field.id"  type="radio" [formControlName]="field.id" [value]="false" (change)="onChange($event)">
        <label class="form-check-label" [for]="'no_'+field.id" >{{ 'action.no' | translate }}</label>
      </div>
    </div>
    <app-feedback-field [field]="control" [extraClass]="'general-feedback mt-n2'"></app-feedback-field>
  </div>

  <!-- TYPE Enum -->
  <div class="form-group"  *ngIf="field.field_value.value_type === 'ENUM'">

    <label [ngClass]="{'required' : field.required}">{{ field.translationKey | translate | evalTranslation }}</label>
    <div [ngClass]="field.field_value.enum_values.length > 2 ? 'form-checks mt-1': 'form-checks-inline'">
      <div class="form-check" [ngClass]="{'mb-1': !last}" *ngFor="let enum of field.field_value.enum_values | keyvalue: enumValueAscOrder; last as last" >
        <input class="form-check-input" type="radio" [id]="field.id + enum.key" [formControlName]="field.id" [value]="enum.key" (change)="onChange($event)">
        <label class="form-check-label" [for]="field.id + enum.key" >{{ ('shared.' + enum.value?.region + '.' + enum.value?.key) | translate }}</label>
      </div>
    </div>

    <app-feedback-field [field]="control" [extraClass]="'general-feedback'" class="mt-n2"></app-feedback-field>

    <p class="text-muted small" *ngIf="field.field_value.value_type === 'ENUM' && field.description"><i>{{ field ?.description | translateHelp | translate }}</i></p>
  </div>

  <!-- TYPE Number -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'NUMBER'">
    <label [ngClass]="{'required' : field.required}" [for]="'num-'+field.id">{{ field.translationKey | translate | evalTranslation }}</label>
<!--    TODO: Fix onkeypress-->
    <input placeholder="" class="form-control" [id]="'num-'+field.id"  type="number" [name]="field.id" [formControlName]="field.id" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" (change)="onChange($event)">
    <app-feedback-field [field]="control" [meta]="field"></app-feedback-field>
  </div>


  <!-- TYPE Decimal -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'DECIMAL_NUMBER'">
    <label [ngClass]="{'required' : field.required}" [for]="'field_'+field.id">{{ field.translationKey | translate | evalTranslation }}</label>
    <input placeholder="" class="form-control" [id]="'field_'+field.id"    type="number" [name]="field.id" [formControlName]="field.id" (change)="onChange($event)">
    <app-feedback-field [field]="control" [meta]="field"></app-feedback-field>
  </div>

  <!-- TYPE String -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'STRING'" >
    <label [ngClass]="{'required' : field.required}" [for]="'field_'+field.id">{{ field.translationKey | translate | evalTranslation }}</label>
    <input placeholder="" class="form-control" type="text" [name]="field.id" [id]="'field_'+field.id" [formControlName]="field.id" (change)="onChange($event)">
    <app-feedback-field [field]="control" [meta]="field"></app-feedback-field>
  </div>

  <!-- TYPE Date -->
  <div class="form-group form-group-calendar" *ngIf="field.field_value.value_type === 'DATE'">
    <label [ngClass]="{'required' : field.required, 'invalid': !control?.valid}">{{ field?.translationKey | translate | evalTranslation }}</label>
    <app-date-input [formControlName]="field.id" [name]="field.id" (change)="onChange($event)"></app-date-input>
    <p class="validation-feedback" *ngIf="control?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
  </div>

  <!-- TYPE Date-Time -->
  <div class="form-group" *ngIf="field.field_value.value_type === 'DATE_TIME'">
    <label [ngClass]="{'required' : field.required, 'invalid': !control?.valid}">{{ field?.translationKey | translate | evalTranslation }}</label>
    <app-date-time-input [formControlName]="field.id" [name]="field.id" (change)="onChange($event)"></app-date-time-input>
    <p class="validation-feedback" *ngIf="control?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
  </div>

  <!-- description for  Number,  Boolean, String and Date -->
  <p class="text-muted small mt-1" *ngIf="field.field_value.value_type !== 'ENUM' && field.description"><i>{{ field ?.description | translateHelp | translate }}</i></p>
</div>
