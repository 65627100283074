<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!isLoading"></app-breadcrumb>
    </div>

    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">{{ careModule?.translationKey | translate }}</h5>

        @if (careModule?.isClinical) {
          <p class="status-badge clinical">
            {{ 'pages.default.administration.care_module_detail.clinical' | translate }}
          </p>
        } @else if (careModule?.isEducation) {
          <p class="status-badge educational">
            {{ 'pages.default.administration.care_module_detail.education' | translate }}
          </p>
        }

      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      <div class="heading-block mb-3">
        <h5 class="text-truncate">
          {{ 'pages.default.administration.care_module_detail.hospital_team' | translate }}
        </h5>

        <button class="block-action btn btn-secondary m-start-auto" (click)="editHospitalTeam($event)"
                id="edit-hospital-team">
          <span class="icon" [inlineSVG]="'/assets/svg/edit-24.svg'"></span>
          <span>{{ 'pages.default.administration.care_module_detail.edit_hospital_team' | translate }}</span>
        </button>
      </div>

      <div class="row">
        <div id="default_clinical_lead" class="col-6">
          <p class="large fw-semibold">
            {{ 'pages.default.administration.care_module_detail.default_clinical_lead' | translate }}
          </p>

          @if (!careModule?.default_clinical_lead?.uid) {
            <p class="text-muted">
              {{ 'pages.default.administration.care_module_detail.no_clinical_lead_selected' | translate }}
            </p>
          } @else {
            <div class="participant-item bg-white">
              <app-avatar [profilePicture]="careModule?.default_clinical_lead?.profile_picture"></app-avatar>

              <div class="meta">
                <p class="title">{{ careModule?.default_clinical_lead?.getFullName() }}</p>
                <p class="description">{{ careModule?.default_clinical_lead?.job_title }}</p>
              </div>

              <div class="status-badge badge-sm clinical-lead">
                {{ 'pages.default.patient_detail.clinical_lead' | translate }}
              </div>
            </div>
          }
        </div>

        <div id="default_case_manager" class="col-6">
          <p class="large fw-semibold">
            {{ 'pages.default.administration.care_module_detail.default_case_manager' | translate }}
          </p>

          @if (!careModule?.default_case_manager?.uid) {
            <p class="text-muted">
              {{ 'pages.default.administration.care_module_detail.no_case_manager_selected' | translate }}
            </p>
          } @else {

            <div class="participant-item bg-white">
              <app-avatar [profilePicture]="careModule?.default_case_manager?.profile_picture"></app-avatar>

              <div class="meta">
                <p class="title">{{ careModule?.default_case_manager?.getFullName() }}</p>
                <p class="description">{{ careModule?.default_case_manager?.job_title }}</p>
              </div>

              <div class="status-badge badge-sm case-manager">
                {{ 'pages.default.patient_detail.case_manager' | translate }}
              </div>
            </div>

          }
        </div>
      </div>

      <hr class="my-4">

      <div id="default-mdts" class="mb-4">
        <p class="large fw-semibold">
          {{ 'pages.default.administration.care_module_detail.default_mdts' | translate }}
        </p>

        @for (mdt of careModule?.default_mdts; track mdt.uid; ) {
          <div id="mdt-{{$index}}" class="action-item-block card card-lg mt-2">
            <div class="card-body">
              <h6 class="h7 mb-3">{{ mdt.name }}</h6>

              <div class="row g-1">
                <div class="col-4" *ngFor="let hcp of mdt.hcps">
                  <div class="participant-item">

                    <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

                    <div class="meta">
                      <p class="title">{{ hcp.getFullName() }}</p>
                      <p class="description">{{ hcp.job_title }}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        } @empty {
          <p class="text-muted">
            {{ 'pages.default.administration.care_module_detail.no_mdt_selected' | translate }}
          </p>
        }
      </div>

      <hr class="my-4">

      <div id="default-hcps">
        <p class="large fw-semibold">
          {{ 'pages.default.administration.care_module_detail.default_hcps' | translate }}
        </p>

        <div class="row g-2">
          @for (hcp of careModule?.default_hcps; track hcp.uid) {
            <div class="col-4">
              <div class="participant-item bg-white">

                <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

                <div class="meta">
                  <p class="title">{{ hcp?.getFullName() }}</p>
                  <p class="description">{{ hcp?.job_title }}</p>
                </div>

              </div>
            </div>

          } @empty {
            <div class="col">
              <p>
                {{ 'pages.default.administration.care_module_detail.no_hcps_selected' | translate }}
              </p>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
