import { Invitee } from './invitee';
import { LocationInterface } from '../interfaces/location.interface';
import { PatientInvitee } from './patient-invitee';
import { HealthCareProfessional } from './health-care-professional';
import { TranslationInterface } from '../interfaces/translation.interface';

export enum Status {
  NONE = 'NONE',
  VIEWED = 'VIEWED',
  REMOVED = 'REMOVED'
}
export class Appointment {
  public uid: string;
  public title: string;
  public description: string;
  public description_key: TranslationInterface;
  public type: string;
  public start_date: string;
  public end_date: string;
  public date: string;
  public location: LocationInterface;
  public cancelled: boolean;
  public organised_by_user: boolean;
  public organiser: HealthCareProfessional;
  public invitees: Invitee[];
  public status_of_user: string;
  public patient_invitee: PatientInvitee;
  public changed_by: HealthCareProfessional;
  public patient_pathway_id: string;
  public created_for_phase_id: string;
  public cancel_by_user_disabled: boolean;
  public title_key: TranslationInterface;

  public translationKey: string;
  public translationDescriptionKey: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.title = item.title ? item.title : '';
    this.description = item.description ? item.description : '';
    this.description_key = item.description_key ? item.description_key : null;
    this.type = item.type ? item.type : '';
    this.start_date = item.start_date ? item.start_date : null;
    this.end_date = item.end_date ? item.end_date : null;
    this.date = item.date ? item.date : null;
    this.location = item.location ? item.location : null;
    this.cancelled = item.cancelled ? item.cancelled : null;
    this.organised_by_user = item.organised_by_user ? item.organised_by_user : null;
    this.organiser = item.organiser ? new HealthCareProfessional(item.organiser) : null;
    this.status_of_user = item.status_of_user ? item.status_of_user : null;
    this.changed_by = item.changed_by ? new HealthCareProfessional(item.changed_by) : null;
    this.patient_pathway_id = item.patient_pathway_id ? item.patient_pathway_id : null;
    this.created_for_phase_id = item.created_for_phase_id ? item.created_for_phase_id : null;
    this.cancel_by_user_disabled = item.cancel_by_user_disabled;
    this.title_key = item.title_key;

    this.translationKey = `shared.${this.title_key?.region}.${this.title_key?.key}`;
    this.translationDescriptionKey = `shared.${this.description_key?.region}.${this.description_key?.key}`;

    this.fillInvitees(item.invitees);
  }

  merge(appointment: Appointment) {
    this.title = appointment.title;
    this.description = appointment.description;
    this.start_date = appointment.start_date;
    this.end_date = appointment.end_date;
    this.date = appointment.date;
    this.location = appointment.location;
    this.cancelled = appointment.cancelled;
    this.organised_by_user = appointment.organised_by_user;
    this.organiser = new HealthCareProfessional(appointment.organiser);
    this.status_of_user = appointment.status_of_user;
    this.changed_by = new HealthCareProfessional(appointment.changed_by);

    this.translationKey = appointment.translationKey;

    this.fillInvitees(appointment.invitees);
  }

  fillInvitees(list) {
    this.invitees = [];

    if (!list || !list.length) {
      return;
    }

    for (const item of list) {
      if (item.type === 'HCP') {
        this.invitees.push(new Invitee(item));
      } else {
        this.patient_invitee = new PatientInvitee(item);
      }
    }
  }

  hasPendingState() {
    if (this.cancelled) {
      return false;
    }

    return this.status_of_user === 'PENDING';
  }

  hasAcceptedState() {
    if (this.cancelled || !this.status_of_user) {
      return false;
    }

    return this.status_of_user === 'ACCEPTED';
  }

  hasCancelledState() {
    if (this.status_of_user === 'DECLINED') {
      return true;
    }
    return !!this.cancelled;
  }

  hasRemovedState() {
    return (this.cancelled && this.organised_by_user);
  }

  hasViewedState(): boolean {
    return this.status_of_user === Status.VIEWED;
  }

  hasNoneState(): boolean {
    return this.status_of_user === Status.NONE;
  }

}
