import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ConversationService} from "../../services/conversation.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Conversation} from "../../models/conversation";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-edit-conversation-subject-modal',
  templateUrl: './edit-conversation-subject-modal.component.html',
  styleUrls: ['./edit-conversation-subject-modal.component.scss']
})
export class EditConversationSubjectModalComponent implements OnInit {
  public conversation: Conversation;

  public form: UntypedFormGroup;
  public validationVisible = false;
  public isSaving = false;
  public subjectMaxLength = 48;

  @Output() public subjectEditedEvent = new EventEmitter<any>();

  constructor(
    public bsModalRef: BsModalRef,
    public conversationService: ConversationService,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      subject: [this.conversation?.subject, [Validators.required, Validators.maxLength(this.subjectMaxLength)]]
    });
  }

  handleCancel() {
    this.bsModalRef.hide();
  }

  handleSubmit() {
    if (!this.isSaving) {
      if (!this.form.valid) {
        this.validationVisible = true;
      } else {
        this.validationVisible = false;
        this.isSaving = true;

        this.conversationService.editConversationSubject(this.conversation.uid, this.conversation.conversation_type, this.form.get('subject').value).subscribe(
          result => this.onEditSuccess(),
          error => this.onEditError()
        );
      }
    }
  }

  onEditSuccess() {
    this.isSaving = false;
    this.conversation.subject = this.form.value.subject;
    this.conversation.latest_message_type = 'CHANGED_SUBJECT';
    this.subjectEditedEvent.emit(this.conversation);
    this.bsModalRef.hide();
  }

  onEditError() {
    this.isSaving = false;
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'));
    this.bsModalRef.hide();
  }
}
