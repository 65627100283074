<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_hospital_team_modal.edit_default_hospital_team' | translate }}</h6>

  <button type="button" class="btn-close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close"
          (click)="onHandleClose()">
  </button>
</div>

<div class="modal-body">
  @if (isLoading) {
    <app-page-loader></app-page-loader>
  } @else {
    <div class="form-group">
      <label for="care-module">{{ 'modals.edit_hospital_team_modal.care_module' | translate }}</label>
      <input type="text" id="care-module" class="form-control w-100" placeholder="" disabled
             [value]="careModule?.translationKey | translate">
    </div>

    <hr/>

    <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">

      <!-- default_clinical_lead -->
      <div class="form-group">
        <app-select [placeholder]="('modals.edit_hospital_team_modal.default_clinical_lead' | translate)"
                    [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
                    [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
                    formControlName="default_clinical_lead" [asFilter]="false" [showCheckboxes]="false"
                    [showUserDetails]="true">
        </app-select>
        <app-feedback-field [field]="form.get('default_clinical_lead')"></app-feedback-field>
      </div>

      <!-- default_case_manager -->
      <div class="form-group">
        <app-select [placeholder]="('modals.edit_hospital_team_modal.default_case_manager' | translate)"
                    [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
                    [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
                    formControlName="default_case_manager" [asFilter]="false" [showCheckboxes]="false"
                    [showUserDetails]="true">
        </app-select>
        <app-feedback-field [field]="form.get('default_case_manager')"></app-feedback-field>
      </div>
    </form>

    <hr/>

    <!-- default MDTs and other hcps -->
    <div class="form-group">
      <label for="mdt-other-hcp-select">{{ 'modals.edit_hospital_team_modal.mdts' | translate }}</label>

      <ng-select (change)="onAddMdtOrHcp()" (search)="searchMdtOtherHcpListEvent.next($event)"
                 [(ngModel)]="mdtOrHcpSelect" [clearable]="true"
                 [items]="allMdtsOrHcps" [placeholder]="'modals.edit_hospital_team_modal.search_team_hcp' | translate"
                 [searchFn]="customSearchFn" class="users-select" id="mdt-other-hcp-select" [loading]="isLoadingMdts" [disabled]="defaultMdtsError">

        <ng-template let-item="item" ng-label-tmp>
          <span *ngIf="item?.className === 'MedicalTeam'">{{ item?.name }}</span>
          <span *ngIf="item?.className === 'HealthCareProfessional'">{{ item?.getFullName() }}</span>
        </ng-template>

        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          @if (item?.className === 'MedicalTeam') {
            <div class="wrapper" id="mdt-info">

              <div class="avatar">
                <span [inlineSVG]="'/assets/svg/community-24.svg'" class="icon"></span>
              </div>

              <div class="user-info">
                <p class="title">{{ item?.name }}</p>
                <p class="sub-text">{{ item?.hcpsAllNames }}</p>
              </div>

              <div *ngIf="isMdtSelected(item)" class="status small text-muted m-start-2">
                <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
              </div>

              <div *ngIf="!isMdtSelected(item)" class="status btn btn-tertiary m-start-2">
                <span [inlineSVG]="'/assets/svg/add-24.svg'" class="icon"></span>
                <span>{{ 'action.add' | translate }}</span>
              </div>

            </div>
          } @else if (item?.className === 'HealthCareProfessional') {
            <div class="wrapper" id="hcp-info">
              <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

              <div class="user-info">
                <p class="title">{{ item?.getFullName() }}</p>
                <p class="sub-text">{{ item?.job_title }}</p>
              </div>

              <div *ngIf="isHcpSelected(item)" class="status small text-muted m-start-2">
                <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
              </div>

              <div *ngIf="!isHcpSelected(item)" class="status btn btn-tertiary m-start-2">
                <span [inlineSVG]="'/assets/svg/add-24.svg'" class="icon"></span>
                <span>{{ 'action.add' | translate }}</span>
              </div>
            </div>
          }
        </ng-template>

      </ng-select>
    </div>

    @if(careModule?.default_mdts?.length) {
      <p class="mt-4 mb-1 small">{{ 'modals.edit_hospital_team_modal.teams' | translate }}</p>

      <div *ngFor="let mdt of careModule?.default_mdts" class="participant-item">

        <div class="avatar">
          <span [inlineSVG]="'/assets/svg/community-24.svg'" class="icon"></span>
        </div>

        <div class="meta">
          <p class="title">{{ mdt?.name }}</p>
          <p class="description">
            <span *ngFor="let hcp of mdt?.hcps; let i=index">
              <span>{{ hcp?.getFullName() }}</span>
              <span *ngIf="(i < (mdt?.hcps?.length - 1))">, </span>
            </span>
          </p>
        </div>

        <button (click)="onRemoveMdt($event, mdt)" [id]="'default-mdt-modal-remove-mdt-' + mdt.uid"
           class="status btn btn-tertiary m-start-1" >
          <span [inlineSVG]="'/assets/svg/close-24.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </button>
      </div>

      <p class="text-danger" *ngIf="defaultMdtsError">{{ defaultMdtsError  | translate }}</p>
    }

    @if(careModule?.default_hcps?.length) {
      <p class="mt-4 mb-1 small">{{ 'modals.edit_hospital_team_modal.other_hcps' | translate }}</p>

      <div *ngFor="let hcp of careModule?.default_hcps" class="participant-item">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>

        <button (click)="onRemoveHcp($event, hcp)" [id]="'default-mdt-modal-remove-hcp-' + hcp.uid"
           class="status btn btn-tertiary">
          <span [inlineSVG]="'/assets/svg/close-24.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </button>

      </div>
    }

    <div class="info-box mt-4">
      <em class="info-box-icon" [inlineSVG]="'/assets/svg/info-circle-24.svg'"></em>
      <p>
        <span class="font-weight-bold">{{ 'modals.edit_hospital_team_modal.please_note' | translate }}</span>
        : {{ 'modals.edit_hospital_team_modal.note' | translate }}
      </p>
    </div>
  }
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-cl-cm-form-cancel" (click)="onHandleClose()">{{ 'action.cancel' |
      translate }}
    </button>
    <button class="btn btn-primary m-start-2" id="edit-cl-cm-form-submit" [ngClass]="{'loader': isSaving}"
            (click)="handleConfirm()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-light-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
