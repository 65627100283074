<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_other_email_modal.edit_email' | translate}}</h6>

  <button type="button" class="btn-close" id="edit-other-email-modal-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>


<div class="modal-body">
  <p class="m-0 text-muted">{{ 'modals.edit_other_email_modal.current_email' | translate}}</p>
  <p class="mb-3">{{ patient?.email || hcp?.email }}</p>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- new email -->
    <div class="form-group">
      <label for="inputNewEmail" class="required">{{ 'form.labels.new_email' | translate }}</label>

      <input type="email" id="inputNewEmail" class="form-control w-100"
        placeholder="" formControlName="new_email">

      <app-feedback-field [field]="form.get('new_email')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <div class="d-flex justify-content-between">
        <label for="inputReason" class="required">{{ 'modals.edit_other_email_modal.reason' | translate }}</label>

        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.reason?.value)">
          {{( form?.controls?.reason?.value?.length || '0' ) | translateNumber }}/{{reasonMaxLength | translateNumber}}
        </p>
      </div>

      <textarea autosize id="inputReason" class="form-control w-100" formControlName="reason"
        placeholder="" required [maxlength]="reasonMaxLength"></textarea>

      <app-feedback-field [field]="form.get('reason')"></app-feedback-field>
    </div>
  </form>

  <p class="text-muted small mt-2">{{ 'modals.edit_other_email_modal.change_time' | translate }}</p>

</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button type="button" class="btn btn-secondary" id="edit-other-email-modal-no" (click)="handleClose()">
      {{ ('action.cancel' | translate ) }}
    </button>
    <button type="button" class="btn btn-primary m-start-2" id="edit-other-email-modal-submit" (click)="handleSubmit()"
      [ngClass]="{'loader': isSaving}">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ ('action.confirm' | translate ) }}</span>
    </button>
  </div>
</div>
