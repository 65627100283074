import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppointmentService } from '../../services/appointment.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HospitalService } from '../../services/hospital.service';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { Appointment } from '../../models/appointment';
import { Invitee } from '../../models/invitee';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocaleService } from '../../services/locale.service';
import { PatientInvitee } from '../../models/patient-invitee';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../models/patient';
import { ErrorService } from '../../services/error.service';
import { PathwayService } from '../../services/pathway.service';
import { Pathway } from '../../models/pathway';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { ToastrService } from 'ngx-toastr';
import { AfterFieldsValidator } from '../../validators/after-validator';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-form-appointment-modal',
  templateUrl: './appointment-form-modal.component.html',
  styleUrls: []
})
export class AppointmentFormModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public linkAvailable = false;
  public isSaving = false;
  public isLoadingPatients = false;
  public isLoadingPathways = false;
  public hcps: HealthCareProfessional[] = [];
  public patients: Patient[] = [];
  public appointment: Appointment;
  public type = 'NEW';
  public validationVisible = false;
  public titleMaxLength = 50;
  public descriptionMaxLength = 1000;
  public locationMaxLength = 250;
  public pathways: Pathway[] = [];
  public time_24_hours: boolean;
  public formValidators: ValidatorFn[] = [AfterFieldsValidator.validate('start_time', 'end_time', 'after')];
  public fullDayAppointment = false;

  public searchPatientLastNameEvent = new Subject<{ term, items }>();

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty;
  }

  @ViewChild('hcpselect', { read: NgSelectComponent }) hcpselect: NgSelectComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public appoinementService: AppointmentService,
    public formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public appointmentService: AppointmentService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public localeService: LocaleService,
    public authenticationService: AuthenticationService,
    public patientService: PatientService,
    public errorService: ErrorService,
    public pathwayService: PathwayService,
    public generalService: GeneralService,
    public toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    if (!this.appointment.invitees) {
      this.appointment.invitees = [];
    }

    this.setAppointmentDates();

    this.formSetup();

    if (this.type === 'EDIT') {
      this.getHCPs(this.appointment.patient_invitee.uid, this.appointment.patient_pathway_id);
    } else {
      this.getPatients();

      this.searchPatientLastNameEvent.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.getPatients(value);
        });

    }


  }

  setAppointmentDates(): void {
    if (this.appointment.date) {
      this.fullDayAppointment = true;
      const date = new Date(this.appointment.date);

      // Already setting start and end time to current time for when full_day option is deselected.
      const start_date = new Date();
      start_date.setDate(date.getDate());
      start_date.setMonth(date.getMonth());
      start_date.setFullYear(date.getFullYear());

      this.appointment.start_date = start_date.toString();

      const end_date = new Date();
      end_date.setDate(date.getDate());
      end_date.setMonth(date.getMonth());
      end_date.setFullYear(date.getFullYear());

      this.appointment.end_date = end_date.toString();

    } else {
      if (!this.appointment.start_date) {
        this.appointment.start_date = new Date().toString();
      }

      if (!this.appointment.end_date) {
        this.appointment.end_date = new Date().toString();
      }
    }
  }

  formSetup() {
    if (this.form) {
      return;
    }

    const preferences = this.localeService.getLocalePreferences();
    let dateFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    dateFormat = this.localeService.transformDateFormatToArabic(dateFormat);
    this.bsDatepickerConfig.dateInputFormat = dateFormat;
    this.bsDatepickerConfig.adaptivePosition = true;

    this.time_24_hours = preferences.locale.time_24_hours;

    const startDateWithoutTz = this.localeService.dateWithoutTimeZone(this.appointment.start_date);
    const endDateWithoutTz = this.localeService.dateWithoutTimeZone(this.appointment.end_date);

    this.form = new UntypedFormGroup({
      patient: new UntypedFormControl(this.appointment?.patient_invitee?.getFullName(), [Validators.required]),
      title: new UntypedFormControl(this.appointment.title, [Validators.required]),
      description: new UntypedFormControl(this.appointment?.description),
      location: new UntypedFormControl(this.appointment.location?.name),
      link: new UntypedFormControl(this.appointment.location?.url),
      start_date: new UntypedFormControl(startDateWithoutTz, [Validators.required]),
      start_time: new UntypedFormControl(startDateWithoutTz, [Validators.required]),
      end_time: new UntypedFormControl(endDateWithoutTz, [Validators.required]),
      full_day: new UntypedFormControl(this.fullDayAppointment)
    });

    if (!this.fullDayAppointment) {
      this.form.setValidators(this.formValidators);
    }

    this.form.get('full_day').valueChanges.subscribe(() => this.fullDayChanged());

    if (this.type === 'NEW') {
      this.form.addControl('pathway', new UntypedFormControl('', [Validators.required]));
    }

    if (this.type === 'EDIT') {
      this.form.get('patient')?.disable();
    }

    this.linkAvailable = (this.appointment?.location?.url && this.appointment?.location?.url !== '');
  }

  fullDayChanged() {
    if (this.form.get('full_day').value) {
      this.form.get('start_time').disable();
      this.form.get('start_time').setValidators([]);

      this.form.get('end_time').disable();
      this.form.get('end_time').setValidators([]);

      this.form.clearValidators();
    } else {
      this.form.get('start_time').enable();
      this.form.get('start_time').setValidators([Validators.required]);

      this.form.get('end_time').enable();
      this.form.get('end_time').setValidators([Validators.required]);

      this.form.setValidators(this.formValidators);
    }

    this.form.updateValueAndValidity();
  }

  getHCPs(patient_uid, pathway_uid) {
    let observable: Observable<any>;

    if (this.authenticationService.hasCcRole()) {
      const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
      observable = this.pathwayService.getPathwayByHospital(hospital_uid, patient_uid, pathway_uid);
    } else {
      const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
      observable = this.pathwayService.getPathwayByHcp(hcp_uid, patient_uid, pathway_uid);
    }

    observable.subscribe((result: Pathway) => {
      this.hcps = result.getHcps(true, true, false);
    });
  }

  getPatients(event?) {
    let term = '';
    let observable: Observable<any>;

    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.patients = [];
    }

    if (this.authenticationService.hasCcRole()) {
      observable = this.patientService.getPatientsByHospital(this.hcpService.getCurrentStoredHospitalUid(), {
        last_name: term,
        status: ['ACTIVE', 'CONSENT_PENDING'],
        has_pathways: true
      }, 'last_name,desc;first_name,desc', 0, 50);
    } else {
      observable = this.patientService.getPatientsByHcp(this.hcpService.getCurrentStoredHcpUid(), {
        last_name: term,
        status: ['ACTIVE', 'CONSENT_PENDING']
      }, 'last_name,desc;first_name,desc', 0, 50);
    }

    this.isLoadingPatients = true;

    observable.subscribe({
      next: result => {
        this.patients = result.patients;
        this.isLoadingPatients = false;
      },
      error: () => this.isLoadingPatients = false
    });

  }

  getPathways(patient_uid) {
    let observable: Observable<Pathway[]>;

    if (this.authenticationService.hasCcRole()) {
      observable = this.pathwayService.getPathwaysByHospital(this.hcpService.getCurrentStoredHospitalUid(), patient_uid);
    } else {
      observable = this.pathwayService.getPathwaysByHcp(this.hcpService.getCurrentStoredHcpUid(), patient_uid);
    }

    this.isLoadingPathways = true;

    observable.subscribe({
      next: result => {
        this.pathways = result;

        if (this.pathways.length === 1) {
          this.form.get('pathway').setValue(this.pathways[0]);
          this.pathwaySelected(this.pathways[0]);
        }

        this.isLoadingPathways = false;
      },
      error: () => this.isLoadingPathways = true
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  isModalValid(): boolean {
    if (!this.appointment.patient_invitee) {
      return false;
    }

    if (!this.linkAvailable) {
      this.form.get('link').disable();
      this.form.updateValueAndValidity();
    } else {
      this.form.get('link').enable();
      this.form.updateValueAndValidity();
    }

    return this.form.valid;
  }

  handleSubmit() {
    if (!this.isModalValid()) {
      this.validationVisible = true;

    } else {
      if (this.type === 'NEW') {
        this.handleCreateAppointment();
      } else {
        this.handleUpdateAppointment();
      }
    }
  }

  isHcpSelected(hcp: HealthCareProfessional) {
    if (!this.appointment.invitees) {
      return false;
    }
    const index = this.appointment.invitees.map(invitee => invitee.uid).indexOf(hcp.uid);
    return (index >= 0);
  }

  removeHcp($event, hcp) {
    $event.preventDefault();

    const index = this.appointment.invitees.indexOf(hcp);
    if (index >= 0) {
      this.appointment.invitees.splice(index, 1);
    }
  }

  customSearchFn(term: string, item: HealthCareProfessional) {
    term = term.toLowerCase();
    return item.last_name.toLowerCase().indexOf(term) > - 1;
  }

  patientSelected(patient) {
    if (!patient) {
      this.pathways = [];
      this.hcps = [];
      this.form.get('pathway').setValue(null);
      this.form.get('patient').setValue(null);
      this.appointment.patient_invitee = undefined;
      this.appointment.invitees = [];
    } else {
      this.appointment.patient_invitee = new PatientInvitee(patient);
      this.getPathways(patient.uid);
    }

    this.form.updateValueAndValidity();
  }

  hcpSelected(hcp) {
    if (!hcp || this.isHcpSelected(hcp)) {
      return;
    }

    if (!this.appointment.invitees) {
      this.appointment.invitees = [];
    }

    const invitee = new Invitee(hcp);
    this.appointment.invitees.push(invitee);
    this.hcpselect.handleClearClick();
  }

  pathwaySelected(pathway: Pathway) {
    if (!pathway) {
      this.hcps = [];
      this.appointment.invitees = [];
    } else {
      this.getHCPs(this.appointment.patient_invitee.uid, pathway.uid);
    }

    this.form.updateValueAndValidity();
  }

  handleCreateAppointment() {
    this.isSaving = true;
    const payload = this.createPayLoad();
    payload['patient_uid'] = this.appointment.patient_invitee.uid;
    payload['patient_pathway_id'] = this.form.value.pathway.uid;

    this.appointmentService.createAppointment(payload).subscribe(
      con => this.createResultHandler(con),
      err => this.errorHandler(err)
    );
  }

  handleUpdateAppointment() {
    this.isSaving = true;
    const payload = this.createPayLoad();

    this.appointmentService.updateAppointment(this.appointment.uid, this.appointment.patient_invitee.uid, payload).subscribe(
      con => this.updateResultHandler(con),
      err => this.errorHandler(err)
    );
  }

  createResultHandler(appointment: Appointment) {
    this.isSaving = false;
    this.toastrService.success(this.translate.instant('modals.appointment.create_success'));

    this._closeAnyway = true;
    this.bsModalRef.hide();
  }

  updateResultHandler(appointment: Appointment) {
    this.isSaving = false;
    this.toastrService.success(this.translate.instant('modals.appointment.update_success'));

    this._closeAnyway = true;
    this.bsModalRef.hide();
  }

  errorHandler(error: any) {
    this.isSaving = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        const errData = {
          backend_errors: true,
          message: err.key
        };

        switch (err.field) {
          case 'location.url':
            this.form.get('link').setErrors(errData);
          break;
          default:
            this.form.get(err.field).setErrors(errData);
        }
      });
    } else {
      this.errorService.showGeneralBackendErrorToast();
      this._closeAnyway = true;
      this.bsModalRef.hide();
    }
  }

  createPayLoad() {
    const payload: any = {
      title: this.form.get('title').value,
      hcp_uids: this.appointment.invitees.map(i => i.uid)
    };

    if (this.form.get('description')?.value && this.form.get('description')?.value !== '') {
      payload.description = this.form.get('description')?.value;
    }

    if (this.form.get('location')?.value && this.form.get('location')?.value !== '') {
      payload.location = {
        name: this.form.get('location').value
      }

      if (this.linkAvailable) {
        payload.location.url = this.form.value['link'];
      }
    }

    if (this.form.get('full_day').value) {
      payload.date = this.generalService.dateToString(this.form.get('start_date').value);
    } else {
      const start_date: Date = this.form.get('start_date').value;

      const day = start_date.getDate();
      const month = start_date.getMonth();
      const year = start_date.getFullYear();

      const start_time: Date = this.form.get('start_time').value;
      start_time.setDate(day);
      start_time.setMonth(month);
      start_time.setFullYear(year);

      const end_time: Date = this.form.get('end_time').value;
      end_time.setDate(day);
      end_time.setMonth(month);
      end_time.setFullYear(year);

      payload.start_date = this.localeService.dateWithTimeZone(start_time);
      payload.end_date = this.localeService.dateWithTimeZone(end_time);
    }

    return payload;
  }
}
