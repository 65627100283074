import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PhaseDna } from '../../models/phase-dna';
import { AnalyticsService } from '../../services/analytics.service';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-dna-phases',
  templateUrl: './dna-phases.component.html'
})
export class DnaPhasesComponent implements OnInit {
  public pieChart: Chart;
  public phaseData;
  public phaseMaxValue = 0;
  public colors = ['#8E49B9', '#00B8D6', '#95114F', '#B04B00', '#F79A8A', '#81B662', '#F0B400', '#85A0F5'];
  public isLoading = true;

  @Output() phaseSelected = new EventEmitter<PhaseDna>();
  @Input() phase: PhaseDna;
  @Input() careModuleUid: string;


  constructor(
    public analyticsService: AnalyticsService,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['careModuleUid']?.currentValue || this.careModuleUid) {
      this.loadPhases();
    }
  }

  loadPhases() {
    this.phaseData =  [];
    if (this.phase && !this.phase?.has_sub_phases) {
      return;
    }

    this.isLoading = true;
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.analyticsService.phaseProgress(hospitalUid, hcpUid, this.careModuleUid, this.phase?.uid).subscribe({
      next: result => {
        this.isLoading = false;
        this.phaseData = result;
        this.phaseMaxValue = Math.max.apply(Math, this.phaseData.map( function(i) { return i.value; }));
        this.buildPhasePieChart();
      }, error: () => this.isLoading = false
    });
  }

  barPercent(value) {
    return ((value / this.phaseMaxValue) * 100) + '%';
  }

  buildPhasePieChart() {
    const params: any = this.getPhasePieParams();
    this.pieChart = new Chart(params);
  }

  getPhasePieParams() {
    const baseParams: any = {
      chart: {
        backgroundColor: 'transparent'
      },
      plotOptions: {
        pie: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 0.9
            }
          },
          colors: this.colors,
          fillColor: '#f2f2f2',
          dataLabels: {
            distance: '-16%',
            color: '#FFFFFF',
            padding: 4,
            borderRadius: 4,
            backgroundColor: '#00103080',
            verticalAlign: 'middle',
            style: {
              fontSize: '14',
              color: 'gray'
            },
            filter: {
              property: 'percentage',
              operator: '>',
              value: 0
            }
          }
        },
        series: {
          enableMouseTracking: true,
          showInLegend: true
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        shared: true,
        borderRadius: 0,
        borderWidth: 1,
        borderColor: '#F4F4F4',
        formatter: function() {
          return `<small>${ this.point.x }</small><br /> <strong>${ this.y }</strong>`;
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        type: 'pie',
        borderWidth: 0,
        innerSize: '67%',
        data: this.phaseData.map(item => ({
          x: item.title,
          y: item.value,
          name: (item.percent + '%')
        }))
      }]
    };

    return baseParams;
  }

  selectPhase(event: MouseEvent, phase: PhaseDna) {
    event?.preventDefault();
    this.phaseSelected.emit(phase);
  }
}
