<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.calendar.calendar' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <button type="button" class="btn btn-icon btn-lg btn-tertiary with-badge m-end-3" id="toggle-pending-invitations" (click)="togglePendingInvitations($event)" *ngIf="pendingAppointments?.length">
            <span [inlineSVG]="'/assets/svg/envelope-outline-24.svg'"></span>
          </button>

          <button type="button" class="btn btn-primary" id="add-appointment" (click)="addAppointment($event)">
            {{ 'pages.default.calendar.add_new_appointment' | translate }}
          </button>

          <app-calendar-pending-invitations
            *ngIf="showPendingInvitations"
            (clickOutside)="clickOutsidePendingInvitations()"
            [delayClickOutsideInit]="'true'"
            [appointments]="pendingAppointments"
            (onAppointmentSelect)="openPendingAppointment($event)"
          />
        </div>
      </div>
    </div>

  </div>
</div>

<div class="body-content pb-0">
  <div class="container">
    <div class="row justify-content-between align-items-end">
      <div class="col col-auto">

        <div class="filters-group">
          @if(currentView === 'year') {
            <div class="custom-scope-range">
              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-prev-year" (click)="prevYear()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-left-24.svg'"></span>
              </button>

              <span class="label mx-1">{{ currentYear | translateNumber }}</span>

              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-next-year" (click)="nextYear()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-right-24.svg'"></span>
              </button>
            </div>
          }

          @if(currentView === 'week') {
            <div class="custom-scope-range">
              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-prev-week" (click)="prevWeek()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-left-24.svg'"></span>
              </button>

              <span class="label mx-1">

                {{ currentWeekFirstDay?.format('D') }}

                <ng-container *ngIf="currentWeekFirstDay?.format('MMMM') !== currentWeekLastDay?.format('MMMM')">
                  {{ currentWeekFirstDay?.format('MMMM') }}
                </ng-container>

                <ng-container *ngIf="currentWeekFirstDay?.format('YYYY') !== currentWeekLastDay?.format('YYYY')">
                  {{ currentWeekFirstDay?.format('YYYY') }}
                </ng-container>

                <ng-container>&nbsp;-&nbsp;</ng-container>

                <ng-container>{{ currentWeekLastDay?.format('D') }}&nbsp;</ng-container>
                <ng-container>{{ currentWeekLastDay?.format('MMMM') }}&nbsp; </ng-container>
                <ng-container>{{ currentWeekLastDay?.format('YYYY') }}</ng-container>

              </span>

              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-next-week" (click)="nextWeek()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-right-24.svg'"></span>
              </button>
            </div>
          }

          @if(currentView === 'month') {
            <div class="custom-scope-range" >
              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-prev-month" (click)= "prevMonth()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-left-24.svg'"></span>
              </button>

              <span class="label mx-1">{{ currentMonthFirstDay?.format('MMMM') }} {{ currentMonthFirstDay?.format('YYYY') }}</span>

              <button type="button" class="btn btn-icon btn-tertiary" id="calendar-next-month" (click)= "nextMonth()">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chevron-right-24.svg'"></span>
              </button>
            </div>
          }

          <div class="divider"></div>

          <div class="form-group">
            <app-select
              id="calendar-select-patient"
              [selectClass]="'ng-select-sm'"
              [label]="'pages.default.calendar.patient' | translate"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="patients"
              [searchFn]="customSearchFn"
              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
              [loading]="isLoadingPatients"
              [(ngModel)]="patientFilter"
              (ngModelChange)="setFilter()"
              (search)="searchPatientLastNameEvent.next($event)"
              (open)="getPatients()"
            />
          </div>

          <div class="form-group">
            <app-select
              id="calendar-select-hcp"
              [selectClass]="'ng-select-sm'"
              [label]="'pages.default.calendar.hcp' | translate"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="hcps"
              [searchFn]="customSearchFn"
              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
              [loading]="isLoadingHcps"
              [(ngModel)]="hcpFilter"
              (ngModelChange)="setFilter()"
              (search)="searchHcpLastNameEvent.next($event)"
              (open)="getHCPs()"
            />
          </div>

          <button id="reset-filters" type="button" class="btn btn-link" *ngIf="filtersAreSet" (click)="resetFilters()">
            {{ 'action.reset' | translate }}
          </button>
        </div>

      </div>
      <div class="col col-auto m-start-auto">
        <div class="btn-group" role="group">
          <button type="button" class="btn" id="calendar-change-view-to-year" [ngClass]="(currentView === 'year') ? 'btn-link active' : 'btn-tertiary'" (click)="changeViewTo('year')">
            {{ 'pages.default.calendar.list' | translate }}
          </button>

          <button type="button" class="btn" id="calendar-change-view-to-week" [ngClass]="(currentView === 'week') ? 'btn-link active' : 'btn-tertiary'" (click)="changeViewTo('week')">
            {{ 'pages.default.calendar.week' | translate }}
          </button>

          <button type="button" class="btn" id="calendar-change-view-to-month" [ngClass]="(currentView === 'month') ? 'btn-link active' : 'btn-tertiary'" (click)="changeViewTo('month')">
            {{ 'pages.default.calendar.month' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-calendar-appointment-compact
  *ngIf="compactAppointment"
  [style.left.px]="compactAppointmentCoords?.x"
  [style.top.px]="compactAppointmentCoords?.y"
  [ngClass]="{'start': (compactAppointmentCoords?.horSided==='start'),'end': (compactAppointmentCoords?.horSided==='end')}"
  [appointment]="compactAppointment"
  (onViewDetails)="compactAppointmentViewDetails()"
  (clickOutside)="clearCompactComponent()"
  (onCloseCompact)="clearCompactComponent()"
  [delayClickOutsideInit]="'true'"
/>

<app-calendar-year-view
  *ngIf="currentView === 'year'"
  [dateFormat]="dateFormat"
  [time24Hours]="time_24_hours"
  [timeZone]="timeZone"
  [yearChanged]="yearChanged"
  [filters]="filters"
  (onAppointmentSelect)="openAppointmentModal($event);"
/>
<app-calendar-week-view
  *ngIf="currentView === 'week'"
  (onAppointmentSelect)="openAppointmentCompact($event);"
  [currentWeekFirstDay]="currentWeekFirstDay"
  [filters]="filters"
  [timeZone]="timeZone"
  [renderMwlView]="'true'"
/>
<app-calendar-month-view
  *ngIf="currentView === 'month'"
  (onAppointmentSelect)="openAppointmentCompact($event);"
  [currentMonthFirstDay]="currentMonthFirstDay"
  [timeZone]="timeZone"
  [filters]="filters"
/>
