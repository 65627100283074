import {Participant} from './participant';
import {ConversationItem} from './conversation_item';
import moment from "moment";
import { Patient } from './patient';

export class Conversation {
  public conversation_type: string;
  public uid: string;
  public patient_pathway_id: string;
  public subject: string;
  public draft: boolean;
  public owner: boolean;
  public unread_messages: number;
  public latest_message: string;
  public latest_message_time: string;
  public latest_message_from: Participant;
  public latest_message_type: string;
  public created_at: string;

  public hcps: Array<Participant> = [];
  public allHcps: Array<Participant> = [];
  public hcp_uids: Array<string>
  public patient: Participant;
  public patient_uid: String;

  public items: Array<ConversationItem> = [];
  public standard_reply_in_days: number;

  public currentHcpUid: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.conversation_type = item.conversation_type;
    this.uid = item.uid;
    this.patient_pathway_id = item.patient_pathway_id;
    this.subject = item.subject;
    this.draft = item.draft;
    this.owner = item.owner as boolean;
    this.unread_messages = item.unread_messages;
    this.latest_message = item.latest_message;
    this.latest_message_time = item.latest_message_time;
    this.latest_message_from = item.latest_message_from;
    this.latest_message_type = item.latest_message_type;
    this.created_at = item.created_at;
    this.patient_uid = item.patient_uid;


    if(item.hcp_uids) {
      this.fillHcpIds(item.hcp_uids);
    } else if(item.hcps) {
      this.hcps = item.hcps.map(i => new Participant(i));
    }
    this.fillPatient(item.patient);
  }

  fillHcpIds (ids) {
    this.hcp_uids = [];
    if (ids) {
      ids.forEach(hcpData => {
        this.hcp_uids.push(hcpData);
      });
    }
  }

  fillHcps(hcpsData) {
    this.allHcps = [];
    if (hcpsData) {
      hcpsData.forEach(hcpData => {
        this.allHcps.push(new Participant(hcpData));
      });
    }
  }

  fillPatient(patientData) {
    if(patientData) {
      this.patient = new Participant(patientData);
    }
  }

  fillCurrentHcpUid(uid) {
    this.currentHcpUid = uid;
  }

  setParticipantDetails(participants: Array<any>) {
    if (this.patient_uid) {
      const filtered = participants.filter((participant) => participant.uid === this.patient_uid);
      this.patient = new Participant(filtered[0]);
    }
    if (!this.hcp_uids || this.hcp_uids.length === 0) {
      return;
    }
    this.hcps = [];

    this.hcp_uids.forEach(uid => {
      const filtered = participants.filter((participant) => participant.uid === uid);
      this.hcps.push(new Participant(filtered[0]));
    });
  }

  getParticipantForUid(uid: string) {
    const filtered = this.allHcps.filter((participant) => {
      return participant.uid === uid;
    });

    if (filtered[0] !== undefined) {
      return filtered[0];
    } else if (this.patient && uid === this.patient.uid) {
      return this.patient;
    }
  }

  activeHcpParticipants(): Array<Participant> {
    return this.hcps.filter( (participant) =>  {
      if (participant.uid !== this.currentHcpUid) {
        return !participant.removed;
      } else {
        return false;
      }
    });
  }

  addItem(item) {
    const conversationItem = new ConversationItem(item);
    conversationItem.page = 0;
    this.items.push(conversationItem);
    this.latest_message = conversationItem.message;
    this.latest_message_time = conversationItem.created_at || moment.now().toLocaleString();
  }

  addItems(result, page) {
    if(page==0) {
      this.items = [];
    }

    result.forEach(item => {
      const conversationItem = new ConversationItem(item);
      conversationItem.page = page;
      this.items.unshift(conversationItem);
    });
  }

  hcpsFullNames(): string {
    this.sortParticipants();
    return this.activeHcpParticipants().map((p) => {
      return p.getFullName();
    }).join(', ');
  }

  fullNames() {
    if(this.patient) {
      return [this.patient.getFullName(), this.hcpsFullNames()].join(', ');
    } else {
      return this.hcpsFullNames();
    }
  }

  sortParticipants() {
    this.hcps.sort(function(a, b) {
      var textA = a?.last_name?.toUpperCase();
      var textB = b?.last_name?.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

  }

  mergeDetails(convo: Conversation) {
    this.subject = convo.subject;
    this.unread_messages = convo.unread_messages;
    this.draft = convo.draft;
    this.latest_message = convo.latest_message;
    this.latest_message_time = convo.latest_message_time;
    this.latest_message_from = convo.latest_message_from;
    this.items = convo.items;
  }
}
