<div class="modal-header">
  <h6 class="h7">
    <span *ngIf="userTask?.title">{{ userTask?.translationKeyTitle | translate | evalTranslation }}</span>
    <span *ngIf="!userTask?.title">...</span>
  </h6>
  <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- Patient information -->
    <div class="participant-item mb-3">
      <app-avatar [profilePicture]="userTask?.patient?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title text-truncate">{{ userTask?.patient?.getFullName() || '...' }}</p>
        <p class="description" *ngIf="userTask?.patient">{{ userTask?.patient?.date_of_birth | timeZoneDate:dateFormat?.format  }} ({{ userTask?.patient?.age() | translateNumber }}{{ 'pages.default.dashboard.yo' | translate }})</p>
      </div>
    </div>

    <!-- Care Module Name (Pathway) title -->
    <div class="form-group">
      <label class="required" for="inputPathway">{{ 'form.labels.pathway' | translate }}</label>
      <input [placeholder]="('form.labels.pathway' | translate)" class="form-control w-100" formControlName="pathway" id="inputPathway" type="text">
    </div>

    <!-- Title (Appointment) -->
    <div class="form-group mb-3">
      <div class="d-flex justify-content-between mb-1">
        <label class="required" for="inputTitle">{{ 'form.labels.title' | translate }}</label>

        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.title?.value)">
          {{( form?.controls?.title?.value?.length || '0' ) | translateNumber}}/{{titleMaxLength | translateNumber}}
        </p>
      </div>

      <input [placeholder]="('form.labels.title' | translate)" class="form-control w-100" formControlName="title" id="inputTitle" type="text" [maxlength]="titleMaxLength">
      <app-feedback-field [field]="form.get('title')"></app-feedback-field>
    </div>

    <div class="row align-items-end">
      <div class="col-6">
        <!-- Date -->
        <div class="form-group required form-group-calendar">
          <label for="inputDate" class="required">{{ 'modals.appointment.form.date' | translate }}</label>
          <input type="text" id="inputDate" class="form-control w-100" formControlName="start_date" required bsDatepicker >
          <app-feedback-field [field]="form.get('start_date')"></app-feedback-field>
        </div>
      </div>
      <div class="col-6">
        <!-- Full day toggle -->
        <div class="form-control shadow-none">
          <div class="form-check mb-0">
            <input type="checkbox" class="form-check-input" id="check-full-day" formControlName="full_day">
            <label class="form-check-label" for="check-full-day">{{ 'modals.appointment.form.full_day' | translate
              }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="!form.get('full_day')?.value">
      <div class="col-6">
        <!-- Start time -->
        <div class="form-group">
          <label for="inputStartTime" class="required">{{ 'modals.appointment.form.start_time' | translate }}</label>
          <timepicker id="inputStartTime" class="with-label" formControlName="start_time" [showMeridian]="!time_24_hours" [showSpinners]="false" [mousewheel]="false"></timepicker>
          <app-feedback-field [field]="form.get('start_time')"></app-feedback-field>
        </div>
      </div>
      <div class="col-6">
        <!-- End time -->
        <div class="form-group">
          <label for="inputEndTime" class="required">{{ 'modals.appointment.form.end_time' | translate }}</label>
          <timepicker id="inputEndTime" class="with-label" formControlName="end_time" [mousewheel]="false" [showMeridian]="!time_24_hours" [showSpinners]="false" ></timepicker>
          <app-feedback-field [field]="form.get('end_time')"></app-feedback-field>
        </div>
      </div>
    </div>

    <div class="form-group required mt-3">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputDescription">{{ 'modals.appointment.form.description' | translate }}</label>

        <p class="extra-small text-muted m-0">
          {{( form?.controls?.description?.value?.length || '0' ) | translateNumber }}/{{descriptionMaxLength | translateNumber}}
        </p>
      </div>

      <textarea appTextareaAutoresize id="inputDescription" class="form-control w-100" formControlName="description" [placeholder]="'modals.appointment.form.description' | translate" [maxlength]="descriptionMaxLength"></textarea>

      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>

      <p class="text-danger extra-small mt-1">{{ 'modals.appointment.form.medical_date' | translate }}</p>
    </div>

    <hr class="medium" />

    <p><strong>{{ 'modals.appointment.form.attendees_title' | translate }}</strong></p>

    <div class="form-group">
      <label class="required">{{ 'modals.appointment.form.attendees' | translate }}</label>
      <ng-select
        class="users-select"
        #hcpselect
        placeholder="{{ 'modals.appointment.form.search_role_or_hcp' | translate }}"
        [items]="hcps"
        (search)="searchHCPs($event)"
        [searchFn]="customSearchFn"
        bindLabel="first_name"
        (change)="hcpSelected($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item">{{item?.getFullName()}}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="wrapper" *ngIf="item">
              <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

              <div class="user-info">
                <p class="title">{{ item?.getFullName() }}</p>
                <p class="sub-text">{{ item?.job_title }}</p>
              </div>

              <div class="status small text-muted m-start-2" *ngIf="isHcpSelected(item)">
                <span>{{ 'modals.appointment.form.already_added' | translate }}</span>
              </div>

              <div class="status btn btn-tertiary m-start-2" *ngIf="!isHcpSelected(item)">
                <span class="icon" [inlineSVG]="'/assets/svg/add-24.svg'"></span>
                <span>{{ 'modals.appointment.form.add' | translate }}</span>
              </div>
            </div>
          </ng-template>
        </ng-select>

      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>
    </div>

    <div class="participant-item mt-3" *ngIf="appointment?.patient_invitee">
      <app-avatar [profilePicture]="appointment?.patient_invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ appointment?.patient_invitee?.getFullName() }}</p>
        <p class="description">{{ 'modals.appointment.form.patient' | translate }}</p>
      </div>
      <div class="text-muted text-align-end">{{ 'modals.appointment.form.patient_not_removed' | translate }}</div>
    </div>

    <div class="participant-item clickable" *ngFor="let invitee of appointment?.invitees">
      <app-avatar [profilePicture]="invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ invitee?.getFullName() }}</p>
        <p class="description">{{ invitee?.job_title }}</p>
      </div>

      <a href="" class="btn btn-tertiary" id="appointment-form-remove-hcp" (click)="removeHcp($event, invitee)">
        <span class="icon" [inlineSVG]="'/assets/svg/close-24.svg'"></span>
        <span>{{ 'modals.appointment.form.remove' | translate }}</span>
      </a>
    </div>

    <hr class="medium" />

    <p><strong>{{ 'modals.appointment.form.location' | translate }}</strong></p>

    <div class="form-group required">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputLocation">{{ 'modals.appointment.form.location' | translate }}</label>

        <p class="extra-small text-muted m-0" *ngIf="!(validationVisible && !form?.controls?.title?.value)">
          {{( form?.controls?.location_name?.value?.length || '0' ) | translateNumber}}/{{ locationMaxLength | translateNumber }}
        </p>
      </div>

      <input type="text" id="inputLocation" class="form-control w-100" formControlName="location_name" [maxlength]="locationMaxLength" [placeholder]="'modals.appointment.form.location' | translate">
      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>

      <div class="form-check mt-2">
        <input type="checkbox" class="form-check-input" id="check_link" [(ngModel)]="linkAvailable" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="check_link" >{{ 'modals.appointment.form.include_link' | translate }}</label>
      </div>
    </div>

    <div class="form-group" *ngIf="linkAvailable">
      <label for="inputLink">{{ 'modals.appointment.form.link' | translate }}</label>
      <input type="text" id="inputLink" class="form-control w-100" formControlName="location_url"  [placeholder]="'modals.appointment.form.link' | translate">
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="user-task-appointment-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <!--
    <button class="btn btn-link" id="user-task-appointment-save" [ngClass]="{'loader': isSaving}" (click)="handleSaveChanges()">
      <span *ngIf="isSaving" class="loader text-secondary"><em class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save-changes' | translate }}</span>
    </button>
  -->

    <button class="btn btn-primary m-start-2" id="user-task-appointment-submit" [ngClass]="{'loader': isSubmitting}" (click)="handleSubmit()">
      <span class="loader" *ngIf="isSubmitting" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
