<div class="notice always-on-top">
  <div class="logo text-jnj mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>

  <div class="position-relative">
    <h2>
      <span [inlineSVG]="'/assets/svg/warning-24.svg'" class="icon icon-lg m-end-2"></span>
      <span class="text-danger">{{ 'pages.general.generic_error.inactive' | translate }}</span>
    </h2>

    <h6>{{ 'pages.general.generic_error.inactive_text1' | translate }}</h6>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights></app-flow-copyrights>
  </div>
</div>
