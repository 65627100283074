import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Pathway } from '../../models/pathway';
import { Patient } from '../../models/patient';
import { Appointment } from '../../models/appointment';
import { UserTaskBasic } from '../../models/user-task-basic';
import { AppointmentService } from '../../services/appointment.service';
import { GeneralService } from '../../services/general.service';
import { SurgeryDateModalComponent } from '../../modals/surgery-date-modal/surgery-date-modal.component';


@Component({
  selector: 'app-patient-detail-surgery-dates',
  templateUrl: './patient-detail-surgery-dates.component.html'
})
export class PatientDetailSurgeryDatesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() pathway: Pathway;
  @Input() tasks: UserTaskBasic[];
  @Input() appointments: Appointment[];
  @Output() changed: EventEmitter<string> = new EventEmitter();

  public patientAppointments: Appointment[];
  public patientAppointmentsLoaded = false;

  constructor(
    public modalService: BsModalService,
    public appointmentService: AppointmentService,
  ) { }

  ngOnInit(): void {
    this.getAppointmentsByType();
  }

  getAppointmentsByType(): void {
    this.appointmentService.getAppointmentsByType(this.patient.uid, AppointmentService.TypeSurgery).subscribe(appointments => {
      this.patientAppointments = appointments;
      this.patientAppointmentsLoaded = true;
    });
  }

  setSurgeryDate(task: UserTaskBasic) {
    this.showModal(task);
  }

  editSurgeryDate(appointment: Appointment) {
    this.showModal(this.getCompleteAppointment(appointment));
  }

  canEdit(appointment: Appointment) {
    return this.patientAppointmentsLoaded && this.patientAppointments.find((a) => a.uid === appointment.uid) !== undefined;
  }

  // Appointments coming from dashboards are missing invitees.
  // We need to add them by merging with patientAppointments.
  getCompleteAppointment(appointment: Appointment): Appointment {
    return this.patientAppointments.find((a) => a.uid === appointment.uid) || appointment;
  }

  showModal(data: Appointment | UserTaskBasic) {
    const initialState: any = {
      patient: cloneDeep(this.patient, true),
    };

    if (data instanceof Appointment) {
      initialState.appointment = data;
    } else if (data instanceof UserTaskBasic) {
      initialState.task = data;
    }

    const modal = this.modalService.show(SurgeryDateModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modal.content?.onSurgeryDateUpdated.subscribe(() => {
      this.getAppointmentsByType();
      this.changed.emit();
    });
  }
}
