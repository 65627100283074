<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.administration.onboarding_requests.onboarding_requests' | translate }}</h5>
        </div>
        <div class="col col-auto" *ngIf="selected && selected?.length">
          <a href="" class="btn btn-secondary" id="admin-onboarding-requests-decline" (click)="bulkActionDecline($event)">{{ 'pages.default.administration.onboarding_requests.bulk_decline' | translate }}</a>
          <a href="" class="btn btn-primary m-start-1" id="admin-onboarding-requests-approve" (click)="bulkActionApprove($event)">{{ 'pages.default.administration.onboarding_requests.bulk_approve' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content pb-0">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">

          <div class="form-group form-group-search">
            <label for="patientLastNameSearch">{{ 'form.labels.patient_last_name' | translate }}</label>
            <input
              type="text"
              id="patientLastNameSearch"
              class="form-control form-control-sm"
              [placeholder]="'form.labels.search' | translate"
              [(ngModel)]="filters.last_name"
              (ngModelChange)="patientLastNameSearch.next($event)"
              required
            />
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <app-select
              id="admin-onboarding-requests-care-modules"
              class=""
              [selectClass]="'ng-select-sm'"
              [placeholder]="('pages.default.administration.patients.pathway' | translate)"
              [clearable]="false"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="careModules"
              [(ngModel)]="filters.care_module_uid"
              [bindValue]="'value'"
              (ngModelChange)="onCareModuleFilterChange()"
              [asFilter]="true"
              [showCheckboxes]="false"
            />
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="checkbox_approved_requests" [(ngModel)]="showApprovedRequests" [ngModelOptions]="{standalone: true}" (change)="onShowApprovedCheck()">
              <label class="form-check-label" for="checkbox_approved_requests">{{ 'pages.default.administration.onboarding_requests.show_approved_requests' | translate }}</label>
            </div>
          </div>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-onboarding-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
        </div>

      </div>
      <div class="col col-auto">

        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="dropdown-btn-sort"
              [label]="'general.sort_by' | translate"
              [selectClass]="'ng-select-sm ng-select-align-end'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="sortingOptions"
              [bindValue]="'value'"
              [(ngModel)]="sorting"

              (ngModelChange)="onChangeSort()"

              [asFilter]="false"
            ></app-select>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">

    @for (request of requests; track request.uid; let index = $index) {
      <div id="admin-onboarding-request-{{index}}" class="single-item-block block-compact mb-2" [ngClass]="{'block-muted': request.approved, 'block-selected': isChecked(request)}">

        <div class="block-checkbox">
          <div class="form-check" *ngIf="!request.approved">
            <input type="checkbox" class="form-check-input" id="checkbox_item_A{{index}}" (change)="checked(request)">
            <label class="form-check-label" for="checkbox_item_A{{index}}"></label>
          </div>
        </div>

        <div class="row d-flex align-items-center w-100">
          <div class="col-3">
            <div class="block-wrapper">
              <p class="label text-truncate" [id]="'admin-onboarding-request-name-' + request.uid">{{ request.first_name}} {{ request.last_name }}</p>
              <p class="value text-truncate" [id]="'admin-onboarding-request-date-of-birth-' + request.uid">{{ request.date_of_birth | timeZoneDate:dateFormat.format  }}</p>
            </div>
          </div>

          <div class="col-4">
            <div class="block-wrapper">
              <p class="text-truncate text-dark" [id]="'admin-onboarding-request-pathway-' + request.uid">{{ request?.careModuleTranslationKey  | translate }}</p>
              <p class="text-truncate small" [id]="'admin-onboarding-request-requested-on-' + request.uid">{{ 'pages.default.administration.onboarding_requests.requested_on' | translate }} {{ request.submission_date | timeZoneDate:dateFormat.format }}</p>
            </div>
          </div>

          <div class="col-5">
            <div class="block-wrapper block-meta">

              <a href="mailto:{{request.email}}" class="meta-item" [id]="'admin-onboarding-request-email-' + request.uid">
                <span [inlineSVG]="'/assets/svg/envelope-outline-24.svg'" class="icon"></span>
                <span>{{request.email}}</span>
              </a>

              <p *ngIf="request.phone_number?.number" class="meta-item" [id]="'admin-onboarding-request-phone-number-' + request.uid">
                <span [inlineSVG]="'/assets/svg/mobile-device-24.svg'" class="icon"></span>
                <span>{{getPhonePrefix(request.phone_number)}} {{request.phone_number?.number}}</span>
              </p>

            </div>
          </div>

        </div>

        <div class="block-wrapper" style="min-width: 120px;" *ngIf="selected.length && !request.approved">
        </div>

        <div class="block-wrapper text-center" style="min-width: 120px;" *ngIf="request.approved || request.declined">
          <p id="admin-onboarding-request-{{index}}-cc-approved" *ngIf="request.approved">{{ 'pages.default.administration.onboarding_requests.approved' | translate }}</p>
          <p id="admin-onboarding-request-{{index}}-cc-declined" *ngIf="request.declined">{{ 'pages.default.administration.onboarding_requests.declined' | translate }}</p>
        </div>

        <div class="block-btn-wrapper justify-content-end" style="min-width: 120px;" *ngIf="!request.approved  && !request.declined && selected.length == 0">
          <a href="" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-onboarding-request-decline-for-' + request.uid" (click)="showDeclineModal($event, request)">
            <span [inlineSVG]="'/assets/svg/close-24.svg'"></span>
          </a>
          <a href="" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-onboarding-request-approve-for-' + request.uid" (click)="showApproveModal($event, request)" >
            <span [inlineSVG]="'/assets/svg/checkmark-24.svg'"></span>
          </a>
        </div>
      </div>
    } @empty {
      <div class="empty-state my-5 my-lg-7 my-xl-9">
        <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
        <h6 class="h7">{{ 'pages.default.administration.onboarding_requests.no_onboarding_requests' | translate }}</h6>
      </div>
    }

  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
