<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.administration.onboarding_codes.onboarding_codes' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <a href="" class="btn btn-primary" id="admin-codes-generate"
          (click)="actionGenerate($event)">{{ 'pages.default.administration.onboarding_codes.generate_new_code' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content pb-0">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="admin-onboarding-codes-select-care-module"
              class=""
              [selectClass]="'ng-select-sm'"
              [placeholder]="('pages.default.administration.patients.pathway' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="true"
              [items]="careModules"

              [(ngModel)]="filters.care_module_uid"
              [bindValue]="'value'"
              (ngModelChange)="onCareModuleFilterChange()"

              [asFilter]="true"
              [showCheckboxes]="true"
            ></app-select>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="checkbox_all_codes" [(ngModel)]="showAllCodes" [ngModelOptions]="{standalone: true}"
                    (change)="onShowAllCodesCheck()">
              <label class="form-check-label"
                    for="checkbox_all_codes">{{ 'pages.default.administration.onboarding_codes.show_all_codes' | translate }}</label>
            </div>
          </div>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-codes-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    @for (timedCode of timedCodes; track timedCode.uid; let index = $index) {
      <div id="admin-oboarding-codes-timecode-{{index}}" class="single-item-block block-compact mb-2" [ngClass]="{ 'block-danger': isInactivated(timedCode), 'block-muted': isExpired(timedCode) }">
        <div class="row d-flex align-items-center w-100">
          <div class="col-4">
            <div class="block-wrapper py-2">
              <p class="label text-truncate e2e-care-module">{{ timedCode?.care_module?.translationKey | translate }}</p>
              <p class="value text-truncate e2e-valid-until" *ngIf="isActive(timedCode)">
                @if(timedCode.end_at) {
                  {{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}
                } @else {
                  {{ 'pages.default.administration.onboarding_codes.no_expiry' | translate }}
                }

              </p>
              <p *ngIf="isInactivated(timedCode)"
                class="text-truncate text-danger e2e-inactivated-on">{{ 'pages.default.administration.onboarding_codes.inactivated_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
              <p *ngIf="isExpired(timedCode)"
                class="text-truncate e2e-expired-on">{{ 'pages.default.administration.onboarding_codes.expired_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
            </div>
          </div>

          <div class="col-4">
            <div class="block-wrapper">
              <p class="text-truncate e2e-timedcode">{{ getCodeNotation(timedCode) }}</p>
            </div>
          </div>

          <div class="col-4">
            <div class="block-wrapper">
              <p class="text-truncate e2e-valid-uses">{{ timedCode.statistics.valid_uses }} {{ 'pages.default.administration.onboarding_codes.times_used' | translate }}</p>
              <p *ngIf="!isActive(timedCode)" class="text-truncate e2e-invalid-uses"
              [ngClass]="{'text-danger': isInactivated(timedCode)}"
              >{{ timedCode.statistics.invalid_uses }} {{ 'pages.default.administration.onboarding_codes.expired_attempts' | translate }}</p>
            </div>
          </div>

        </div>

        <div class="block-btn-wrapper m-end-1" style="min-width: 200px;">
          <!-- <a href="" (click)="onShowEditHcpModal($event, hcp)" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-hcps-edit-hcp-' + hcp.uid">
            <span [inlineSVG]="'/assets/svg/edit-outline-24.svg'"></span>
          </a> -->

          <a *ngIf="isActive(timedCode)" href="" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-codes-inactivate-code-' + timedCode.uid" (click)="actionInactivate($event, timedCode.uid)">
            <span [inlineSVG]="'/assets/svg/close-24.svg'"></span>
            <span class="m-start-1">{{ 'pages.default.administration.onboarding_codes.inactivate' | translate }}</span>
          </a>
          <a *ngIf="isActive(timedCode)" href="" class="block-action btn btn-lg btn-tertiary btn-icon m-start-1" [id]="'admin-codes-copy-code-' + timedCode.uid" (click)="actionCopyCode($event, timedCode)">
            <span [inlineSVG]="'/assets/svg/copy-24.svg'"></span>
          </a>
        </div>
      </div>
    } @empty {
      <div class="empty-state my-5 my-lg-7 my-xl-9">
        <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
        <h6 class="h7">{{ 'pages.default.administration.onboarding_codes.no_onboarding_codes' | translate }}</h6>
      </div>
    }

  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
